import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Enumerable from 'linq';
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade, Zoom } from "@progress/kendo-react-animation";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBookOpen, FaEye, FaPlus,FaBars, FaEarlybirds, FaOpencart, FaDownload } from 'react-icons/fa';

import {FiEdit,FiAlertTriangle}from 'react-icons/fi';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu,ColumnMenuCheckboxFilter } from "./columnMenu";
import TextField from '@mui/material/TextField';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import CustomReportMain from './cstmRptMain'
import { height } from '@mui/system';
//import {HiViewColumns} from 'react-icons/Hi';
import {AiFillEye, AiOutlineFileSearch, AiOutlineFolderOpen, AiOutlineFundView} from 'react-icons/ai';
import { MdPreview } from 'react-icons/md';
import { BsEyeFill } from 'react-icons/bs';
import Moment from 'moment';
//import dateFormat from 'dateformat';

const EditCommandCell = (props) => {
  return (
    <td>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" title='Edit'
        onClick={() => props.enterEdit(props.dataItem)}
      >
        <FiEdit></FiEdit>
      </button>
    </td>
  );
};

const StatementGrid = ({data }) => {

  const TmpltNmRegex = new RegExp(/[~`!#$%\^&*+=\-\[\]\\;,/{}|\\":<>\?]/g);
  const navigate = useNavigate();
  const[startDt,setstartDt] = React.useState("");
  const[endDt,setendDt ]= React.useState("");
  const[rptPckgNm,setrptPckgNm] = React.useState("");
  const[fileNm,setfileNm] = React.useState("");
  
  const [valdMsg, setvaldMsg] = React.useState("");
  const [valdMsg2, setvaldMsg2] = React.useState("");
  const [rptCountMsg, setrptCountMsg] = React.useState("");
  const [tmpltDetails, SetTmpltDetails] = React.useState(data);
  const [show, setShow] = React.useState(false);
  const [rptIds, setRptIds] = React.useState([]);
  const [tnm, SetTemplateNm] = React.useState("");
  const [tdsc, SetTemplateDesc] = React.useState("");
  const [btnStatus, setBtnStatus] = React.useState(true);
  const [btnStatus2, setBtnStatus2] = React.useState(true);
  const [btnStatus3, setBtnStatus3] = React.useState(true);
  const [error,setError]=React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState({
    templateId: 0,
  });
  const [errorMessage,setErrorMessage]=React.useState("");
  var tempToken = JSON.parse(localStorage.getItem('token'));

  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(tmpltDetails, initialDataState)
  );
  function downloadPDF(pdf,fileName) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
  const GetTmpltList = async (fileName,filePath) => {
    

    let token = tempToken;
    
    var i=0;
    var index=-2;
    var toIndex=0;
    while(index!==-1){
      
      console.log("filePath");
console.log(filePath);
      index=filePath.indexOf("\\",i);
      i=toIndex+1;
toIndex=index!==-1?index:toIndex;

    }
    
    
    let StmtFilePath=filePath.substring(0,toIndex);
    console.log(StmtFilePath.substring(0,toIndex));
    let StmtFileNm=fileName;
    //let AcctId=2392;
    const config = {
      headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }
  };
  const postData = {StmtFileNm,StmtFilePath};

  await axios.post('/FileGet', postData, config)
      .then(response => {
          
          console.log(response.data);
          if(!response.data.includes("Login failed")&&!response.data.includes("Error"))
          downloadPDF(response.data,StmtFileNm);
         else{
          setErrorMessage(response.data);
          setError(true);
          setTimeout(()=>{
            
          
            setError(false);
          
           
          },5500)
         }
      })
      .catch((error) => {
          
          if (error.response.status === 401) {
            
              refreshToken(fileName,filePath);

          }
          console.log("my error is " + error);
      })

      
      

  //     const res= await axios.get(`/FileGet/${ fileName }`);
  //     fetch("/FileGet"+"fileName"+fileName+"filePath"+filePath)
  // .then((response) => response.json())
  // .then(console.log);
      //const res = await axios.get("/FileGet"+"fileName"+fileName+"filePath"+filePath);
        // .catch((error) => {
        //     
        //     if (error.response.status === 401) {
        //         refreshToken();

        //     }
        //     console.log("my error is " + error);
        // })
}

const menuWithExcelCheckOnlyDate = (props) => {
  
  
  for (let i = 0 ; i < data.length ; i ++ )
  {
    let  startdt = new Date(data[i].startDt);
    let enddt = new Date(data[i].endDt);
  
      data[i].startDt =formatDate(new Date(startdt), "MM/dd/yyyy")
      data[i].endDt  = formatDate(new Date(enddt), "MM/dd/yyyy")
  
  }
  
  return (
    <div>

      <ColumnMenuCheckboxFilter
        {...props}

       
        data={data}
      
      />
    </div>)
}

const menuWithExcelCheckOnly = (props) => {
  
  
 
  
  return (
    <div>

      <ColumnMenuCheckboxFilter
        {...props}

       
        data={data}
      
      />
    </div>)
}


const refreshToken = async (fileName,filePath) => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
        headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

    };
    await axios.post('/token/Refresh',
        postData
    )
        .then((response) => {
            
            tempToken = response.data;
            localStorage.setItem('token', JSON.stringify(response.data));

            GetTmpltList(fileName,filePath);

        })
        .catch((error) => {
            // 
            if(error.code==="ERR_BAD_REQUEST"){
                //setSession("Session Expired");
              }
            console.log("my error is " + error);
        })

}
  const OpenCstmRptPage = (tid, tnm) => {
    localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(tid));
    //console.log(tnm);
    localStorage.setItem("tmpltNm", tnm);
    setShow(true);
  }

  const onDataStateChangeCustom = React.useCallback((e) => {
    

    var startDtInd;
    var endDtInd;
    var rptPckgNmInd;
    var fileNmInd;

     if(e.dataState.filter!==undefined){
      if(e.dataState.filter.filters.length>0){
         startDtInd = 0;
       // for (var i = 0; i < e.stateColumns.length; i++) {
          for (var i = 0; i < e.target.columns.length; i++) {
          
          for (var j = 0; j < e.dataState.filter.filters.length; j++) {
             // a= e.target.columns[i].field;
            if (e.target.columns[i].field == e.dataState.filter.filters[j].filters[0].field) {
             // a.headerClassName="active";
              //Grid.columns[i].headerClassName =="active";
              if (e.dataState.filter.filters[j].filters[0].field == "startDt")
              {
              setstartDt("active")
              startDtInd = 1
              }
              if (e.dataState.filter.filters[j].filters[0].field == "endDt")
              {
              setendDt("active")
              endDtInd= 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "rptPckgNm")
              {
              setrptPckgNm("active")
              rptPckgNmInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "fileNm")
              {
              setfileNm("active")
              fileNmInd = 1;
              }
  

              //dataState.columns[i].headerClassName = "";
              break;
            }
            else{
              if (startDtInd != 1)
              {
              setstartDt("")
              }
              if (endDtInd != 1)
              {
              setendDt("")
              }
              if (rptPckgNmInd != 1)
              {
              setrptPckgNm("")
              }
              if (fileNmInd != 1)
              {
              setfileNm("")
              }
              //e.target.columns[i].headerClassName="";
              //Grid.columns ="active";
            }
          }
        }
      }
      else{
        for (var i = 0; i < e.target.columns.length; i++) {   
              // e.target.columns[i].headerClassName="";
              setstartDt("")
              setendDt("")
              setrptPckgNm("")
              setfileNm("")
            }
          }

          
    }

    if(e.dataState.filter ==undefined)
          {
            setstartDt("")
            setendDt("")
            setrptPckgNm("")
            setfileNm("")
          }
    setDataState(e.dataState);
    setResult(process(data, e.dataState));
  }, []);
  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "") {
        
        let fileName = cellProps.dataItem["fileNm"];
        let filePath = cellProps.dataItem["stmtFileNm"];
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            <button className="btn btn-sm btn-light" title='Download' onClick={() => GetTmpltList(fileName,filePath)}><FaDownload/> </button>
          </td>
        );
      }
    }
    return tdElement;
  };
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getRptIDs = (e) => {
    
    const { value, checked } = e.target;
    if (e.target.checked) {
      setRptIds([...rptIds, value])
    }
    else {
      setRptIds(rptIds.filter((e) => e !== value));
    }
    console.log(`${rptIds} is ${checked}`);

    const elements = document.getElementsByName('checkbox');
    let checkedCount = 0;
    elements.forEach((element) => {
      if (element.checked) {
        checkedCount++;
      }
    })
    if (checkedCount >= 1 && checkedCount<=5) {
      setrptCountMsg("");
      setBtnStatus(false)
    } else {
      setrptCountMsg(checkedCount<1?"Please select atleast one report.":"Please select maximum five reports at a time.")
      setBtnStatus(true)
    }

  }
  const handleChangeTnm = (e) => {
    
    SetTemplateNm(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);
    
    if (!isValid) {
      setBtnStatus2(true);
      setvaldMsg("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus2(true);
      setvaldMsg("Please Enter Template Name.");
    }

    if (isValid && e.target.value !== "") {
      setBtnStatus2(false);
      setvaldMsg("");
    }
  }
  const handleChangeTdesc = (e) => {
    SetTemplateDesc(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);

    if (!isValid) {
      setBtnStatus3(true);
      setvaldMsg2("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus3(true);
      setvaldMsg2("Please Enter Template Description.");
    }
    if (isValid && e.target.value !== "") {
      setBtnStatus3(false);
      setvaldMsg2("");
    }

  }
  
  const saveNewTemplate = async () => {
    
    let idXml = '<Root>';
    idXml = idXml + rptIds.map(function (val) {
      return val !== ',' ? '<Records><ReportId>' + val + '</ReportId></Records>' : '';
    })
    idXml = idXml + '</Root>'
    let RptIds = idXml;

    let TemplateNm = tnm;
    let TemplateDesc = tdsc;
    localStorage.setItem("tmpltNm", tnm);
    let UserId = JSON.parse(localStorage.getItem('userId'));
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    const data = { UserId, TemplateNm, TemplateDesc, RptIds };

    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };

    await axios.post('TemplateProfile/TemplateProfile', data, config)
      .then((response) => {

        //console.log(response);

        if (response.statusText === 'OK' || response.statusText === '') {
          localStorage.setItem("tmpltNm", tnm);
          setRptIds([]);
          SetTmpltDetails(response.data.ocTemplateProfile);
          
          if (response.data.saveTemplateId !== 0) {
            localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(response.data.saveTemplateId));
            navigate("/cstmRptMain");
          }
        }

      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshToken();

        }
        console.log("my error is " + error);
      })

    handleClose();
  }
  const UpdateTmplt = async () => {
    
    let token = tempToken;
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    let TemplateId = localStorage.getItem('tIdForUpdt');
    let TemplateNm = localStorage.getItem('tNmForUpdt');
    let TemplateDesc = localStorage.getItem('tDescForUpdt');
    let InsrtUpdtInd = 1;
    let UserId = JSON.parse(localStorage.getItem('userId'));
    const postData = { TemplateId, TemplateNm, TemplateDesc, UserId, InsrtUpdtInd };

    await axios.post('TemplateProfile/UpdateTemplate', postData, config)
      .then(response => {
        
        const rowData = response.data;
        const templateData = rowData.ocTmpltDetailsT;
        const lstRpts = rowData.ocQualRptLstT;
        SetTmpltDetails(rowData.ocTmpltDetailsT);
        //setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        setResult(rowData.ocTmpltDetailsT, initialDataState);
        // setRptList(rowData.oCQualRptLstT);                
      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshTokenForUpdt();

        }
        console.log("my error is " + error);
      })
  }
  const refreshTokenForUpdt = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData
    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        UpdateTmplt();

      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

  }
  const DateCell = (props) => {

    if (props.field === "startDt") {
      let cdt = new Date(props.dataItem["startDt"]);
      return (
        <td style={{ textAlign: 'left' }}>
          {(cdt.getMonth() + 1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
        </td>
      )
    }
    if (props.field === "endDt") {
      let cdt = new Date(props.dataItem["endDt"]);
      return (
        <td style={{ textAlign: 'left' }}>
          {(cdt.getMonth() + 1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
        </td>
      )
    }
  }
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const enterEdit = (item) => {
    
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    
    localStorage.setItem('tIdForUpdt', event.templateId);
    localStorage.setItem('tNmForUpdt', event.templateNm);
    localStorage.setItem('tDescForUpdt', event.templateDesc);
    UpdateTmplt();
    // window.location.reload(false)
   // console.log("template updated");
    setOpenForm(false);
  };
  const handleCancelEdit = () => {
    console.log("template update cancelled");
    setOpenForm(false);
  };
  
  const windowHeight = useRef(window.innerHeight);

  return (

    <div>
       <NotificationGroup
        style={{
          backgroundColor:'red',
         
          zIndex:99999999,
          right: '45%',
          top: '45%',
          
          alignItems: "center",
          flexWrap: "wrap-reverse",
          
        }}
       
      >
        
        <Zoom >
          {error && (
            <Notification
              type={{
                style: "none",
                icon: false,
                
              }}
              
              style={{backgroundColor:"#de0f00", color:"white"}}
              // closable={true}
              
              // onClose={() =>
              //   setError(false)
              // }
            >
              <FiAlertTriangle size={30}></FiAlertTriangle>&nbsp;<span><b>{errorMessage}</b></span>
            </Notification>
          )}
        </Zoom>
        
      </NotificationGroup>
      <div className='row'>
        <div className="col col-md-12 col-sm-12">
          {/* <div className="card-header tableheader py-1 mb-2">Custom Report</div> */}
          <div className='mx-1 px-1'>
            <Grid style={{ height: windowHeight.current - 100 }}

              //data={resultState.slice(page.skip, page.skip + page.take)}
              // groupable={{
              //   footer: "visible",
              // }}
              data={result}
              resizable={true}
              reorderable={true}
              sortable={true}
              pageable={true}
              onDataStateChange={onDataStateChangeCustom}
              {...dataState}
              cellRender={cellRender}
            >
             <Column field='startDt' menu={true} title="Start Date" cell={DateCell} width="159px" headerClassName={startDt}  columnMenu={menuWithExcelCheckOnlyDate} />
              <Column field='endDt' menu={true} title="End Date" cell={DateCell} width="159px" headerClassName={endDt} columnMenu={menuWithExcelCheckOnlyDate} />
              
              {/* <Column field='id' menu={true} title="Id"  width="80px" columnMenu={ColumnMenu} /> */}
              <Column field='rptPckgNm' menu={true} title="Report Package Name" width="230px" headerClassName={rptPckgNm} columnMenu={menuWithExcelCheckOnly} />
               {/* <Column field='stmtCmpltTm' menu={true} title="Statement Completion Time" width="280px" columnMenu={ColumnMenu} /> */}
              <Column field='fileNm' menu={true} title="File Name" width="auto"  headerClassName = {fileNm} columnMenu={menuWithExcelCheckOnly} />
              <Column field='' title='Download' menu={true}  width="110px" />
            </Grid>
            
          </div>
          
          <div className="row mx-1 my-2">
            <div className="form">
              <div className="input-container">
                <Modal fullscreen={true} show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title> <div className="card-header tableheader"></div></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <br></br>
                  <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                      Close
                    </button>
                    <CustomReportMain></CustomReportMain>

                  </Modal.Body>
                  

                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export default StatementGrid



