import React from "react";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import AcctHoldingGrid from "./acctHoldingGrid";
import { Card, FormControlLabel } from "@mui/material";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Loading from "./loading";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomCalendar } from "./customCalendar";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import {
  FaFileExcel,
  FaPlusSquare,
  FaMinusSquare,
  FaSave,
} from "react-icons/fa";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import moment from "moment/moment";

import Checkbox from "@mui/material/Checkbox";
import { blue, blueGrey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
const AcctHoldingRpt = (acctId) => {
  const [expandedState, setExpandedState] = React.useState(true);
  const [totMarketvalue, setTotMarketValue] = React.useState(0.0);
  const [totalCash, setTotalCash] = React.useState(0.0);
  var date = new Date(localStorage.getItem("processingDate"));
  const [asOfDt, setasOfDt] = React.useState(date);
  const [priceDt, setpriceDt] = React.useState(date);
  const [triggerExport, setTriggerExport] = useState(0);
  const [triggerColapse, setTriggerColapse] = useState(0);
  const [triggerExpand, setTriggerExpand] = useState(0);

  const [triggerColSave, setTriggerColSave] = useState(0);
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));

  //maxDate.setMonth(maxDate.getMonth());
  const [minPDt, setminPDt] = React.useState(minDate);
  const [maxPDt, setmaxPDt] = React.useState(maxDate);

  const [AcctHoldingRptData, populateAcctHoldingRptData] = useState([]);
  const [totalLiqPriBal, populatetotalLiqPriBal] = useState(0.0);
  const [incomeCash, populateincomeCash] = useState(0.0);
  // const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
  //   const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [loading, setLoading] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [effectivecDtInd, seteffectiveDtInd] = useState(true);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();

  function setDate(val) {
    if (val == null) {
      setasOfDt(asOfDt);
    } else {
      setasOfDt(val);
    }
  }
  function setPrcDt(val) {
    if (val == null) {
      setpriceDt(priceDt);
    } else {
      setpriceDt(val);
    }
  }
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked ? true : false);
  };
  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 34;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        // console.log('save in db');
        // console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };
  const GetAcctHoldinData = async () => {
    setLoading(true);

    let token = tempToken;
    // let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = acctId.acctId; //JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    let AsofDt = formatDate(asOfDt, "MM/dd/yyyy");
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    let EffectiveDtInd = effectivecDtInd; //

    let PriceDt = priceDt === null ? "" : formatDate(priceDt, "MM/dd/yyyy");
    let mConsolidationId = localStorage.getItem("pConsId");
    const postData = {
      UserId,
      AcctId,
      AsofDt,
      EffectiveDtInd,
      PriceDt,
      mConsolidationId,
      RltnshpCd,
    };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AcctHolding", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data;
        for (var i = 0; i < rowData.ocAcctHolding.length; i++) {
          if (moment(rowData.ocAcctHolding[i].holdingDate).isValid())
            rowData.ocAcctHolding[i].holdingDate = formatDate(
              new Date(rowData.ocAcctHolding[i].holdingDate),
              "MM/dd/yyyy"
            );
          if (moment(rowData.ocAcctHolding[i].priceDate).isValid())
            rowData.ocAcctHolding[i].priceDate = formatDate(
              new Date(rowData.ocAcctHolding[i].priceDate),
              "MM/dd/yyyy"
            );
        }
        var resultObjectDistinct = orderBy(rowData.ocAcctHolding, [
          { field: "mjrAstNm", dir: "asc" },
          { field: "mnrAstNm", dir: "asc" },
        ]);
        populateAcctHoldingRptData(rowData.ocAcctHolding);
        //setTotMarketValue(rowData.ocAcctHolding[0].market+rowData.ocAcctHolding[0].principalCash+rowData.ocAcctHolding[0].incomeCash+rowData.ocAcctHolding[0].investedIncome)
        getColumnStateDb();
        var sum = 0;
        var sumAccuredInc = 0;
        for (var i = 0; i < rowData.ocAcctHolding.length; i++) {
          sum = sum + rowData.ocAcctHolding[i].market;
          sumAccuredInc = sumAccuredInc + rowData.ocAcctHolding[i].acrdIncmAmt;
        }

        if (rowData.ocAcctHolding.length > 0) {
          //setTotMarketValue(sum + sumAccuredInc + rowData.ocAcctHolding[0].principalCash + rowData.ocAcctHolding[0].incomeCash);
          setTotMarketValue(sum);
          populatetotalLiqPriBal(rowData.ocAcctHolding[0].totalLiqPriBal);
          populateincomeCash(rowData.ocAcctHolding[0].incomeCash);
        }

        setTotalCash(rowData.totalCash);
        setLoading(false);
      })
      .catch((error) => {
        debugger;
        console.log("my error is 1 " + error);
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  let userId = JSON.parse(localStorage.getItem("userId"));
  useEffect(() => {
    const fetchData = () => {
      GetAcctHoldinData();
      getColumnStateDb();
    };
    fetchData();
  }, [userId]);
  const formSubmit = (event) => {
    setTriggerColSave(0);
    GetAcctHoldinData();
    setExpandedState(true);
  };

  const handleExcelExport = (event) => {
    setTriggerExport((triggerExport) => triggerExport + 1);
  };

  const handleTriggerColapse = (event) => {
    setTriggerColapse((triggerColapse) => triggerColapse + 1);
    setExpandedState(false);
  };

  const handleTriggerExpand = (event) => {
    setTriggerExpand((triggerExpand) => triggerExpand + 1);
    setExpandedState(true);
  };
  const handleTriggerColSave = (event) => {
    console.log("save called :", triggerColSave);
    setTriggerColSave((triggerColSave) => triggerColSave + 1);
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctHoldinData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {})
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    localStorage.removeItem("token");
    localStorage.clear();
  };
  const themes = useSelector(selectTheme);
  const datepattern = "mm/dd/yyyy";
  if (loading) {
    return (
      <div className="container-fluid">
        <Loading />
      </div>
    );
  }

  console.log("minDate", minDate);
  console.log("maxDate", maxDate);
  return (
    <div>
      <div className="container-fluid">
        <table
          className="table tablel-bordered border border-bottom border-gray m-0 w-100 bg-show"
          style={{ backgroundColor: themes === "dark" ? "#4f4f4f" : "#d9f3ff" }}
        >
          <tbody>
            <tr>
              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                Total Liquid Principal Balance :
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(totalLiqPriBal, "##,#.00")}{" "}
                </span>
              </td>
              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                Income:
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(incomeCash, "##,#.00")}
                </span>
              </td>
              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                {" "}
                <BsFillInfoCircleFill
                  title="Total market value includes accrued income + real time manual asset price adjustments."
                  color={themes === "dark" ? "#47daff" : "#0070cc"}
                  size={15}
                />
                &nbsp; Total Market Value:
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(totMarketvalue, "##,#.00")}
                </span>
              </td>
              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                Total Cash:
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(totalCash, "##,#.00")}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <>
          <table className="table tablel-bordered border border-bottom border-gray m-0 w-100">
            <tbody>
              <tr>
                <td className="align-center">
                  {expandedState ? (
                    <>
                      <div
                        className="btn btn-outline-secondary mx-2 btn-sm"
                        onClick={handleTriggerColapse}
                      >
                        <FaMinusSquare></FaMinusSquare> collapse
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="btn btn-outline-secondary mx-2 btn-sm"
                        onClick={handleTriggerExpand}
                      >
                        {" "}
                        <FaPlusSquare></FaPlusSquare> expand
                      </div>
                    </>
                  )}
                </td>
                <td className="align-center">
                  <button
                    className="btn btn-outline-secondary mx-2 btn-sm"
                    onClick={handleExcelExport}
                  >
                    <FaFileExcel></FaFileExcel> export
                  </button>
                </td>
                <td className="align-center">
                  <button
                    className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
                    onClick={handleTriggerColSave}
                  >
                    <FaSave></FaSave> save columns
                  </button>
                </td>
                <td className="align-center">
                  <span
                    className="py-1"
                    style={{ color: themes === "dark" ? "white" : "#454545" }}
                  >
                    As Of date:{" "}
                  </span>
                  <DatePicker
                    format="MM/dd/yyyy"
                    // calendar={CustomCalendar}

                    className="form-control form-control-sm"
                    size={"small"}
                    width={150}
                    value={asOfDt}
                    max={maxDate}
                    min={minDate}
                    onChange={(e) => {
                      setDate(e.value);
                      setPrcDt(e.value);
                    }}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                  />
                </td>
                <td className="align-center">
                  <span
                    className="py-1"
                    style={{ color: themes === "dark" ? "white" : "#454545" }}
                  >
                    Price date:{" "}
                  </span>
                  <DatePicker
                    format="MM/dd/yyyy"
                    // calendar={CustomCalendar}
                    className="form-control form-control-sm"
                    size={"small"}
                    max={maxDate}
                    min={minDate}
                    width={150}
                    value={priceDt}
                    onChange={(e) => {
                      setPrcDt(e.value);
                    }}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                  />
                </td>

                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "0398fc",
                        "&.Mui-checked": {
                          color: "#0398fc",
                        },
                      }}
                      checked={effectivecDtInd}
                      onChange={handleEffectiveDtInd}
                    />
                  }
                  style={{ color: themes === "dark" ? "white" : "#454545" }}
                  label="Effective Date Indicator"
                />
                <td className="align-bottom">
                  <input
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={formSubmit}
                    value="submit"
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
        </>

        {/* <div className="accordion" id="accordionExample2">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Holdings Report
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
      <div className="accordion-body">
     
         <hr></hr>

      <form onSubmit={formSubmit}  >
      <div className='row align-items-center'>
      
      <div className='col-md-2'>
        <span className='py-1'>As Of Date</span>
                  <DatePicker id="dpAsOfDt"
                    defaultValue={asOfDt}
                    format="MM/dd/yyyy"
                    calendar={CustomCalendar}
                   
                   
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    //formatPlaceholder="datepattern"
                    //placeholder="mm/dd/yyyy"
                    min={minPDt}
                    max={maxPDt}
                    //disabled={isDisabled}
                    onChange={(e) => {
                      setDate(e.value);

                    }}

                  />


        </div>
        <div className='col-md-2'>
        <span className='py-1'>Price Date</span>
                  <DatePicker
                    //disabled={true}
                    title='mm/dd/yyyy'
                    defaultValue={priceDt}
                    format="MM/dd/yyyy"
                    calendar={CustomCalendar}
                    min={minPDt}
                    max={maxPDt}
                    onChange={(e) => {
                      setPrcDt(e.value);

                    }}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                  />


        </div>

        <div className='col-md-2'>
        <label className='form-check-label px-2'>Effective Date Indicator</label>

<input className='form-check-input' type='checkbox' name='chkInvTrgMix'
  checked={effectivecDtInd} onChange={handleEffectiveDtInd}  ></input>


          </div>
          <div className='col-md-2'>
                  <input type="submit" className='btn btn-outline-primary' value="Submit"></input>

                </div>
                <div className='col-md-4'></div>

    
      </div>



      </form>

    
      
      
      </div>
    </div>
  </div>

  </div> */}

        <div>
          <div className="row d-flex  justify-content-between">
            <AcctHoldingGrid
              data={AcctHoldingRptData}
              flag={isColumnSave}
              triggerExport={triggerExport}
              triggerColapse={triggerColapse}
              triggerExpand={triggerExpand}
              triggerColSave={triggerColSave}
              incomeCash={incomeCash}
              totalLiqPriBal={totalLiqPriBal}
              totMarketvalue={totMarketvalue}
              totalCash={totalCash}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcctHoldingRpt;
