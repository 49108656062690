import React from "react";
import * as ReactDOM from "react-dom";
import { useState, useMemo, useEffect, useRef } from "react";
import axios from "axios";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Button, Card } from "@mui/material";
import {
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaPlus,
  FaSave,
} from "react-icons/fa";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade, Slide, Zoom } from "@progress/kendo-react-animation";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
const aggregates = [
  {
    field: "pCash",
    aggregate: "sum",
  },
  {
    field: "iCash",
    aggregate: "sum",
  },
  // {
  //   field: 'shares',
  //   aggregate: 'sum',
  // },
];
const initialGroup = [
  // {
  //   field: 'tranTypNm',
  // },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const DATA_ITEM_KEY = "tranId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const AcctTransactionGrid = ({
  data,
  flag,
  triggerExport,
  triggerColapse,
  triggerExpand,
  triggerColSave,
}) => {
  const [success, setSuccess] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const dataExport = process(data, {
    group: initialGroup,
  }).data;

  //const data = products;
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const [locked, setLocked] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState({});
  const [columnLatest, setColumnLatest] = React.useState(null);
  const themes = useSelector(selectTheme);
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const DateCell = (props) => {
    if (props.field === "prcsDt") {
      let cdt = new Date(props.dataItem["prcsDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
    if (props.field === "modDt") {
      let cdt = new Date(props.dataItem["modDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
  };

  const onSelectionChange = (event) => {
    // data.map((item) => {
    //     item.selected = false;
    // });

    let theSelectedItem = event.dataItems[event.endRowIndex];

    let newData = data.map((item) => {
      item.selected = false;
      if (item.tranId === theSelectedItem.tranId) {
        item.selected = !item.selected;
      }
      return item;
    });
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
    setColumnLatest(props.target._columns);
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }
    // newColumnsState[2].footerCell=totalSum ;
    // newColumnsState[3].footerCell=totalSum ;
    // newColumnsState[4].footerCell=totalSum ;

    //newColumnsState[0].show = localStorage.getItem("SelAcctId")>0?false:true  ;
    var tempcolumn = [];

    var j = 0;
    for (var i = 0; i < newColumnsState.length; i++) {
      //i=newColumnsState[i].field==="accountName"&&localStorage.getItem("SelAcctId") > 0?i+1:i;
      tempcolumn[j] = newColumnsState[i];

      j++;
    }
    return tempcolumn;
    const [, ...restnew] = newColumnsState;
    if (localStorage.getItem("SelAcctId") > 0) {
      return restnew;
    } else {
      return newColumnsState;
    }
    // newColumnsState[1].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[2].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[3].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithExcelCheck;
    // newColumnsState[7].columnMenu = menuWithExcelCheck;
    // newColumnsState[8].columnMenu = menuWithExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu = menuWithExcelCheck;
    // newColumnsState[12].columnMenu = menuWithExcelCheck;
    // newColumnsState[13].columnMenu = menuWithExcelCheck;
    // newColumnsState[14].columnMenu = menuWithExcelCheck;
    // newColumnsState[15].columnMenu = menuWithExcelCheck;
    // newColumnsState[16].columnMenu = menuWithoutExcelCheck;;
    // newColumnsState[17].columnMenu = menuWithExcelCheck;
    // newColumnsState[18].columnMenu = menuWithExcelCheck;
    // newColumnsState[19].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    //newColumnsState[19].columnMenu = menuWithoutExcelCheck;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const defaultColumnsCopy = [
    // {
    //   title: 'Branch',
    //   field: 'branchName',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account',
    //   field: 'accountType',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Account#",
      field: "accountName",
      minWidth: 120,
      show: localStorage.getItem("SelAcctId") > 0 ? false : true,
      filter: "text",
      //locked: true,
    },
    {
      title: "Trans Type",
      field: "tranTypNm",
      minWidth: 120,
      show: true,
      filter: "text",
      //locked: true,
    },

    {
      title: "Description",
      field: "totalLine",
      minWidth: 600,
      show: true,
      filter: "text",
      //locked: true,
    },
    {
      title: "Trans Date",
      field: "prcsDt",
      minWidth: 120,
      show: true,
      filter: "date",
      locked: false,
    },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    {
      title: "Principal($)",
      field: "pCash",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      //footerCell: totalSum,
      aggregate: "sum",
    },
    {
      title: "Income($)",
      field: "iCash",
      minWidth: 140,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      // footerCell: totalSum,
      aggregate: "sum",
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: 120,
      show: true,
      filter: "numeric",
      format: "{0:d2}",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
    },
    {
      title: "Created By",
      field: "createdByInit",
      minWidth: 200,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Location",
      field: "location",
      minWidth: 120,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Registration",
      field: "registration",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Effective Date",
      field: formatDate("effectiveDate", "mdy"),
      minWidth: 250,
      show: true,
      locked: false,
      filter: "date",
    },
    {
      title: "Entry Date",
      field: formatDate("entryDate", "mdy"),
      minWidth: 250,
      show: true,
      locked: false,
      filter: "date",
    },
    {
      title: "Transaction Explanation",
      field: "glDescription",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Long Name",
      field: "longName",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Payment Code",
      field: "paymentCode",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
    },
    // {
    //   title: 'Process Code',
    //   field: 'processCode',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    //   headerClassName: "rightHeader"
    // },
    {
      title: "Remittance Type",
      field: "remittanceType",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Sec Symbol",
      field: "secSymbol",
      minWidth: 200,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Transaction #",
      field: "sequenceNumber",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      headerClassName: "rightHeader",
      headerCellOptions: { textAlign: "right" },
    },
    {
      title: "Payment Counterparty",
      field: "vendorName",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Security",
      field: "security",
      minWidth: 120,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Trade Date",
      field: formatDate("tradeDate", "mdy"),
      minWidth: 200,
      show: true,
      filter: "date",
      locked: false,
    },
  ];
  const defaultColumns = [
    // {
    //   title: 'Branch',
    //   field: 'branchName',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account',
    //   field: 'accountType',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Account#",
      field: "accountName",
      minWidth: 120,
      show: localStorage.getItem("SelAcctId") > 0 ? false : true,
      filter: "text",
      //locked: true,
      orderIndex: -1,
    },
    {
      title: "Trans Type",
      field: "tranTypNm",
      minWidth: 120,
      show: true,
      filter: "text",
      orderIndex: 0,
      //locked: true,
    },

    {
      title: "Description",
      field: "totalLine",
      minWidth: 600,
      show: true,
      filter: "text",
      orderIndex: 1,
      //locked: true,
    },
    {
      title: "Trans Date",
      field: "prcsDt",
      minWidth: 120,
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 2,
    },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    {
      title: "Principal($)",
      field: "pCash",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      //footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 3,
    },
    {
      title: "Income($)",
      field: "iCash",
      minWidth: 140,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      // footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 4,
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: 120,
      show: true,
      filter: "numeric",
      format: "{0:d2}",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 5,
    },
    {
      title: "Created By",
      field: "createdByInit",
      minWidth: 200,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 6,
    },
    {
      title: "Location",
      field: "location",
      minWidth: 120,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 7,
    },
    {
      title: "Registration",
      field: "registration",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 8,
    },
    {
      title: "Effective Date",
      field: formatDate("effectiveDate", "mdy"),
      minWidth: 250,
      show: true,
      locked: false,
      filter: "date",
      orderIndex: 9,
    },
    {
      title: "Entry Date",
      field: formatDate("entryDate", "mdy"),
      minWidth: 250,
      show: true,
      locked: false,
      filter: "date",
      orderIndex: 10,
    },
    {
      title: "Transaction Explanation",
      field: "glDescription",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 11,
    },
    {
      title: "Long Name",
      field: "longName",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 12,
    },
    {
      title: "Payment Code",
      field: "paymentCode",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 13,
    },
    // {
    //   title: 'Process Code',
    //   field: 'processCode',
    //   minWidth: 120,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    //   headerClassName: "rightHeader"
    // },
    {
      title: "Remittance Type",
      field: "remittanceType",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 14,
    },
    {
      title: "Sec Symbol",
      field: "secSymbol",
      minWidth: 200,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 15,
    },
    {
      title: "Transaction #",
      field: "sequenceNumber",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: false,
      headerCellOptions: { textAlign: "right" },

      headerClassName: "rightHeader",
      orderIndex: 16,
    },
    {
      title: "Payment Counterparty",
      field: "vendorName",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 17,
    },
    {
      title: "Security",
      field: "security",
      minWidth: 120,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 18,
    },
    {
      title: "Trade Date",
      field: formatDate("tradeDate", "mdy"),
      minWidth: 200,
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 19,
    },
  ];
  const CustomGroupHeader = (props) => {
    return `${props.value}`;
  };
  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;

  if (
    localStorage.getItem("TransactDataState") !== undefined &&
    localStorage.getItem("TransactDataState") !== null
  ) {
    var datastatetemp = JSON.parse(localStorage.getItem("TransactDataState"));
    if (datastatetemp.filter !== undefined) {
      if (datastatetemp.filter.filters.length > 0) {
        for (var i = 0; i < GridColumns.length; i++) {
          for (var j = 0; j < datastatetemp.filter.filters.length; j++) {
            if (
              GridColumns[i].field ==
              datastatetemp.filter.filters[j].filters[0].field
            ) {
              GridColumns[i].headerClassName = "active";
              break;
            } else {
              GridColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < GridColumns.length; i++) {
          GridColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < GridColumns.length; i++) {
        GridColumns[i].headerClassName = "";
      }
    }
  }
  for (var i = 0; i < GridColumns.length; i++) {
    for (var j = 0; j < defaultColumnsCopy.length; j++) {
      if (
        GridColumns[i].field === defaultColumnsCopy[j].field &&
        defaultColumnsCopy[j].headerClassName !== undefined
      )
        GridColumns[i].headerClassName =
          defaultColumnsCopy[j].headerClassName +
          " " +
          GridColumns[i].headerClassName;
    }
  }

  //const GridColumns =defaultColumns;
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    filter:
      localStorage.getItem("TransactDataState") === undefined ||
      localStorage.getItem("TransactDataState") === null
        ? undefined
        : JSON.parse(localStorage.getItem("TransactDataState")).filter,
    sort:
      localStorage.getItem("TransactDataState") === undefined ||
      localStorage.getItem("TransactDataState") === null
        ? [
            {
              field: "effectiveDate",
              dir: "desc",
            },
          ]
        : JSON.parse(localStorage.getItem("TransactDataState")).sort,
    take: 40000,
    skip: 0,
    // group: [
    //   {
    //     field: 'tranTypNm',
    //   },
    // ],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    // group: [{ field: 'tranTypNm' }]
  });

  const [result, setResult] = React.useState(
    processWithGroups(
      data,
      localStorage.getItem("TransactDataState") === undefined ||
        localStorage.getItem("TransactDataState") === null
        ? initialState.dataState
        : JSON.parse(localStorage.getItem("TransactDataState"))
    )
  );

  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );
  const [dataState, setDataState] = React.useState(initialState.dataState);

  const [, ...rest] = GridColumns;
  var tempcolumn = [];

  var j = 0;
  for (var i = 0; i < GridColumns.length; i++) {
    //i=GridColumns[i].field==="accountName" && localStorage.getItem("SelAcctId") > 0?i+1:i;
    tempcolumn[j] = GridColumns[i];

    j++;
  }

  const [stateColumns, setStateColumns] = React.useState(tempcolumn);
  //const [stateColumns, setStateColumns] = React.useState(localStorage.getItem("SelAcctId")>0?rest:GridColumns);
  const [currentColumns, setCurrentColumns] = React.useState(GridColumns);
  //GridColumns[0].show = localStorage.getItem("SelAcctId")>0?false:true  ;
  // GridColumns[1].columnMenu = menuWithoutExcelCheck;
  // GridColumns[2].columnMenu = menuWithoutExcelCheck;
  // GridColumns[3].columnMenu = menuWithoutExcelCheck;
  // GridColumns[4].columnMenu = menuWithoutExcelCheck;
  // GridColumns[5].columnMenu = menuWithoutExcelCheck;
  // GridColumns[6].columnMenu = menuWithExcelCheck;
  // GridColumns[7].columnMenu = menuWithExcelCheck;
  // GridColumns[8].columnMenu = menuWithExcelCheck;
  // GridColumns[9].columnMenu = menuWithoutExcelCheck;
  // GridColumns[10].columnMenu = menuWithoutExcelCheck;
  // GridColumns[11].columnMenu = menuWithExcelCheck;
  // GridColumns[12].columnMenu = menuWithExcelCheck;
  // GridColumns[13].columnMenu = menuWithExcelCheck;
  // GridColumns[14].columnMenu = menuWithExcelCheck;
  // GridColumns[15].columnMenu = menuWithExcelCheck;
  // GridColumns[16].columnMenu = menuWithoutExcelCheck;;
  // GridColumns[17].columnMenu = menuWithExcelCheck;
  // GridColumns[18].columnMenu = menuWithExcelCheck;
  // GridColumns[19].columnMenu = menuWithoutExcelCheck;
  // GridColumns[0].columnMenu = menuWithExcelCheck;
  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 35;
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        // console.log(response);
        const rowData = response.data;
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  const saveColumnsState = () => {
    console.log("save called!!");
    const columns = stateColumns;

    for (var i = 0; i < columns.length; i++) {
      if (columns[i].headerClassName !== undefined) {
        if (columns[i].headerClassName.includes("rightHeader")) {
          columns[i].headerClassName = "rightHeader";
        } else {
          columns[i].headerClassName = "";
        }
      }
    }
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);

    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  // useEffect(() => {
  //
  //   saveColumnsState(stateColumns);
  // }, [stateColumns]);

  const dataStateChange = (event) => {
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumnsCopy.length; j++) {
        if (stateColumns[i].field === defaultColumnsCopy[j].field)
          stateColumns[i].headerClassName =
            defaultColumnsCopy[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });
    localStorage.setItem(
      "TransactDataState",
      JSON.stringify(updatedState.dataState)
    );
    setResult(processWithGroups(data, updatedState.dataState));
    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  // const handleColumnLockToggle = (columnField, state) => {
  //   let newColumns = currentColumns.map((column) => {
  //     if (column.field === columnField) {
  //       column.locked = state;
  //     }

  //     return column;
  //   });
  //   setCurrentColumns(newColumns);
  // }; // place all locked columns first

  // const expandChange = (event) => {
  //
  //   const isExpanded =
  //     event.dataItem.expanded === undefined
  //       ? event.dataItem.aggregates
  //       : event.dataItem.expanded;
  //   event.dataItem.expanded = !isExpanded;
  //   setResult({ ...result });
  // };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleExpand = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const NumberCell = (props) => {
    if (props.field === "branchName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tranTypNm") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "totalLine") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "administrator") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "investmentOfficer") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "pCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "iCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "shares") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.

      const columns = [
        { field: "pCash", aggregate: "sum" },
        { field: "iCash", aggregate: "sum" },
        { field: "shares" },
        { field: "createdByInit" },
        { field: "location" },

        { field: "registration" },
        { field: "effectiveDate" },
        { field: "entryDate" },
        { field: "glDescription" },
        { field: "longName" },
        { field: "paymentCode" },
        { field: "remittanceType" },
        { field: "secSymbol" },
        { field: "sequenceNumber" },
        { field: "vendorName" },
        { field: "security" },
        { field: "tradeDate" },
      ];
      if (!tdElement.props.colSpan) {
        return <td></td>;
      }
      let currentColumns = stateColumns.filter((c) => c.show);
      currentColumns = currentColumns.map((col) => {
        let aggrCol = columns.find((c) => {
          return c.field == col.field;
        });
        if (aggrCol && aggrCol.aggregate) {
          console.log("aggregate");
          return {
            ...col,
            aggregate: aggrCol.aggregate,
          };
        } else {
          return col;
        }
      });
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - currentColumns.length}
          ></td>
          {getCells(
            currentColumns.sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            }),
            cellProps
          )}
        </>
      );
    }

    if (cellProps.rowType === "groupFooter") {
      // if (cellProps.field === "shares") {

      //   return (
      //     <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
      //       {formatNumber(cellProps.dataItem.aggregates.shares.sum, "##,#.00")}
      //     </td>
      //   );
      // }

      if (cellProps.field === "iCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.aggregates.iCash.sum, "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "pCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.aggregates.pCash.sum, "##,#.00")}
          </td>
        );
      }
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "prcsDt") {
        let cdt = new Date(cellProps.dataItem["prcsDt"]);
        return (
          <td
            style={{ textAlign: "left" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "effectiveDate") {
        let cdt = new Date(cellProps.dataItem["effectiveDate"]);
        return (
          <td
            style={{ textAlign: "left" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "entryDate") {
        let cdt = new Date(cellProps.dataItem["entryDate"]);
        return (
          <td
            style={{ textAlign: "left" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "tradeDate") {
        let cdt = new Date(cellProps.dataItem["tradeDate"]);
        return (
          <td
            style={{ textAlign: "left" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "holdingDate") {
        let cdt = new Date(cellProps.dataItem["holdingDate"]);
        return (
          <td
            style={{ textAlign: "left" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "pCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "iCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "shares") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "processCode") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "sequenceNumber") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "###")}
          </td>
        );
      }
    }

    return tdElement;
  };

  let _pdfExport;
  const exportPDF = () => {
    //let oldDataState = { ...dataState };
    // if (pdfExportComponent.current) {
    //setDataState({ ...dataState, skip: 0, take: Number.MAX_VALUE });

    _pdfExport.save();

    // setTimeout(() => {
    //   setDataState(oldDataState);
    // });
  };

  useEffect(() => {
    if (data) {
      setResult(processWithGroups(data, initialState.dataState));
    }
  }, [data]);

  useEffect(() => {
    handleColapse();
  }, []);
  useMemo(() => {
    if (triggerExport) {
      excelExport();
    }
  }, [triggerExport]);
  useEffect(() => {
    if (triggerColSave) {
      saveColumnsState();
    }
  }, [triggerColSave]);
  // useMemo(() => {

  //   if (triggerExpand)
  //    {
  //     handleExpand();
  //    }
  //   }, [triggerExpand]);

  //   useMemo(() => {

  //     if (triggerColapse)
  //      {
  //       handleColapse();
  //      }
  //     }, [triggerColapse]);
  const windowHeight = useRef(window.innerHeight);
  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? localStorage.getItem("pRltncd") == null ||
        localStorage.getItem("pRltncd") == undefined ||
        localStorage.getItem("pRltncd") == ""
        ? "Consolidation:" + localStorage.getItem("pConsName")
        : "Relationship Code:" + localStorage.getItem("pRltncd")
      : "Account Number:" + localStorage.getItem("SelAcctNm");

  return (
    <>
      <NotificationGroup
        style={{
          zIndex: 9999999999999999,
          right: "55%",
          top: "12%",
          width: "50px",
          alignItems: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <Slide>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}

              // closable={true}

              // onClose={() =>
              //   setError(false)
              // }
            >
              Saved successfully.
            </Notification>
          )}
        </Slide>
      </NotificationGroup>
      <ExcelExport
        data={dataExport}
        group={initialGroup}
        ref={_export}
        fileName={excelTitle + "_AcctTransactDateaRange.xlsx"}
      >
        <ExcelExportColumnGroup
          title={
            excelTitle +
            "  Processing Date: " +
            localStorage.getItem("processingDate")
          }
          headerCellOptions={{
            textAlign: "left",
          }}
        >
          {stateColumns
            .sort(function (a, b) {
              return a.orderIndex - b.orderIndex;
            })
            .map((w) => {
              return (
                <ExcelExportColumn
                  field={w.field}
                  hidden={!w.show}
                  title={w.title}
                  headerCellOptions={w.headerCellOptions}
                  cellOptions={w.cellOptions}
                />
              );
            })}

          {/* <ExcelExportColumn field="accountName" title="Account#" hidden={localStorage.getItem("SelAcctId")>0?true:false} />
              <ExcelExportColumn
                 field="tranTypNm"
                 //hidden={true}
                 title="Trans Type"
                 width={150} 
               />
              
              <ExcelExportColumn field="totalLine" title="Description" width={500}   />
              <ExcelExportColumn field="prcsDt" title="Date"   />
              <ExcelExportColumn field="pCash" headerCellOptions={{ textAlign: "right", }}     cellOptions={{   format: "#,##0.00", }} title="Principal($)"   />
              <ExcelExportColumn field="iCash" headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }}   title="Income($)"   />
              <ExcelExportColumn field="shares"  headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }}   title="Shares"   />
              <ExcelExportColumn field="createdByInit" title="Created By" width={150}   />
              <ExcelExportColumn field="location" title="Location" width={150}   />
              <ExcelExportColumn field="registration" title="Registration" width={150}   />
              <ExcelExportColumn field="effectiveDate" title="Effective Date" width={150}   />
              <ExcelExportColumn field="entryDate" title="Entry Date" width={150}   />
              <ExcelExportColumn field="glDescription" title="Transaction Explanation" width={150}   />
              <ExcelExportColumn field="longName" title="Long Name" width={150}   />
              <ExcelExportColumn field="paymentCode" title="Payment Code" width={150}   />
              
              <ExcelExportColumn field="remittanceType" title="Remittance Type" width={150}   />
              <ExcelExportColumn field="secSymbol" title="Sec Symbol" width={150}   />
              <ExcelExportColumn field="sequenceNumber" headerCellOptions={{ textAlign: "right", }} title="Transaction #" width={150}   />
              <ExcelExportColumn field="vendorName" title="Payment Counterparty" width={150}   />
              <ExcelExportColumn field="security" title="Security" width={150}   />
              <ExcelExportColumn field="tradeDate" title="Trade Date" width={150}   /> */}
        </ExcelExportColumnGroup>
      </ExcelExport>
      <Grid
        style={{
          height: windowHeight.current - 150,
          width: "auto",
          position: "absolute",
          border: "none",
        }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={onColumnReorder}
        onColumnResize={onColumnResize}
        expandField="expanded"
        onExpandChange={expandChange}
        cellRender={cellRender}
        sortable={true}
        resizable={true}
        pageable={{
          buttonCount: 0,
          info: true,
          previousNext: false,
        }}
        reorderable={true}
        scrollable="scrollable"
        pageSize={2000}
        groupable={{
          footer: "none",
          enabled: false,
        }}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
      >
        {/* <GridToolbar>

            <div className="col text-start">
          {
            expandedState?
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleColapse}  ><FaMinus className='mx-2'></FaMinus></Button></>
            :
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleExpand} ><FaPlus className='mx-2'></FaPlus></Button></>
          }
          </div>
          <ButtonKendo className="buttons-container-button" icon="excel" onClick={excelExport}>
          Export
        </ButtonKendo>



        </GridToolbar> */}

        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                columnMenu={(props) => (
                  <>
                    {column.filter === "text" &&
                    column.title !== "Description" &&
                    column.title !== "Transaction Explanation" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : column.filter === "numeric" ||
                      column.title === "Description" ||
                      column.title === "Transaction Explanation" ? (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}

                //cell={NumberCell}
                // columnMenu={(props) => (
                //   <CustomColumnMenuNoGrp
                //     {...props}
                //     columns={stateColumns}
                //     onColumnsSubmit={onColumnsSubmit}
                //   />
                // )}
              />
            )
        )}
      </Grid>
      {themes === "dark" ? (
        <style>
          {`
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}
  .k-calendar   .k-focus
{

  background-color: #78e4ffa8 !important;

}
  .k-calendar   .k-focus
{
  background: radial-gradient(#ffffff, #000000e0) !important;
  
}
.k-calendar   .k-focus:hover
{
  background: #5665b9b9 !important;
  
}
.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </>
  );
};

export default AcctTransactionGrid;
