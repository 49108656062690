import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from "bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {Core} from "@grapecity/activereports";
// import "@progress/kendo-theme-bootstrap/dist/all.css";
import { Provider } from 'react-redux';
import store from './app/store';
Core.setLicenseKey("*.fi-tek.com,735927133394133#B1vVDSVTMadnVPhTRWl7TEpWUHFGNxw6RN5GZjd4M5FUcypnbTJ4R5hXVCNUe8AFUwtkaNlTStl6LSlDRn3idJxUNvkWNUdnZ6NzZ8BVQtdXOPdXYEF5ULdzKHljT0dEWJVFZ9F6dkZ5LtJmUEl5cuJFMXZEU7lEZ6Q5UDJkcvYnYuFzRjNzNQFFM6l4K4M7M9AjQMhXRjZnVwImVz2iZKRUVatGZoZHZHZWNUhXWxd5ThNnQUJjd7UjeWRnVzA7YOVUeth6ZupWe9VUVrNWZ9l5Lxg5MVNWZwUGZptWUwF4LCFmV7VlbyNTcONVU9YnSiojITJCLiEEM7EkMEJjI0ICSiwiM5EDO9YDOwQTM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsICMxATN9ADIxEzMwQjMwIjI0ICdyNkIsISbvNmLrVGdtkmZuoiI0IyctRkIsIyQMxEIssWZU5SaGJiOiEmTDJCLiMzMxQTOzMzMxcjM9UzM7IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPB96RKdmcxJ5bEFUM0NFNwdnZIVnTpVzT7k7Uyl7c9lFUJBHdzcEUQpXSw8WehlVOktWYy4mTkJHeVhVaihjUYl6b8BlbvUmNW9Uekt6Vod4NURVZUp4cnxEOqt6KohWWDRmYCDVPD");



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
  <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
