import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { weekInYear, addDays } from '@progress/kendo-date-math';
import Modal from 'react-bootstrap/Modal';
import Enumerable from 'linq';
import { filterBy } from '@progress/kendo-data-query';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import StatementGrid from './statementGrid';
import { CustomCalendar } from "./customCalendar";
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBookOpen, FaEye, FaPlus } from 'react-icons/fa';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";

import TextField from '@mui/material/TextField';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import Loading from './loading';
import { FaFileExcel, FaSave } from 'react-icons/fa';
import CustomDashboardGrd from './cstmDashboardGrd'

import { FcExpired } from 'react-icons/fc';
import LogDataGrid from './logdataGrid';
import { useSelector } from 'react-redux';
import { selectTheme } from '../features/theme/themeSlice';


const LogData = () => {
    
const themes=useSelector(selectTheme);
    const [loading, setLoading] = React.useState(true);
    const [logData, SetlogData] = React.useState([]);
    const [qualRpts, setRptList] = React.useState([]);
    const [flag, setFlag] = React.useState(0);
    const [triggerExport, setTriggerExport] = useState(0);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [toDate, setToDate] = React.useState(new Date());
    var date = new Date(localStorage.getItem('processingDate'));

    //date.setMonth(date.getMonth() - 1);
    const [frmDate, setfrmDate] = React.useState(addDays(new Date(),-7));
    const maxDt = addDays(new Date(localStorage.getItem('processingDate')), 365);
    const minDt = date;
    const [session, setSession] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            
            
            try {
                GetTmpltList();
            }
            catch (errMsg) {
                console.log(errMsg)
            }
        }
        fetchData();
    }, []);


    const handleExcelExport = (event) => {
        setTriggerExport((triggerExport) => triggerExport + 1);
      }



    const GetTmpltList = async () => {
        
        setLoading(true);
        let token = tempToken;
        let ToDate = formatDate(toDate, "MM/dd/yyyy");
        let FromDate = formatDate(frmDate, "MM/dd/yyyy");
        //let AcctId=2392;
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const postData = { FromDate, ToDate };

        await axios.post('/AuthLog', postData, config)
            .then(response => {
                
                const rowData = response.data.logins===null?[]:response.data.logins;
                SetlogData(rowData);
                for(var i=0;i<rowData.length;i++){
                    var date=new Date (rowData[i].loginDate);
                    rowData[i].loginDate=(date.getMonth() + 1).toString().padStart(2, '0')+"/"+date.getDate().toString().padStart(2, '0')+"/"+date.getFullYear()+"  "+date.getHours().toString().padStart(2, '0')+":"+date.getMinutes().toString().padStart(2, '0');
                }
                
                setLoading(false);
                setFlag(1);
            })
            .catch((error) => {
                
                if (error.response.status === 401) {
                    refreshToken();


                }
                console.log("my error is " + error);
            })
    }
    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData
        )
            .then((response) => {
                
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));

                GetTmpltList();

            })
            .catch((error) => {
                // 
                if (error.code === "ERR_BAD_REQUEST") {
                    setSession("Session Expired");
                }
                console.log("my error is " + error);
            })

    }
    const filterData = (filter) => {

        // const dataAcct = selAcctData.slice();
        return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
    };
    // const handleChange = (event) => {
    //     
    //       if (event.target.value === null) {
    //         SetselAcct(selAcct);
    //         //GetUpdatedAccountProfile(0);
    //         localStorage.setItem('IsAcctSelected', false);
    //         localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
    //         }
    //       else {
    //         SetselAcct(event.target.value);


    //         localStorage.setItem('IsAcctSelected', true);
    //         localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));

    //         //GetUpdatedAccountProfile(event.target.value.acctId);
    //       }

    //     };
    // const filterChange = (event) => {

    //     setSelAcctData(filterData(event.filter));
    //   };

    const signOut = () => {

        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        axios.post('/token/revoke',
            postData,
            config
        )
            .then((response) => {
                // 
                // localStorage.setItem('token', '');
                //console.log(response);
                // navigate("/");

                // if (response.statusText === '') {



                // }


            })
            .catch((error) => {
                // 
                console.log("my error is " + error);
            })

        // let tokenNew={token:''};
        // localStorage.setItem('token',JSON.stringify(tokenNew));
        // localStorage.setItem("AcctSelected",null);
        localStorage.removeItem('token');
        localStorage.clear();
        //firebaseApp.auth.signOut();

    }
    function setDate(val) {
        
        if (val == null) {
          setfrmDate(frmDate);
        //   var newToDate = new Date(frmDate);
        //   newToDate.setMonth(frmDate.getMonth() + 12);
        //   if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
        //     setToDate(new Date(localStorage.getItem('processingDate')));
        //   }
        //   else {
        //     setToDate(newToDate);
        //   }
    
        }
        else {
          setfrmDate(val);
        //   var newToDate = new Date(val);
        //   newToDate.setMonth(val.getMonth() + 12);
        //   if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
        //     setToDate(new Date(localStorage.getItem('processingDate')));
        //   }
        //   else {
        //     setToDate(toDate);
        //     if(toDate>newToDate)    //Added
        //     setToDate(newToDate);
        //   }
        }
    
      }
      function setTDate(val) {
        
        if (val == null) {
          setToDate(toDate);
        //   var newStartDate = new Date(toDate);
        //   newStartDate.setMonth(toDate.getMonth() - 12);
        //   setfrmDate(newStartDate);
    
        }
        else {
    
          setToDate(val);
        //   var newStartDate = new Date(val);
        //   newStartDate.setMonth(val.getMonth() - 12);
        //   setfrmDate(frmDate);
        //   if(frmDate<newStartDate)      //Added
        //   setfrmDate(newStartDate);
        }
      }
    if (loading) {
        return (
            <>
                {session === "Session Expired" ?

                    <Modal show={true} fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{ outlineColor: "black", borderBlockColor: "black", backgroundColor: "#0099ff", width: "50px" }} onClick={signOut}>OK</a></div></Modal.Body></Modal>
                    :

                    <Loading />
                }</>
        )
    }

    return (
        <div className='pt-1'>
            {/* <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
          value={frmDate}
          format="MM/dd/yyyy"
          calendar={CustomCalendar}
           min={minDt}
           max={maxDt}
         valid={frmDate<=toDate && frmDate<=maxDt &&frmDate>=minDt}
          onChange={(e) => {
            setDate(e.value);

          }}

        />

      </div>

      <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
          //defaultValue={toDate}
          value={toDate}
          format="MM/dd/yyyy"
          calendar={CustomCalendar}
          min={minDt}
           max={maxDt}
           valid={frmDate<=toDate && toDate<=maxDt &&toDate>=minDt}
          disabled={false}
          onChange={(e) => {
            setTDate(e.value);

          }}
        />

      </div> */}
            <>

                <table className='table tablel-bordered border border-bottom  bordeer-gray my-0 py-0'>
                    <tbody>
                        <tr>
                            <td className='align-bottom'>
                                <span className='py-1' style={{color:themes==='dark'?"white":"#454545"}}>From: </span>
                                <DatePicker
                                    format="MM/dd/yyyy"
                                   // calendar={CustomCalendar}
                                    placeholder=""



                                    className='form-control form-control-sm'
                                    size={'small'}
                                    value={frmDate}
                                    width={150}
                                    formatPlaceholder={{
                                        year: "yyyy",
                                        month: "mm",
                                        day: "dd",
                                    }}
                                    
                                    
                                    onChange={(e) => {
                                        setDate(e.value);

                                    }}

                                />
                            </td>
                            <td className='align-bottom'>
                                <span className='py-1' style={{color:themes==='dark'?"white":"#454545"}}>To: </span>
                                <DatePicker

                                    format="MM/dd/yyyy"
                                   // calendar={CustomCalendar}
                                    width={150}
                                    placeholder=""


                                    size={'small'}
                                    className='form-control form-control-sm'
                                    value={toDate}

                                    formatPlaceholder={{
                                        year: "yyyy",
                                        month: "mm",
                                        day: "dd",
                                    }}

                                    
                                    disabled={false}
                                    onChange={(e) => {
                                        setTDate(e.value);

                                    }}

                                />
                            </td>

                            <td className='align-bottom'>

                                <input type="button" onClick={GetTmpltList} className='btn btn-primary btn-sm' value="submit"></input>

                            </td>

                            <td className='align-center'><button className='btn btn-outline-secondary mx-2 btn-sm' onClick={handleExcelExport}><FaFileExcel></FaFileExcel> export</button>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </>

            {flag === 1 ?
                <LogDataGrid data={logData} triggerExport={triggerExport}/>
                :
                <></>
            }
        </div>
    )
}
export default LogData