import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import validator from 'validator';
import Select, { SelectChangeEvent } from '@mui/material/Select';
//import SelectControl from './selectcontrol';
import Loading from './loading';
import { DatePicker } from "@progress/kendo-react-dateinputs";
//import Header from './header';
import { lastDayOfMonth,isEqual } from '@progress/kendo-date-math';
import DailyPerformenceGrid from './dailyPerformanceGrid';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Button, Card } from '@mui/material';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from 'react-bootstrap/Modal';
import { FcExpired } from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from 'react-bootstrap';
import Enumerable from 'linq';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { FaInfoCircle } from 'react-icons/fa';
import { Popover } from "@progress/kendo-react-tooltip";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const DailyPerformanceRpt = (acctId) => {
  const [TimeWtdRtnRptRptData, populateTimeWtdRtnRptRptData] = useState([]);
  const [TimeWtdRtnRptRptDataChart, populateTimeWtdRtnRptRptDataChart] = useState([]);
  const [IncepDt,setIncepDt]=useState();
  //const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
  const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem('processingDate'));
  date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  //const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [asOfDate, setAsOfDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(true);
const [isChckedBM,setisChckedBM]=useState(false);
const [openDuration, setOpenDuration] = React.useState(100);
  const [closeDuration, setCloseDuration] = React.useState(300);
  // const minFrmDt = new Date(2021, 8, 14);
  
   const maxFrmDt = new Date(localStorage.getItem('processingDate'));
   const minFrmDt=new Date(localStorage.getItem('processingDate'));
   minFrmDt.setMonth(minFrmDt.getMonth()-60);
  const [isDisabled, setisDisabled] = React.useState(false);
  
  const [monthlyOrDaily, setMonthlyOrDaily] = React.useState(acctId.acctId==0 && JSON.parse(localStorage.getItem('pConsId'))>0?"Monthly":"Daily");
  const [isDisabledDaily, setisDisabledDaily] = React.useState(acctId.acctId==0 && JSON.parse(localStorage.getItem('pConsId'))>0?true:false);
  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [session, setSession] = useState("");
  //const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();
 
  const [dateType, setDateType] = React.useState(1);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
        GetTimeWtdRtnRpteData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, []);
  
const handelChkBM=(e)=>{
 
    setisChckedBM(e.target.checked);
 
}

const handleChangemonthlyOrDaily=(e)=>{
  
  setMonthlyOrDaily(e.target.value);
}
  const handelSubmit = (event) => {

   
  //  var date = new Date(asOfDate);
  //  var month =date.getMonth();
  //  var year =date.getFullYear();
  //  var day=date.getDate();
   //var lDay= lastDayOfMonth(new Date(year,month,day));
   
    //let date = dtfrm.selAcctData;
    setfrmDate(asOfDate);
       if(validator.isDate(asOfDate)&&(asOfDate>=minFrmDt)&&asOfDate<=maxFrmDt){
        
        GetTimeWtdRtnRpteData();
       }
       
      
     
    
  }
  
  const formSubmit = (event) => {
    GetTimeWtdRtnRpteData();
  }
 

  const GetTimeWtdRtnRpteData = async () => {
    
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem('userId'));  //3689
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = acctId.acctId;
    let PageId = 1;
    let netFeeId = 1;
    
    
     var date = new Date(asOfDate);
   var month =date.getMonth();
   var year =date.getFullYear();
   var day=date.getDate();
   var lDay= lastDayOfMonth(new Date(year,month,day));
    var IsDaily=0;
    if(monthlyOrDaily=="Daily")
    IsDaily=0;
  

    
    //var lDay=lastDayOfMonth(asOfDate);
    if(!isEqual(asOfDate,lDay) && IsDaily==0)
    {
      lDay= lastDayOfMonth(new Date(year,month-1,day));

      setAsOfDate(lDay);

    }
    else
    {
      lDay=asOfDate;
      //setAsOfDate(lDay);

    }
    


    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    let AsofDate=lDay;
    let ConsolidationId = JSON.parse(localStorage.getItem('pConsId'));
    AcctId = JSON.parse(localStorage.getItem('SelAcctId'));
    let ShowBenchmark =0;  
    let RltnshpCd =localStorage.getItem("pRltncd")==null||localStorage.getItem("pRltncd")==undefined?"":localStorage.getItem("pRltncd"); 
    if(isChckedBM) 
      ShowBenchmark=1;
    const postData = { UserId, AcctId, AsofDate, ConsolidationId,ShowBenchmark,IsDaily,RltnshpCd};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTSelectedPeriodPerformance',
      postData,
      config
    )
      .then(response => {

    
        
        const rowData = response.data;
       let gridData= Enumerable.from(rowData.lstSelectedPeriodT1).where(w => w.market > 0).toArray();
       
       let chartData= Enumerable.from(rowData.lstSelectedPeriodT2).where(w => w.market > 0).toArray();
      
        populateTimeWtdRtnRptRptData(rowData.lstSelectedPeriodT1);
        populateTimeWtdRtnRptRptDataChart(rowData.lstSelectedPeriodT2);
        let cdt = new Date(rowData.perfIncptnDt);
        let siDate=(cdt.getMonth() + 1).toString().padStart(2, '0')+"/"+cdt.getDate().toString().padStart(2, '0')+"/"+cdt.getFullYear();
        setIncepDt(siDate);
        console.log("Inception DATE:",siDate);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        // else {
        //   SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        // }
        getColumnStateDb();
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshToken();

        }
        // return error;
      });

  }

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();

      })
      .catch((error) => {
        // 
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }

  const getColumnStateDb = async () => {
    //Storing column settings in DB
    
    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTGetGridColumn/Index',
      postData,
      config
    )
      .then(response => {
        
        console.log('save in db');
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem('gridColumns', rowData);
        }
        else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log('error in save db ' + error);
        return error;
      });
  }

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }

  };

  const signOut = () => {

    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
      postData,
      config
    )
      .then((response) => {
        // 
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");

        // if (response.statusText === '') {

        // }

      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem('token');
    localStorage.clear();
    //firebaseApp.auth.signOut();

  }

  if (loading) {
    return (
      <>
        {session === "Session Expired" ?

          <Modal show={true} fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{ outlineColor: "black", borderBlockColor: "black", backgroundColor: "#0099ff", width: "50px" }} onClick={signOut}>OK</a></div></Modal.Body></Modal>
          :
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>}</>
    )
  }
  
  
  return (

     



    <div>
      
     <div className='container-fluid '>
            <form>  
                  
                  
               


                  <div className='row '>

          
<Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
<div className='kpicard'>
  <div className='kpiheader' style={{alignContent:'center',justifyContent:'center'}}><i className='me-2'><BsFillInfoCircleFill size={15} color='#0fa5fc' title="Performance Market Value may differ from the Total Market Value due to certain asset exclusions from the Performance Market Value (i.e. real estate assets) as well as with Performance Market Value using trade date value vs settlement date used for Total Market Value."></BsFillInfoCircleFill></i>
  Performance Market Value</div>
  <hr className='mt-1 mb-0 pb-0'></hr>

  <div className='kpibody'>
  ${TimeWtdRtnRptRptDataChart.length == 0 ? '0' : formatNumber(abs(TimeWtdRtnRptRptDataChart[0].market), "##,#.00")}
  </div>
</div>
</Card>
<Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
<div className='kpicard'>
  <div className='kpiheader' style={{alignContent:'center',justifyContent:'center'}}>Account YTD %</div>
  <hr className='mt-1 mb-0 pb-0'></hr>

  <div className='kpibody'>
  {TimeWtdRtnRptRptDataChart.length == 0 ? '0' : formatNumber(abs(TimeWtdRtnRptRptDataChart[0].retYTD), "##,#.00")}%
  </div>
</div>
</Card>

</div>





                <div className='row mt-2 d-flex justify-content-end align-items-center'>
                  <div className='col'>
                  <span className='py-1'>As Of Date </span>
      {/* <label className='form-check-label px-2'>As Of Date</label> */}
      <DatePicker id="dpFrm"
        value={asOfDate}
        className='form-control form-control-sm'
                size={'small'}
                width={150}
        format="MM/dd/yyyy"
       // calendar={CustomCalendar}
        min={minFrmDt}
        max={maxFrmDt}
        disabled={isDisabled}
        onChange={(e) => {
          setAsOfDate(e.value);

        }}

      />
      </div>
      <div className='col'>
                  
                  <input type="button" className='btn btn-primary btn-sm mx-3' onClick={handelSubmit}   value="submit"/>



                  </div>

              


              
   

  </div>

  <div className='row'>
  {flag === 1 ?
                  <DailyPerformenceGrid data={TimeWtdRtnRptRptData} flag={isColumnSave} alloc={TimeWtdRtnRptRptDataChart} InceptnDt={IncepDt}/> 
                  : <></>
                }

    </div>
          
               

             
            </form>
          </div>
       
      </div>

   
  )
}

export default DailyPerformanceRpt


