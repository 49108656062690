import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { BsInfoCircleFill } from "react-icons/bs";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import '@progress/kendo-theme-default/dist/all.css';
// import Tab from '@mui/material/Tab/TabList';
// import TabContext from '@mui/material/Tabs';
import { BiSolidMessageError } from "react-icons/bi";
// import TabPanel from '@mui/lab/TabPanel';
import { Circles, Oval, BallTriangle, RotatingLines, RevolvingDot } from 'react-loader-spinner';
// import Drawer from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import { Header } from 'antd/es/layout/layout';
import { Layout } from 'antd/es';
import { RadioButton } from "@progress/kendo-react-inputs";
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { IoMenu } from "react-icons/io5";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CalendarMonthOutlined, MenuOpen } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { FaUsers, FaCogs, FaSignOutAlt, FaRedoAlt } from "react-icons/fa";
import { RiFileUserFill } from "react-icons/ri";
import Person2Icon from '@mui/icons-material/Person2';
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from './sidebar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Form } from 'react-bootstrap';

import Tooltip from '@mui/material/Tooltip';
import Select from "@mui/material/Select";
import { NestedMenuItem } from 'mui-nested-menu';
import {
    ListView,
    ListViewHeader,
    ListViewFooter,
} from "@progress/kendo-react-listview";
import {
    Filter,
    Operators,
    TextFilter,
    NumericFilter,
    BooleanFilter,
} from "@progress/kendo-react-data-tools";
import { DropDownList, ComboBox } from "@progress/kendo-react-dropdowns";
import { groupBy, filterBy } from "@progress/kendo-data-query";
//import { Dropdown } from 'antd';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import AccountCircle from '@mui/icons-material/AccountCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { useLocation, useNavigate } from 'react-router-dom';
//import { ResponsiveContainer } from 'recharts';
import AdvisorLogo from './advisorLogo';
import { selectTheme, toggleTheme } from '../features/theme/themeSlice';
import DashboardContent from './dashboardContent';
import Enumerable from 'linq';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AiOutlineSetting, AiOutlineCloseCircle } from 'react-icons/ai';
import { GrPowerReset } from 'react-icons/gr';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsFillGrid1X2Fill, BsFillGridFill } from 'react-icons/bs';
import { FaLink } from 'react-icons/fa';
import { start } from '@progress/kendo-react-dateinputs';
import LogData from './logdataRpt';
import { log } from 'mathjs';
import { Modal } from 'antd';
import ErrorLogData from './errorlogdataRpt';
import Radio from '@mui/material/Radio';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { selectdarkstyle, setdarkstyle } from '../features/darktheme/darkthemeSlice';
var consolidationtype = [];
var roleList = [];
var allowedroleList = [];
var consTypeList = [];
const drawerWidth = 350;
var dashboardData;


var GWESLink = process.env.REACT_APP_GWES_LINK;
var CIALink = process.env.REACT_APP_CIA_LINK;
var AOBLink = process.env.REACT_APP_ACCOB_LINK;
var isRelCode = process.env.REACT_APP_RELATIONSHIP_CODE;
var showUserList = process.env.REACT_APP_SHOW_USERLIST;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeftNew() {
    const [themeloading, setthemeloading] = useState(false);
    const viewDataSource = [
        "Show All",
        // "By Relationship",
        // "By Account"
    ];
    const themes = useSelector(selectTheme);

    const radios = [
        { name: 'Reset', value: '0' },
        // { name: 'By Relationship', value: '2' },
        // { name: 'By Account', value: '1' },
    ];
    const renderTabBar = (props, DefaultTabBar) => (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 999, top: 40, padding: 0, height: "38px", lineHeight: "20px", background: '#f6f7f9' }}>
                <DefaultTabBar {...props} style={{
                    top: 17,
                }} />
            </Header>
        </Layout>
    );

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('1');
    const dispatch = useDispatch();
    const allthemes = useSelector(selectTheme);
    const getdarkstyle = useSelector(selectdarkstyle);
    // const [initialCall, setInitialCall] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selView, setSelView] = React.useState(viewDataSource[0]);
    const [reltnToggle, setReltnToggle] = React.useState(false);
    const [reltnCdToggle, setReltnCdToggle] = React.useState(false);
    const [selRelatiosship, setSelRelationship] = React.useState([]);
    const [relationDataBasic, setRelationDataBasic] = React.useState([]);
    const [relationData, setRelationData] = React.useState([]);
    const [relationCdData, setRelationCdData] = React.useState([]);
    const [acctDataConsAll, setAcctDataConsAll] = React.useState([]);
    const [acctDataConsAllBasic, setAcctDataConsAllBasic] = React.useState([]);


    if (localStorage.getItem("rltnshpCd") === null || localStorage.getItem("rltnshpCd") === undefined) {
        localStorage.setItem("rltnshpCd", "");
    }
    const [lightthemekendo, setlightthemekendo] = React.useState();
    const [darkthemekendo, setdarkthemekendo] = React.useState();

    const [rltnCdDataBasic, setRltnCdDataBasic] = React.useState([]);
    const [radioValue, setRadioValue] = useState('0');
    const [logGrid, setLogGrid] = useState(false);
    const [allowedroledisp, setAllowedroledisp] = useState(false);
    const [logErrorGrid, setLogErrorGrid] = useState(false);
    const [userRole, setUserRole] = React.useState([]);
    const [userRoleVal, setUserRoleVal] = React.useState([]);
    const [consType, setconsType] = React.useState([]);
    const [user, setUser] = React.useState([]);
    const [consTypeVal, setconsTypeVal] = React.useState([]);
    const [roleTypeVal, setroleTypeVal] = React.useState([]);
    const [selCons, setSelCons] = useState([]);
    const [selUser, setSelUser] = useState([]);
    const [selAccount, setSelAccount] = React.useState("");
    const [selRltncode, setSelRltncode] = React.useState("");
    const [loadingView, setLoadingView] = React.useState(true);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = React.useState(0);
    const [consId, setConsId] = React.useState(0);
    const [rltnCdId, setrltnCdId] = React.useState("");
    const [acctId, setAcctId] = React.useState(0);
    const [selectedRole, setselectedRole] = React.useState([]);
    const [selRole, setSelRole] = React.useState();
    const [selectedConsldtn, setselectedConsldtn] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [valueRel, SetValueRel] = React.useState("Relationship");
    const [disableSidebar, SetDisableSidebar] = React.useState(false);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const navigate = useNavigate();
    const handleChange = (e) => {
        setValue(e.target.value);
    };
    const Title = ({ children }) => <div className="title">{children}</div>;
    let closeImg = { cursor: 'pointer', float: 'right', marginTop: '5px', width: '20px' };
    const handleMenu = (event) => {

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const SubmitSetting = () => {
        setSelRltncode("");
        localStorage.setItem('pRltncd', "");
        localStorage.setItem('pRltncdName', "");
        setAcctId(0);
        setrltnCdId("");

        localStorage.setItem('pConsName', "");

        localStorage.setItem("pConsId", 0);
        setConsId(0);
        localStorage.setItem('SelAcctId', 0);
        localStorage.setItem("SelAcctNm", "");
        setOpenDialog(false);
        setSelAccount([]);
        setSelRelationship([]);

        ConsTypeDBSet(localStorage.getItem("SelConsTypeVal"), localStorage.getItem("UserRoleData"));

    }
    const togglelog = () => {
        setLogGrid(!logGrid);

        if (logGrid) {
            document.body.style.overflow = 'auto';

        }
        else {
            document.body.style.overflow = 'hidden';
            handleDrawerClose();

        }
    }
    const toggleErrorlog = () => {
        setLogErrorGrid(!logErrorGrid);
        if (logGrid) {
            document.body.style.overflow = 'auto';
        }
        else {
            document.body.style.overflow = 'hidden';
            handleDrawerClose();

        }
    }
    const handleChangeUserRole = (e) => {

        setSelRole(e.target.value);
        setUserRoleVal(e.target.value);
        // var role = Enumerable.from(roleList).where(w => w.roleTypId === e.target.value)
        //     .toArray();
        // setselectedRole("Role: " + role[0].roleTypDesc);
        localStorage.setItem("UserRoleData", e.target.value.roleTypId);
        // GetConsViewData(0, 0, 0);
        // setSelView(viewDataSource[0]);
        // setSelAccount([]);
        // setSelRelationship([]);
        // setAcctId(0);
    };
    const handleChangeConsType = (e) => {
        // setSelRltncode("");
        // localStorage.setItem('pRltncd', "");
        // localStorage.setItem('pRltncdName', "");

        // setrltnCdId("");
        setSelCons(e.target.value);
        // setconsTypeVal(e.target.value);
        // var cnsldtn = Enumerable.from(consType).where(w => w.cnsldtnTypId === e.target.value)
        //     .toArray();
        // // setselectedConsldtn(cnsldtn[0].cnsldtnTypDesc);
        localStorage.setItem("SelConsTypeVal", e.target.value.cnsldtnTypId);
        // ConsTypeDBSet(e.target.value);
        // localStorage.setItem('pConsName', "");
        // localStorage.setItem("pConsId", 0);
        // setConsId(0);
        // localStorage.setItem('SelAcctId', 0);
        // localStorage.setItem("SelAcctNm", "");
        // setSelView(viewDataSource[0]);
        // setSelAccount([]);
        // setSelRelationship([]);
        // setAcctId(0);
        //GetConsViewData(0, 0, 0);
        //window.location.reload(false);
    };
    const handleChangeUser = (e) => {

        setSelUser(e.target.value);
        localStorage.setItem("userId", JSON.stringify(e.target.value.userId));

        console.log("USERID:", e.target.value.userId)
        //handleRadioChange();
        setAcctDataConsAllBasic([]);
        setAcctDataConsAll([]);
        setSelRelationship([]);
        setSelAccount([]);
        setSelRltncode([]);
        setrltnCdId("");


        setAcctId(0);
        //setRadioValue(e.currentTarget.value);
        setRadioValue("0")
        //setSelView(event.value);
        // setLoadingView(true);
        var mode = "All";
        // if (e.currentTarget.value === "1")
        //     mode = "Account";
        setMode(mode);
        setConsId(0);
        localStorage.setItem("SelAcctId", 0);

        localStorage.setItem("pConsId", 0);
        localStorage.setItem("pRltncd", "");

        localStorage.setItem('pConsName', "");
        localStorage.setItem('pActiveLTab', "0");
        localStorage.setItem('pRltncd', "");
        localStorage.setItem("activeTab", "1");
        GetUserRole();
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const theme = useTheme();

    const handleCloseCombo = (e) => {

    }
    //const [open, setOpen] = React.useState(false);

    const handleLogout = () => {
        if (allthemes === 'dark') {
            toggle();
        }
        navigate("/");

        let token = JSON.parse(localStorage.getItem('token'));
        if (token == undefined || token == null) {

        }

        else {
            const postData = {};
            const config = {
                headers: {
                    'authorization': `Bearer ${token.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }

            };
            axios.post('/token/revoke',
                postData,
                config
            )
                .then((response) => {
                    localStorage.removeItem('token');
                    localStorage.clear();

                    // localStorage.removeItem('token');
                    // localStorage.clear();

                    // if (response.statusText === '') {

                    // }

                })
                .catch((error) => {
                    // 
                    console.log("my error is " + error);
                })

        }


        localStorage.clear();

    }

    const handleAdvancedOpen = () => {
        // handleDrawerClose();
        // setAnchorEl(null);
        // setOpen(false);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }





    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                GetUserRole();

                //GetConsType();
                //GetConsViewData(0,0,0);

            } catch (error) {
                console.error(error.message);
            }

        }
        fetchData();
    }, [])

    const handleChangeView = (event) => {

        setAcctDataConsAllBasic([]);
        setAcctDataConsAll([]);
        setSelRelationship([]);
        setSelAccount([]);
        setAcctId(0);
        if (event.value === null) {
            setSelView(selView);
        }
        else {
            setSelView(event.value);
            // setLoadingView(true);
            setMode(event.value);
            GetConsViewData(event.value, 0);
        }
    };

    const handleRadioChange = () => {

        setAcctDataConsAllBasic([]);
        setAcctDataConsAll([]);
        setSelRelationship([]);
        setSelAccount([]);
        setSelRltncode([]);
        setrltnCdId("");



        setAcctId(0);
        //setRadioValue(e.currentTarget.value);
        setRadioValue("0")
        //setSelView(event.value);
        // setLoadingView(true);
        var mode = "All";
        // if (e.currentTarget.value === "1")
        //     mode = "Account";
        setMode(mode);
        setConsId(0);
        localStorage.setItem("SelAcctId", 0);

        localStorage.setItem("pConsId", 0);
        localStorage.setItem("pRltncd", "");
        GetConsType();
        GetConsViewData(mode, 0, 0, "");
        localStorage.setItem('pConsName', "");
        localStorage.setItem('pActiveLTab', "0");
        localStorage.setItem('pRltncd', "");
        localStorage.setItem("activeTab", "1");
    }
    const handleChangeRltnCode = (event) => {


        if (event.target.value === null) {
            setSelRltncode([]);
            //localStorage.setItem('pRltncd',"");
            //GetConsViewData(mode, 0, 0, "");
            //setrltnCdId("");
            //localStorage.setItem('pRltncd', '');
            //localStorage.setItem('pRltncdName', "");


            // setAcctDataConsAll(acctDataConsAll);
        }
        else {

            setSelAccount([]);
            setAcctId(0);

            localStorage.setItem('pConsId', 0);
            localStorage.setItem("SelAcctId", 0);
            setSelRelationship([]);
            setSelRltncode(event.target.value);
            localStorage.setItem('pRltncd', event.target.value.rltnshpCd);
            localStorage.setItem('pRltncdName', event.target.value.rltnshpCd);
            GetConsViewData(mode, 0, 0, event.target.value.rltnshpCd);
            setrltnCdId(event.target.value.rltnshpCd);

            // setAcctDataConsAll(Enumerable.from(consAcct).where(w => w.cnsldtnId === event.target.value.cnsldtnId)
            //     .toArray())
        }
        // if(localStorage.getItem("activeTab")==="6"||localStorage.getItem("activeTab")==="7"){
        //     localStorage.setItem("activeTab","1");
        // }
    };
    const handleChangeRelation = (event) => {

        // setSelAccount([]);
        // setAcctId(0);
        // setSelRltncode([]);
        localStorage.setItem('pRltncd', "");
        localStorage.setItem("SelAcctId", 0);

        if (event.target.value === null) {
            setSelRelationship([]);

            // GetConsViewData(mode, 0, 0, "");
            // setConsId(0);
            // localStorage.setItem('pConsId', 0);
            // localStorage.setItem('pConsName', "");



        }
        else {


            setSelAccount([]);
            setAcctId(0);
            setSelRltncode([]);
            setSelRelationship(event.target.value);

            localStorage.setItem('pConsId', event.target.value.cnsldtnId);
            localStorage.setItem('pConsName', event.target.value.cnsldtnNm);

            GetConsViewData(mode, event.target.value.cnsldtnId, 0, "");
            setConsId(event.target.value.cnsldtnId);

            // setAcctDataConsAll(Enumerable.from(consAcct).where(w => w.cnsldtnId === event.target.value.cnsldtnId)
            //     .toArray())
        }
        // if(localStorage.getItem("activeTab")==="6"||localStorage.getItem("activeTab")==="7"){
        //     localStorage.setItem("activeTab","1");
        // }
    };

    const handleChangeAccount = (event) => {



        if (!loadingView) {
            if (event.target.value === null) {


                setSelAccount([]);

                if (localStorage.getItem('pConsId') !== "0" || (localStorage.getItem('pRltncd') !== "" && localStorage.getItem('pRltncd') !== undefined && localStorage.getItem('pRltncd') !== null)) {
                    setAcctId(0);
                    localStorage.setItem('SelAcctId', 0);
                    GetConsViewData(mode, consId, 0, selRltncode.rltnshpCd === undefined ? "" : selRltncode.rltnshpCd);

                }



                console.log(selRelatiosship);
                // localStorage.setItem("SelAcctNm", "");
                //
            }
            else {


                localStorage.setItem('SelAcctId', event.target.value.acctId);
                localStorage.setItem("SelAcctNm", event.target.value.extrnlAcctId);
                setSelAccount(event.target.value);
                setAcctId(event.target.value.acctId);
                localStorage.setItem('SelAcctId', event.target.value.acctId);
                GetConsViewData(mode, consId, event.target.value.acctId, selRltncode.rltnshpCd === undefined ? "" : selRltncode.rltnshpCd);
            }
        }

    }

    const handleCloseDialog = () => {

        setSelCons(consolidationtype);
        setOpenDialog(false);

    }

    const onFilterChangeRelation = (event) => {

        setRelationData(filterBy(relationDataBasic, event.filter));

    };
    const onFilterChangeRelationCd = (event) => {

        setRelationCdData(filterBy(rltnCdDataBasic, event.filter));

    };

    const handleClickSettings = (e) => {
        //  
        handleDrawerClose();
        setAnchorEl(null);
        setOpenDialog(true);
    }

    const handleChangeRadioRel = (e) => {

        // 
        setSelAccount([]);
        setAcctId(0);
        setSelRltncode([]);
        setSelRelationship([]);
        setrltnCdId("");
        localStorage.setItem('pRltncd', "");
        localStorage.setItem("SelAcctId", 0);

        localStorage.setItem('pConsId', 0);
        SetValueRel(e.target.value);
        localStorage.setItem('RelationRadioVal', e.target.value);
        handleRadioChange();
    }

    const onFilterChangeAcctConsAll = (event) => {
        setAcctDataConsAll(filterBy(acctDataConsAllBasic, event.filter));

    };


    const handleMeuState = (disableState) => {
        if (disableState) {
            setOpen(false);
        }
        SetDisableSidebar(disableState)

    }
    const GetUserRole = async () => {
        //
        setLoading(true);

        let token = tempToken;

        let UserId = localStorage.getItem('userId');
        const postData = { UserId };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/GetRoles',
            postData,
            config
        )
            .then(response => {

                const rowData = response.data;
                // commented on 8th march 2024  localStorage.setItem("UserRoleData", JSON.stringify(rowData.oC_Roles[0].roleTypId));
                setUserRole(response.data.oC_Roles);
                roleList = response.data.oC_Roles;
                allowedroleList = false ? response.data.oC_Roles : response.data.oC_AllowedRoles;

                setUser(response.data.oC_AllUserModel);
                let user = Enumerable.from(response.data.oC_AllUserModel).where(w => w.userId === JSON.parse(localStorage.getItem('userId')))
                    .toArray();
                setSelUser(user[0]);
                // var role=Enumerable.from(response.data).where(w => w.roleTypId === rowData[0].roleTypId)
                //  .toArray();
                // setselectedRole("Role: "+role[0].roleTypDesc);

                setSelRole(roleList[0]);
                //setUserRoleVal(response.data[0].roleTypId);
                // commented on 8th march 2024 GetConsType();

                //added on 8th march 2024 start
                let token = tempToken;
                let UserId = JSON.parse(localStorage.getItem('userId'));
                const postData = { UserId };
                const config = {
                    headers: {
                        'authorization': `Bearer ${token.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }

                };
                axios.post('/AdvGetConsType',
                    postData,
                    config
                )
                    .then(response => {

                        const rowData = response.data.oC_ConsTypMdl;
                        setconsType(rowData);
                        consTypeList = rowData;
                        //setconsTypeVal(rowData[0].cnsldtnTypId);

                        setconsTypeVal(response.data.selConsTypeId);
                        setroleTypeVal(response.data.selRoleTypeId);
                        var roletypval = Enumerable.from(roleList).where(w => w.roleTypId === response.data.selRoleTypeId)
                            .toArray();
                        if (roletypval.length != 0) {
                            setSelRole(roletypval[0]);
                            localStorage.setItem("UserRoleData", JSON.stringify(roletypval[0].roleTypId));
                        }
                        else {
                            localStorage.setItem("UserRoleData", JSON.stringify(roleList[0].roleTypId));
                        }

                        var initialConstype = {
                            cnsldtnTypId: response.data.selConsTypeId,
                            cnsldtnTypDesc: consTypeList.length > 0 ? (consTypeList.find((ele) => { return ele.cnsldtnTypId === response.data.selConsTypeId })) : ""
                        };

                        var cnsldtn = Enumerable.from(consTypeList).where(w => w.cnsldtnTypId === response.data.selConsTypeId)
                            .toArray();
                        setSelCons(cnsldtn[0]);
                        localStorage.setItem("SelConsTypeVal", cnsldtn[0].cnsldtnTypId);
                        consolidationtype = cnsldtn[0];
                        GetConsViewData(0, 0, 0, "");
                        setLoading(false);
                        //setselectedConsldtn(initialConstype);


                    })
                    .catch((error) => {

                        if (error.response.status === 401) {
                            refreshTokenUserConsl();

                        }
                        return error;
                    });


                //added on 8th march 2024 end


            })
            .catch((error) => {

                if (error.response.status === 401) {
                    refreshTokenUserConsl();

                }
                return error;
            });

    }
    const GetConsType = async () => {

        setLoading(true);
        let token = tempToken;
        let UserId = JSON.parse(localStorage.getItem('userId'));
        const postData = { UserId };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/AdvGetConsType',
            postData,
            config
        )
            .then(response => {

                const rowData = response.data.oC_ConsTypMdl;
                setconsType(rowData);
                consTypeList = rowData;
                //setconsTypeVal(rowData[0].cnsldtnTypId);

                setconsTypeVal(response.data.selConsTypeId);
                setroleTypeVal(response.data.selRoleTypeId);
                var roletypval = Enumerable.from(roleList).where(w => w.roleTypId === response.data.selRoleTypeId)
                    .toArray();
                if (roletypval.length != 0) {
                    setSelRole(roletypval[0]);
                    localStorage.setItem("UserRoleData", JSON.stringify(roletypval[0].roleTypId));
                }
                else {
                    localStorage.setItem("UserRoleData", JSON.stringify(roleList[0].roleTypId));
                }

                var initialConstype = {
                    cnsldtnTypId: response.data.selConsTypeId,
                    cnsldtnTypDesc: consTypeList.length > 0 ? (consTypeList.find((ele) => { return ele.cnsldtnTypId === response.data.selConsTypeId })) : ""
                };

                var cnsldtn = Enumerable.from(consTypeList).where(w => w.cnsldtnTypId === response.data.selConsTypeId)
                    .toArray();
                setSelCons(cnsldtn[0]);
                localStorage.setItem("SelConsTypeVal", cnsldtn[0].cnsldtnTypId);
                consolidationtype = cnsldtn[0];

                //setselectedConsldtn(initialConstype);
                setLoading(false);

            })
            .catch((error) => {

                if (error.response.status === 401) {
                    refreshTokenUserConsl();

                }
                return error;
            });

    }

    const ConsTypeDBSet = async (pVal, pRoleId) => {

        setLoadingView(true);
        let token = tempToken;
        let UserId = JSON.parse(localStorage.getItem('userId'));
        let ConsolidationTypId = pVal;//JSON.parse(localStorage.getItem("SelConsTypeVal"));
        let RoleTypId = pRoleId;
        const postData = { UserId, ConsolidationTypId, RoleTypId };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/SetConsolType',
            postData,
            config
        )
            .then(response => {


                const rowData = response.data;
                setLoadingView(false);
                GetConsType();
                GetConsViewData(0, 0, 0, "");

            })
            .catch((error) => {

                if (error.response.status === 401) {
                    refreshTokenConsTypDb();

                }
                return error;
            });

    }
    const GetConsViewData = async (pMode, pConsId, pAcctId, pRltncode) => {

        localStorage.removeItem('DisbData');
        localStorage.removeItem('DipositData');
        localStorage.removeItem('TransferData');
        localStorage.removeItem('MemoData');
        localStorage.removeItem('filter');
        localStorage.removeItem('TransactDataState');
        localStorage.removeItem('HoldingDataState');
        localStorage.removeItem('DepositDataState');
        localStorage.removeItem('DisbursementDataState');
        localStorage.removeItem('TransferDataState');
        localStorage.removeItem('MemoDataState');
        localStorage.removeItem('disbStatecolumns');
        // localStorage.setItem("rltnshpCd",pRltncode);
        let token = tempToken;

        let UserId = JSON.parse(localStorage.getItem('userId'));// 78034;
        console.log("userid", UserId)
        let RoleTypeId = localStorage.getItem('UserRoleData');//2;
        let Mode;
        let CnsldtnId = pConsId;
        let AcctId = pAcctId;
        let RltnshpCd = pRltncode
        // localStorage.setItem('SelAcctId', AcctId);
        if (pMode == 'Account')
            Mode = 1;
        else
            Mode = 0;

        const postData = { UserId, RoleTypeId, CnsldtnId, AcctId, Mode, RltnshpCd };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        setLoadingView(true);
        await axios.post('/GetAdvConsViewDef',
            postData,
            config
        )
            .then(response => {
                setLoadingView(false);


                const rowData = response.data;


                setRelationData(rowData.lstCons);
                setRelationDataBasic(rowData.lstCons);

                setAcctDataConsAll(rowData.lstAcctAll);
                setAcctDataConsAllBasic(rowData.lstAcctAll);
                setRelationCdData(rowData.lstRltnshpCd);
                setRltnCdDataBasic(rowData.lstRltnshpCd);

                dashboardData = rowData;
                let procDt = new Date(rowData.procDate);
                let pdt = (procDt.getMonth() + 1).toString().padStart(2, '0') + "/" + (procDt.getDate().toString().padStart(2, '0')) + "/" + (procDt.getFullYear());
                localStorage.setItem('processingDate', pdt);
                //setLoading(false);

            })
            .catch((error) => {

                console.log(error);
                if (error.response.status === 401) {
                    refreshToken(pMode, pConsId, pAcctId, pRltncode);

                }

                return error;
            });
    }

    const refreshToken = async (pMode, pConsId, pAcctId, pRltncode) => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {

                var Mode;
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));
                if (pMode == 'Account')
                    Mode = 1;
                else
                    Mode = 0;

                GetConsViewData(Mode, pConsId, pAcctId, pRltncode);

            })
            .catch((error) => {
                // 
                if (error.code === "ERR_BAD_REQUEST") {
                    //  setSession("Session Expired");
                }
                console.log("my error is " + error);
            })

    }
    const refreshTokenConsTypDb = async (pMode, pConsId, pAcctId) => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {

                var Mode;
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));
                if (pMode == 'Account')
                    Mode = 1;
                else
                    Mode = 0;

                ConsTypeDBSet(JSON.parse(localStorage.getItem("SelConsTypeVal")), JSON.parse(localStorage.getItem("UserRoleData")));

            })
            .catch((error) => {
                // 
                if (error.code === "ERR_BAD_REQUEST") {
                    //  setSession("Session Expired");
                }
                console.log("my error is " + error);
            })

    }
    const refreshTokenUserConsl = async (pMode, pConsId, pAcctId) => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {

                var Mode;
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));
                if (pMode == 'Account')
                    Mode = 1;
                else
                    Mode = 0;
                GetUserRole();
                //GetConsType();
                //GetConsViewData(Mode, pConsId, pAcctId);

            })
            .catch((error) => {
                // 
                if (error.code === "ERR_BAD_REQUEST") {
                    //  setSession("Session Expired");
                }
                console.log("my error is " + error);
            })

    }
    const itemRender = (li, itemProps) => {
        const item = (
            <i
                style={{
                    color: "green",
                    //   fontWeight: 900,
                    //   width: "100%",
                    fontSize: "1.5em",
                    textAlign: "center",
                }}
            >
                {li.props.children} {itemProps.index}

            </i>
        );
        return React.cloneElement(li, li.props, item);
    };

    const handleResetFilter = () => {
        GetConsViewData(0, 0, 0);
        // setSelView(viewDataSource[0]);
        setSelAccount([]);
        setSelRelationship([]);
        setAcctId(0);
        setConsId(0);

    }
    // if(loadingView){
    //     return(
    //         <Loading></Loading>
    //     )
    // }
    const handleCIAClick = () => {

        const objectForSending = { "loginName": "testDemoAdmin", "loginPassword": "Welcome!24", "hiddenbankabvr": "DEMO" };

        const form = document.createElement('form');
        form.method = 'POST';
        form.target = "_blank";
        form.action = CIALink;

        // Sample 1
        const hiddenFieldSample1 = document.createElement('input');
        hiddenFieldSample1.type = 'hidden';
        hiddenFieldSample1.name = 'loginName';
        hiddenFieldSample1.value = objectForSending.loginName;
        form.appendChild(hiddenFieldSample1);

        // Sample 2
        const hiddenFieldSample2 = document.createElement('input');
        hiddenFieldSample2.type = 'hidden';
        hiddenFieldSample2.name = 'loginPassword';
        hiddenFieldSample2.value = objectForSending.loginPassword;
        form.appendChild(hiddenFieldSample2);


        const hiddenFieldSample3 = document.createElement('input');
        hiddenFieldSample3.type = 'hidden';
        hiddenFieldSample3.name = 'hiddenbankabvr';
        hiddenFieldSample3.value = objectForSending.hiddenbankabvr;
        form.appendChild(hiddenFieldSample3);

        document.body.appendChild(form);
        form.submit();



        // event.preventDefault();
        // 
        // fetch('https://beta.trustreporter.com/TRv5/mvcLogin.do?cmd=login', {
        //     method: 'post',
        //     headers: {'Content-Type':'application/json'},
        //     body: {
        //      "loginName": "testDemoAdmin",
        //      "loginPassword": "Welcome!23",
        //      "hiddenbankabvr": "DEMO",
        //     }
        //    })
        //    .then((response)=>response.json())
        //    .then((responseJson)=>{})
        //    .catch((error) => {
        //     
        //     console.error(error);
        //   });
    }
    const reltnOpen = () => {
        if (relationData.length > 2)
            setReltnToggle(true);
    }
    const reltnClose = () => {
        if (relationData.length > 2)
            setReltnToggle(false);
    }
    const reltncdOpen = () => {
        if (relationCdData.length > 2)
            setReltnCdToggle(true);
    }
    const reltncdClose = () => {
        if (relationData.length > 2)
            setReltnCdToggle(false);
    }
    // React.useMemo(() => {
    //     
    //             const fetchData = async () => {
    //                 setLoading(true);
    //                 try {
    //                     GetUserRole();

    //                     //GetConsType();
    //                     //GetConsViewData(0,0,0);

    //                 } catch (error) {
    //                     console.error(error.message);
    //                 }

    //             }
    //             fetchData();
    //         }, [allthemes])
    const toggle = (e) => {
        setthemeloading(true);
        setTimeout(() => {
            setthemeloading(false);
        }, 2);
        dispatch(toggleTheme());

        // SetDisableSidebar(false);

        //     if(allthemes === 'light'){
        //       
        //       for(var i=0;i<=document.getElementsByTagName("style").length;i++){
        //         if(document.getElementsByTagName("style")[i]!==undefined&&document.getElementsByTagName("style")[i].innerHTML!==undefined){
        //         if(document.getElementsByTagName("style")[i].innerHTML.includes("@progress")){
        //           var style=document.getElementsByTagName("style")[i];
        //           setlightthemekendo(document.getElementsByTagName("style")[i]);

        //           localStorage.setItem("lightstyle",document.getElementsByTagName("style")[i]);
        //           style.remove();
        //           break;
        //         }
        //       }

        //       }
        //       if(darkthemekendo){
        //         document.head.append(darkthemekendo);
        //       }
        //       else if(getdarkstyle!==""){
        //         document.head.append(getdarkstyle);
        //       }
        //       else{
        //         import('../Css/mmpdark/dist/css/mmpdark.css');


        //       }



        //     }


        //   else{
        //     
        //     for(var i=0;i<=document.getElementsByTagName("style").length;i++){
        //       if(document.getElementsByTagName("style")[i]!==undefined&&document.getElementsByTagName("style")[i].innerHTML!==undefined){
        //         if(document.getElementsByTagName("style")[i].innerHTML.includes("@progress")){
        //           var style=document.getElementsByTagName("style")[i];
        //           setdarkthemekendo(document.getElementsByTagName("style")[i]);
        //           dispatch(setdarkstyle(document.getElementsByTagName("style")[i]));
        //           style.remove();
        //           break;
        //         }
        //       }


        //     }

        //     document.head.append(lightthemekendo);
        //     //require('@progress/mmplight/dist/css/mmplight.css');
        //   }

    }
    return (
        <>



            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={logGrid}
                onClose={togglelog}>
                <DialogTitle id="alert-dialog-title">

                    <Title>{"User Log"}
                        {/* <span style={closeImg} onClick={handleCloseDialog}><AiOutlineCloseCircle></AiOutlineCloseCircle></span>  */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={togglelog}
                            style={closeImg}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Title>
                </DialogTitle>

                <DialogContent>


                    <LogData></LogData>


                </DialogContent>
                <DialogActions>

                    <div className='d-grid gap-2 d-sm-block'>


                        <button className='btn btn-outline-secondary  mx-2 px-2' onClick={togglelog}>Close</button>


                    </div>


                    {/* <Button className='btn btn-outline-primary' >Ok</Button>
                            <Button className='btn btn-outline-secondary' onClick={handleCloseDialog}>Cancel</Button> */}

                </DialogActions>

            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={logErrorGrid}
                onClose={toggleErrorlog}>
                <DialogTitle id="alert-dialog-title">

                    <Title>{"Error Log"}
                        {/* <span style={closeImg} onClick={handleCloseDialog}><AiOutlineCloseCircle></AiOutlineCloseCircle></span>  */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={toggleErrorlog}
                            style={closeImg}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Title>
                </DialogTitle>

                <DialogContent>


                    <ErrorLogData></ErrorLogData>


                </DialogContent>
                <DialogActions>

                    <div className='d-grid gap-2 d-sm-block'>


                        <button className='btn btn-outline-secondary  mx-2 px-2' onClick={toggleErrorlog}>Close</button>


                    </div>


                    {/* <Button className='btn btn-outline-primary' >Ok</Button>
                            <Button className='btn btn-outline-secondary' onClick={handleCloseDialog}>Cancel</Button> */}

                </DialogActions>

            </Dialog>
            {/* <Modal fullscreen={true}  show={logGrid} onHide={togglelog}>
                  <Modal.Header closeButton>
                    <Modal.Title> <div className="card-header tableheader"></div></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <br></br>
                  <button className="btn btn-primary  btn-sm" onClick={togglelog}>
                      Close
                    </button>
                    <LogData></LogData>

                  </Modal.Body>


                </Modal> */}
            <Box sx={{ display: 'flex' }}>

                <CssBaseline />
                <AppBar position="fixed" open={open}>

                    <Toolbar style={{ minHeight: '40px', height: '50px' }}>

                        <div className='' >
                            <IconButton

                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start" rltnCdId
                                disabled={disableSidebar}





                                sx={{ mr: 1, ...(open && { display: 'none' }) }}
                            >
                                <IoMenu size={40} />
                            </IconButton>


                        </div>








                        <div className='d-flex flex-grow-1'>
                            <AdvisorLogo></AdvisorLogo></div>







                        <div className='d-flex mx-2  align-self-center  ' style={{ color: allthemes === 'dark' ? '#4ad8ff' : '#004cff' }}>
                            {localStorage.getItem('pConsId') !== "0" && acctId === 0 ? <>Relationship: {localStorage.getItem('pConsName')}</> : acctId > 0 ? <>Account: {localStorage.getItem("SelAcctNm", "")}</> : rltnCdId !== "" ? <>Relationship Code:{localStorage.getItem("pRltncd")}</> : <></>}



                        </div>

                        <div className='d-flex mx-2  align-self-center '>
                            {localStorage.getItem('processingDate') != null ? <>GWES Processing Date: {localStorage.getItem('processingDate')}</> : <></>}



                        </div>



                        {GWESLink === "null" && CIALink === "null" && AOBLink === "null" ? <></> : <div className='d-flex mx-2 align-self-center'>
                            <Dropdown id='ddrMenu'>
                                <Dropdown.Toggle variant='outline-secondary' size='sm' id="dropdown-basic">

                                    <BsFillGridFill  ></BsFillGridFill>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {GWESLink === "null" ? <></> : <><Dropdown.Item href={GWESLink} target="_blank"><FaLink /> GWES Direct</Dropdown.Item></>}

                                    {CIALink === "null" ? <></> : <><Dropdown.Item onClick={handleCIAClick} target="_blank"><FaLink /> GWES Access</Dropdown.Item></>}

                                    {AOBLink === "null" ? <></> : <><Dropdown.Divider></Dropdown.Divider>
                                        <Dropdown.Item href={AOBLink} target="_blank"><FaLink /> Account Onboarding</Dropdown.Item></>}
                                </Dropdown.Menu>

                            </Dropdown>
                        </div>}

                        <IconButton sx={{ ml: 1 }} onClick={toggle} color="inherit">
                            {allthemes === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        {/* <div className='text-primary accttext fw-bold d-flex align-items-center text-end pt-3 justify-content-center' style={{ width: "60%" }}>
                        <Typography  noWrap>
                            {localStorage.getItem('pConsId') > 0 && acctId === 0 ? <>Relationship: {localStorage.getItem('pConsName')}</> : acctId > 0 ? <>Account: {selAccount.extrnlAcctId}</> : <></>}
                           {localStorage.getItem('processingDate')!=null?<>   GWES processing date:  {localStorage.getItem('processingDate')}</>:<></>}
                        </Typography>

                    </div> */}

                        <Dialog
                            fullWidth={true}
                            maxWidth={'sm'}
                            open={openDialog}
                            onClose={handleCloseDialog}>
                            <DialogTitle style={{ color: allthemes === 'dark' ? '#4ad8ff' : '#006fba' }} >

                                <Title >{"Settings"}
                                    {/* <span style={closeImg} onClick={handleCloseDialog}><AiOutlineCloseCircle></AiOutlineCloseCircle></span>  */}
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseDialog}
                                        style={closeImg}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Title>
                            </DialogTitle>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {showUserList == "1" ? <div className='py-2 pb-3'>
                                        <>User:</>
                                        <DropDownList
                                            style={allthemes === 'dark' ? {
                                                width: "100%",
                                                //display: open ? "" : "none"
                                                background: "#292929",
                                                color: "white"
                                            } : { width: "100%", background: "", color: "black" }}
                                            data={user}
                                            textField="userNm"
                                            dataItemKey='userId'

                                            placeholder="Please select  ..."
                                            onChange={handleChangeUser}

                                            value={selUser}
                                            id='userId'

                                        //  allowCustom={allowCustom}
                                        />
                                    </div> : <></>}

                                    <div style={{ borderStyle: 'solid', borderColor: '#26a3fc', borderWidth: '1px', padding: '20px', borderRadius: '12px' }}>
                                        <div className='py-2'>
                                            <><ClickAwayListener onClickAway={(e) => { setAllowedroledisp(false) }}><Tooltip
                                                PopperProps={{
                                                    disablePortal: false,
                                                }}
                                                arrow
                                                onClose={(e) => { setAllowedroledisp(false) }}
                                                //open={allowedroledisp}
                                                disableFocusListener

                                                disableTouchListener
                                                placement="right"
                                                title={<><>Allowed Roles</>{allowedroleList.map((role) => (

                                                    <li>{role.roleTypDesc}</li>
                                                ))}</>}
                                            >

                                                <a onClick={(e) => { setAllowedroledisp(true) }}><BsInfoCircleFill color='#129bfc'></BsInfoCircleFill></a></Tooltip></ClickAwayListener></>
                                            &nbsp;Role:
                                            <DropDownList

                                                style={allthemes === 'dark' ? {
                                                    width: "100%",
                                                    //display: open ? "" : "none"
                                                    background: "#292929",
                                                    color: "white"
                                                } : { width: "100%", background: "", color: "black" }}
                                                data={userRole}
                                                textField="roleTypDesc"
                                                dataItemKey='roleTypId'

                                                placeholder="Please select  ..."

                                                value={selRole}
                                                id='role'
                                                onChange={handleChangeUserRole}
                                            //  allowCustom={allowCustom}
                                            />

                                        </div>
                                        <div className='py-2'>
                                            <>Consolidation Type:</>
                                            <DropDownList
                                                style={allthemes === 'dark' ? {
                                                    width: "100%",
                                                    //display: open ? "" : "none"
                                                    background: "#292929",
                                                    color: "white"
                                                } : { width: "100%", background: "", color: "black" }}
                                                data={consType}
                                                textField="cnsldtnTypDesc"
                                                dataItemKey='cnsldtnTypId'

                                                placeholder="Please select  ..."
                                                onChange={handleChangeConsType}

                                                value={selCons}
                                                id='consType'

                                            //  allowCustom={allowCustom}
                                            />
                                        </div>
                                        <div className='d-grid gap-2 d-sm-block text-end pt-3' >

                                            <button className='btn btn-block btn-primary mx-2 px-2' onClick={SubmitSetting}>Save</button>
                                            <button className='btn btn-outline-secondary  mx-2 px-2' onClick={handleCloseDialog}>Cancel</button>


                                        </div>
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>




                                {/* <Button className='btn btn-outline-primary' >Ok</Button>
                            <Button className='btn btn-outline-secondary' onClick={handleCloseDialog}>Cancel</Button> */}

                            </DialogActions>

                        </Dialog>

                        <div style={{ display: "flex", justifyContent: 'flex-end', width: "10%" }}>

                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="dark"

                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >

                                {/* <p className='text-center'>Relation Type</p>
                           <Select
                                style={{ minWidth: '340px' }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={consTypeVal}
                                label="Relation"
                                size="small"
                                onChange={handleChangeConsType}
                            >
                                {consTypeList.map((consType) => (
                                    <MenuItem
                                        key={consType.cnsldtnTypId}
                                        value={consType.cnsldtnTypId}
                                    //  style={getStyles(name, personName, theme)}
                                    >
                                        {consType.cnsldtnTypDesc}
                                    </MenuItem>
                                ))}
                            </Select> */}

                                <MenuItem onClick={handleClickSettings}><FaCogs size={20} className='mx-2'></FaCogs> Settings</MenuItem>
                                <Divider></Divider>
                                {localStorage.getItem("UserRoleData") === "2" || localStorage.getItem("UserRoleData") === "14" ? <MenuItem onClick={togglelog}><RiFileUserFill size={20} className='mx-2'></RiFileUserFill >User Log</MenuItem> : <></>}

                                <MenuItem onClick={toggleErrorlog}><BiSolidMessageError size={20} className='mx-2'></BiSolidMessageError >Error Log</MenuItem>

                                <Divider></Divider>
                                <div className='text-danger'>
                                    <MenuItem onClick={handleLogout}><FaSignOutAlt className='mx-2'></FaSignOutAlt>LogOut</MenuItem>

                                </div>

                            </Menu>
                        </div>

                    </Toolbar>
                </AppBar>

                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            // paddingLeft:'7px',
                            // paddingRight:'7px'
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                // disabled={loadingView}
                >
                    <div style={{ minHeight: '45px' }} >
                        <div className='d-flex align-items-center'>
                            {/* <div className='col d-flex text-center h6 text-primary mx-2'>
                                Filters
                            </div> */}
                            <div className='btn btn-sm btn-outline-primary px-1 py-1 m-2' title='Refresh' onClick={handleRadioChange}>
                                Reset <FaRedoAlt></FaRedoAlt>
                            </div>
                            {/* <div className='col text-start'>

                                <div className='btn btn-sm btn-outline-primary px-1 py-1 m-2' title='Refresh' onClick={handleRadioChange}>
                                    <FaRedoAlt></FaRedoAlt>
                                </div>

                                {/* <button onClick={(e)=>{setOpenModal(true); handleDrawerClose();
        setAnchorEl(null);}} className='btn btn-sm btn-outline-primary'>Relationship Code</button>

                            </div> */}
                            <div className='col text-end'>



                                <IconButton onClick={handleDrawerClose}
                                >
                                    <ArrowBackIosNewIcon></ArrowBackIosNewIcon>
                                </IconButton>

                            </div>


                        </div>

                    </div>

                    <div disabled={loadingView}>




                        <>
                            <Modal title="Details" open={openModal} style={{ zIndex: 1400 }}
                                footer={[
                                    <Button key="Ok" onClick={handleCloseModal} >
                                        Ok
                                    </Button>

                                ]}

                            >
                                <p>abcd</p>
                            </Modal>

                            <div className='pt-1'>

                                <RadioGroup
                                    row

                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Relationship"
                                    value={valueRel}
                                    name="radio-buttons-group"
                                    onChange={handleChangeRadioRel}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                        },
                                    }}

                                >
                                    {isRelCode == "1" ?
                                        <>
                                            <FormControlLabel className='mx-1' value="Relationship" control={<Radio />} label="Relationship" />
                                            <FormControlLabel className='mx-1' value="RelationshipCode" control={<Radio />} label="Relationship code" />
                                        </> : <> <FormControlLabel className='mx-1' value="Relationship" control={<FormLabel />} label="Relationship" /></>
                                    }
                                    {/* <FormControlLabel className='mx-1' value="Relationship" control={<FormLabel />} label="Relationship" /> */}
                                    {/* <FormControlLabel className='mx-1'  value="RelationshipCode" control={<Radio />} label="Relationship code" />
                                 */}
                                </RadioGroup>

                            </div>


                            {valueRel == "Relationship" ?
                                <div className='py-1'>
                                    {/* <div className='mx-2 fw-bold kpiheader'>Relationships</div> */}

                                    <ComboBox
                                        style={{
                                            width: "100%",
                                            color: themes === 'dark' ? "white" : "black",
                                            backgroundColor: themes === 'dark' ? "#292929" : "white",
                                            //display: open ? "" : "none"
                                            visibility: open ? 'visible' : 'hidden'
                                        }}
                                        data={relationData}
                                        textField="cnsldtnNm"
                                        dataItemKey='cnsldtnId'
                                        disabled={mode === "Account" ? true : false}
                                        placeholder="Please select  ..."
                                        filterable={true}
                                        onChange={handleChangeRelation}
                                        value={selRelatiosship}
                                        id='relationship'
                                        onFilterChange={onFilterChangeRelation}
                                        onOpen={reltnOpen}
                                        onClose={reltnClose}
                                    //  allowCustom={allowCustom}
                                    />
                                    <Divider />

                                </div>
                                :
                                <div className='py-1'>
                                    {/* <div className='mx-2 fw-bold kpiheader'>Relationship code</div> */}

                                    <ComboBox
                                        style={{
                                            width: "100%",
                                            color: themes === 'dark' ? "white" : "black",
                                            backgroundColor: themes === 'dark' ? "#292929" : "white",
                                            //display: open ? "" : "none"
                                            visibility: open ? 'visible' : 'hidden'
                                        }}
                                        data={relationCdData}
                                        textField="rltnshpCd"
                                        dataItemKey='rltnshpCd'
                                        disabled={mode === "Account" ? true : false}
                                        placeholder="Please select  ..."
                                        filterable={true}
                                        onChange={handleChangeRltnCode}
                                        value={selRltncode}
                                        id='rltnshpCd'
                                        onFilterChange={onFilterChangeRelationCd}
                                        onOpen={reltncdOpen}
                                        onClose={reltncdClose}
                                    //  allowCustom={allowCustom}
                                    />
                                    <Divider />

                                </div>


                            }



                            <div className='mt-5'>
                                <div
                                //  className='mx-2 fw-bold kpiheader'
                                ><FormControlLabel className='mx-1' value="Accounts" control={<FormLabel />} label="Accounts" /></div>

                                <ComboBox
                                    style={{
                                        width: "100%",
                                        visibility: open ? 'visible' : 'hidden',
                                        color: themes === 'dark' ? "white" : "black",
                                        backgroundColor: themes === 'dark' ? "#292929" : "white"
                                        //display: open ? "" : "none"
                                    }}

                                    data={acctDataConsAll}
                                    textField="extrnlAcctId"
                                    dataItemKey='acctId'
                                    // value={}
                                    //  disabled={loadingView}
                                    placeholder="Please select  ..."
                                    filterable={true}
                                    value={selAccount}
                                    id='accounts'
                                    onOpen={reltnClose}
                                    opened={!reltnToggle & open}

                                    popupSettings={{
                                        height: "auto",
                                    }}
                                    onFilterChange={onFilterChangeAcctConsAll}
                                    onChange={handleChangeAccount}
                                //itemRender={itemRender}
                                />

                            </div>




                        </>

                    </div>

                </Drawer>


                <Main open={open}>

                    <DrawerHeader />

                    {loadingView ? <Loading /> : <DashboardContent dashboardData={dashboardData} acctId={acctId} selAccount={selAccount} menuStateData={handleMeuState}  ></DashboardContent>

                    }

                </Main>
                {themes === 'dark' ?
                    <style>
                        {`.k-list-md .k-list-item, .k-list-md .k-list-optionlabel {
    
    background-color: #292929;

    }
    .k-no-data, .k-nodata {
    color: white;
    background-color:#292929;
}
    .k-list-item{
    color: white
    }
    .k-list-item:hover{
    color: #008cff
    }
    .k-list-item.k-selected {
  color: #fff !important;
  background-color: #008deb !importantimpo;
}
  .k-grid-md td, .k-grid-md .k-table-td {
    
    color: white !important;
}
    .k-grid .k-table-row.k-table-alt-row {
        background: rgba(0, 0, 0, 0.17);
    }
    
    .k-master-row.k-table-alt-row .k-grid-content-sticky {
        background: rgba(0, 0, 0, 0.15);
    }
        .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
  .k-pager-numbers-wrap{
  color: rgb(135, 137, 252);
}
.k-pager-numbers .k-selected {
 
  color: rgb(236, 238, 255);
  
}
    `}
                    </style> : <></>}
                {/* <style>
    {`
    body::-webkit-scrollbar {
  width: 15px;               /* width of the entire scrollbar 
}

body::-webkit-scrollbar-track {
  background: orange;        /* color of the tracking area
}

body::-webkit-scrollbar-thumb {
  background-color: blue;    /* color of the scroll thumb 
  border-radius: 20px;       /* roundness of the scroll thumb 
  border: 3px solid orange;  /* creates padding around scroll thumb 
}
    `}</style> */}
            </Box></>

    );
}



