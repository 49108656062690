import React from "react";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { Modal } from "antd";
import {
  FaFileExcel,
  FaPlusSquare,
  FaMinusSquare,
  FaSave,
} from "react-icons/fa";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { TbPlayerTrackPrev, TbPlayerTrackNext } from "react-icons/tb";
import { MultiSelect } from "primereact/multiselect";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from "react-router-dom";

import { FcExpired, FcSettings } from "react-icons/fc";
import Loading from "./loading";

import AcctTransactionGrid from "./acctTransactionGrid";
import { formatDate } from "@telerik/kendo-intl";
import { Button } from "react-bootstrap";
import Enumerable from "linq";
import { distinct } from "@progress/kendo-data-query";
import { DropDownButton } from "@progress/kendo-react-all";
import { AiFillSetting } from "react-icons/ai";
import { IoSettings } from "react-icons/io5";
import { smaller } from "mathjs";
import { durationInMonths } from "@progress/kendo-date-math";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { blue } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";
const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: blue[600],
    "&:hover": {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: blue[600],
  },
}));
const AcctTransactionRpt = ({ acctId, selAccount }) => {
  var acctIdtemp = acctId;
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [filtercheckbox, setfiltercheckbox] = useState(null);
  const [AcctTransactionRptData, populateAcctTransactionRptData] = useState([]);
  const [AcctTransactionRptDataOrg, populateAcctTransactionRptDataOrg] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [modalDatecheck, setmodalDatecheck] = useState(false);
  const [datevalidationmessage, setdatevalidationmessage] = useState("");
  // const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  // const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
  const [session, setSession] = useState("");
  var date = new Date(localStorage.getItem("processingDate"));
  const navigate = useNavigate();
  date.setMonth(date.getMonth() - 12);
  const maxdaterange =
    localStorage.getItem("SelAcctId") == 0
      ? localStorage.getItem("pRltncd") == null ||
        localStorage.getItem("pRltncd") == undefined ||
        localStorage.getItem("pRltncd") == ""
        ? 12
        : 12
      : 12;
  const [frmDate, setfrmDate] = React.useState(date);
  const [pageno, setpageno] = React.useState(1);
  const [isClicked, setIsClicked] = useState(
    localStorage.getItem("isSearchClicked")
  );
  const [searchData, setSearchData] = useState(
    JSON.parse(localStorage.getItem("searchedData"))
  );
  const [firstFlag, setFirstFlag] = useState(0);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [flag, setFlag] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(false);
  const [triggerExport, setTriggerExport] = useState(0);
  const [triggerColapse, setTriggerColapse] = useState(0);
  const [triggerExpand, setTriggerExpand] = useState(0);
  const [triggerColSave, setTriggerColSave] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [state, setState] = React.useState({ value: [], allSelected: true });
  const [effectivecDtInd, seteffectiveDtInd] = useState(true);
  const [excludeSuppress, setexcludeSuppress] = useState(true);
  const [sweepIndicator, setsweepIndicator] = useState(true);
  const [ddrTransTypeList, setDdrTransTypeList] = React.useState([]);
  const [ddrTransTypeVal, setDdrTransTypeVal] = useState([]);
  const themes = useSelector(selectTheme);
  const countries = [
    { name: "Effective Date Indicator", code: "RM" },
    { name: "Exclude Suppress", code: "LDN" },
  ];
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  //maxDate.setMonth(maxDate.getMonth());
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);
  const [selectedOption, setSelectedOption] = useState(null);
  // const minFrmDt = new Date(2021,8,13);
  // const maxFrmDt = new Date(2022,8,14);
  let userId = JSON.parse(localStorage.getItem("userId"));
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        setDdrTransTypeVal(defaultItem);
        //setEmail(email);
        GetAcctTransactionData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [userId]);
  function setDate(val) {
    if (val == null) {
      setfrmDate(frmDate);
      var newToDate = new Date(frmDate);
      newToDate.setMonth(frmDate.getMonth() + maxdaterange);
      if (newToDate >= new Date(localStorage.getItem("processingDate"))) {
        //setToDate(new Date(localStorage.getItem('processingDate')));
      } else {
        //setToDate(newToDate);
      }
    } else {
      setfrmDate(val);
      // var newToDate = new Date(val);
      // newToDate.setMonth(val.getMonth() + 6);
      // if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
      //   setToDate(new Date(localStorage.getItem('processingDate')));
      // }
      // else {
      //   setToDate(toDate);
      //   if(toDate>newToDate)    //Added
      //   setToDate(newToDate);
      // }
    }
  }
  function setTDate(val) {
    if (val == null) {
      setToDate(toDate);
      // var newStartDate = new Date(toDate);
      // newStartDate.setMonth(toDate.getMonth() - 6);
      // setfrmDate(newStartDate);
    } else {
      setToDate(val);
      // var newStartDate = new Date(val);
      // newStartDate.setMonth(val.getMonth() - 6);
      // setfrmDate(frmDate);
      // if(frmDate<newStartDate)      //Added
      // setfrmDate(newStartDate);
    }
  }
  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 35;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        // console.log('save in db');
        // console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctTransactionData();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };
  const formSubmit = (event) => {
    var start = frmDate;
    var end = toDate;
    var datesub = end.getDate() - start.getDate();

    const duration = durationInMonths(start, end);
    const datefrmmincheck = durationInMonths(minDate, end);
    const datetomincheck = durationInMonths(minDate, start);
    const datefrmmaxcheck = durationInMonths(end, maxDate);
    const datetomaxcheck = durationInMonths(start, maxDate);
    if (duration > maxdaterange || (duration === maxdaterange && datesub > 0)) {
      setmodalDatecheck(true);
      setdatevalidationmessage(
        maxdaterange === 1
          ? "Date range should be within " + maxdaterange + " month"
          : "Date range should be within " + maxdaterange + " months"
      );
    } else if (duration < 0) {
      setmodalDatecheck(true);
      setdatevalidationmessage("To date cannot exceed from date.");
    } else if (minDate > end || minDate > start) {
      setmodalDatecheck(true);
      setdatevalidationmessage("Invalid date range.");
    } else if (end > maxDate || start > maxDate) {
      setmodalDatecheck(true);
      setdatevalidationmessage("From/To date cannot exceed processing date");
    } else {
      setTriggerColSave(0);
      setDdrTransTypeVal(defaultItem);
      localStorage.setItem("SubmitClicked", 1);
      GetAcctTransactionData(1);
      setpageno(1);
      getColumnStateDb();
      setExpandedState(false);
    }
  };
  const handleChangeCheckbox = (event) => {
    setfiltercheckbox(event.value);
  };
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const GetAcctTransactionData = async (pageno = 1) => {
    setLoading(true);

    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let userId = JSON.parse(localStorage.getItem("userId"));
    let acctId = localStorage.getItem("SelAcctId");
    //let startDate = "09/13/2019";
    //let startDate= (localStorage.getItem('processingDate'));
    let startDate = formatDate(frmDate, "MM/dd/yyyy");
    let endDate = formatDate(toDate, "MM/dd/yyyy");
    //let endDate = "09/13/2021";
    let EffectiveDtInd = effectivecDtInd;
    let ExcludeSupp = excludeSuppress;
    let SwpInd = sweepIndicator;

    // if(n==1){
    //   for(var i=0;i<filtercheckbox.length;i++){
    //     if(filtercheckbox[i].name==="Effective Date Indicator"){
    //       EffectiveDtInd=true;
    //     }
    //     else if(filtercheckbox[i].name==="Exclude Suppress"){
    //       ExcludeSupp=true;
    //     }
    //   }
    // }

    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    let mConsolidationId = localStorage.getItem("pConsId");
    let PageNo =
      process.env.REACT_APP_TRANSACTION_PAGE_ENABLE !== "0" ? pageno : 0;
    const postData = {
      userId,
      startDate,
      endDate,
      acctId,
      mConsolidationId,
      EffectiveDtInd,
      ExcludeSupp,
      SwpInd,
      RltnshpCd,
      PageNo,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AcctTransactDateRange", postData, config)
      .then((response) => {
        //  console.log(response);

        const rowData = response.data;
        if (rowData.ocAcctTransaction == null) {
          rowData.ocAcctTransaction = [];
          rowData.accountType = "";
          rowData.administrator = "";
          rowData.endingBal = 0;
        }
        localStorage.setItem("EndingBal", rowData.endingBal);
        localStorage.setItem("acctType", rowData.accountType);
        localStorage.setItem("Administrator", rowData.administrator);

        localStorage.setItem("MaxPage", rowData.maxPage);
        populateAcctTransactionRptDataOrg(rowData.ocAcctTransaction);
        if (
          ddrTransTypeVal.tranTypId != undefined &&
          ddrTransTypeVal.tranTypId != "-1" &&
          localStorage.getItem("SubmitClicked") === "0"
        ) {
          var resultByTransType = Enumerable.from(rowData.ocAcctTransaction)
            .where((w) => w.tranTypNm === ddrTransTypeVal.tranTypNm)
            .toArray();

          populateAcctTransactionRptData(resultByTransType);
        } else populateAcctTransactionRptData(rowData.ocAcctTransaction);
        var resultObjectDistinct = orderBy(
          distinct(rowData.ocAcctTransaction, "tranTypId"),
          [{ field: "tranTypNm", dir: "asc" }]
        );

        if (resultObjectDistinct.length == 0) setDdrTransTypeVal(defaultItem);
        setDdrTransTypeList(resultObjectDistinct);

        setFirstFlag(1);
        setFlag(true);
        setLoading(false);

        for (var i = 0; i < rowData.ocAcctTransaction.length; i++) {
          rowData.ocAcctTransaction[i].prcsDt = formatDate(
            new Date(rowData.ocAcctTransaction[i].prcsDt),
            "MM/dd/yyyy"
          );
          rowData.ocAcctTransaction[i].effectiveDate = formatDate(
            new Date(rowData.ocAcctTransaction[i].effectiveDate),
            "MM/dd/yyyy"
          );
          rowData.ocAcctTransaction[i].entryDate = formatDate(
            new Date(rowData.ocAcctTransaction[i].entryDate),
            "MM/dd/yyyy"
          );
          rowData.ocAcctTransaction[i].tradeDate = formatDate(
            new Date(rowData.ocAcctTransaction[i].tradeDate),
            "MM/dd/yyyy"
          );
        }
        localStorage.setItem(
          "filterData",
          JSON.stringify(rowData.ocAcctTransaction)
        );

        getColumnStateDb();
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };
  const filterChange = (event) => {};
  const filterData1 = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
    } else {
      GetAcctTransactionData();
      //console.log(selAcct);

      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const handleChangeTransType = (event) => {
    localStorage.setItem("SubmitClicked", 0);
    setDdrTransTypeVal(event.target.value);
    setFlag(true);
    if (event.target.value.tranTypId != -1) {
      var resultTransType = Enumerable.from(AcctTransactionRptDataOrg)
        .where((w) => w.tranTypNm === event.target.value.tranTypNm)
        .toArray();
      populateAcctTransactionRptData(resultTransType);
    } else {
      populateAcctTransactionRptData(AcctTransactionRptDataOrg);
    }
    setExpandedState(true);
    // handleTriggerColapse();
  };
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked);
  };
  const handleExcludeSuppress = (e) => {
    setexcludeSuppress(e.target.checked);
  };
  const handleSweepIndicator = (e) => {
    setsweepIndicator(e.target.checked);
  };
  const handleExcelExport = (event) => {
    setTriggerExport((triggerExport) => triggerExport + 1);
  };

  const handleTriggerColapse = (event) => {
    setTriggerColapse((triggerColapse) => triggerColapse + 1);
    setExpandedState(false);
  };

  const handleTriggerExpand = (event) => {
    setTriggerExpand((triggerExpand) => triggerExpand + 1);
    setExpandedState(true);
  };
  const handleTriggerColSave = (event) => {
    setTriggerColSave((triggerColSave) => triggerColSave + 1);
  };
  const defaultItem = {
    tranTypNm: "All",
    tranTypId: "-1",
  };

  if (loading) {
    return (
      <>
        <div className="mb-1 row d-flex  p-1 justify-content-between align-items-center">
          <Loading />
        </div>
      </>
    );
  }
  const value = state.value;
  const selected = value.length;
  return (
    <div className="">
      <Modal
        title="Validation"
        open={modalDatecheck}
        onOk={(e) => {
          setmodalDatecheck(false);
        }}
        onCancel={(e) => {
          setmodalDatecheck(false);
        }}
        footer={[
          <Button
            key="Ok"
            onClick={(e) => {
              setmodalDatecheck(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        {datevalidationmessage}
      </Modal>
      <div className="container-fluid rounded my-0 py-0">
        <>
          <table className="table tablel-bordered border border-bottom border-gray my-0 py-0">
            <tbody>
              <tr>
                <td className="align-bottom">
                  {/* {
                expandedState ?
                  <><div className='btn btn-outline-secondary mx-2 btn-sm' onClick={handleTriggerColapse}><FaMinusSquare></FaMinusSquare> collapse</div></> :

                  <><div className='btn btn-outline-secondary mx-2 btn-sm' onClick={handleTriggerExpand}><FaPlusSquare></FaPlusSquare> expand</div></>
              } */}

                  <button
                    className="btn btn-outline-secondary mx-2 btn-sm"
                    onClick={handleExcelExport}
                  >
                    <FaFileExcel></FaFileExcel> export
                  </button>
                  <button
                    className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
                    onClick={handleTriggerColSave}
                  >
                    <FaSave></FaSave> save columns
                  </button>

                  {process.env.REACT_APP_TRANSACTION_PAGE_ENABLE !== "0" ? (
                    <>
                      <button
                        className="btn btn-outline-secondary mx-2 btn-sm"
                        disabled={pageno <= 1 ? true : false}
                        onClick={(e) => {
                          setpageno(pageno - 1);
                          GetAcctTransactionData(pageno - 1);
                        }}
                      >
                        <TbPlayerTrackPrev></TbPlayerTrackPrev>{" "}
                      </button>
                      <button
                        className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
                        disabled={
                          localStorage.getItem("MaxPage") <= pageno
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          setpageno(pageno + 1);
                          GetAcctTransactionData(pageno + 1);
                          setTriggerColSave(0);
                        }}
                      >
                        <TbPlayerTrackNext></TbPlayerTrackNext>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                {/* <td className='align-bottom'>
            <span className='py-1'>Transaction Type: </span>
        
            <DropDownList
                style={{
                  width: "200px",

                }}
                size={'small'}
                className='form-control form-control-sm'
                data={ddrTransTypeList}
                textField="tranTypNm"
                dataItemKey='tranTypId'

                //placeholder="Please select  ..."
                defaultItem={defaultItem}

                value={ddrTransTypeVal}
                id='ddrTransType'
                onChange={handleChangeTransType}

              //  allowCustom={allowCustom}
              />
            </td>
             */}
                <td className="align-bottom">
                  <span
                    className="py-1"
                    style={{ color: themes === "dark" ? "white" : "#454545" }}
                  >
                    From:{" "}
                  </span>

                  <DatePicker
                    format="MM/dd/yyyy"
                    //calendar={CustomCalendar}
                    placeholder=""
                    className="form-control form-control-sm"
                    size={"small"}
                    value={frmDate}
                    width={150}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    min={minFrmDt}
                    max={maxFrmDt}
                    disabled={isDisabled}
                    onChange={(e) => {
                      setDate(e.value);
                    }}
                  />
                </td>
                <td className="align-bottom">
                  <span
                    className="py-1"
                    style={{ color: themes === "dark" ? "white" : "#454545" }}
                  >
                    To:{" "}
                  </span>
                  <DatePicker
                    format="MM/dd/yyyy"
                    // calendar={CustomCalendar}
                    width={150}
                    placeholder=""
                    size={"small"}
                    className="form-control form-control-sm"
                    value={toDate}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    min={minFrmDt}
                    max={maxFrmDt}
                    disabled={false}
                    onChange={(e) => {
                      setTDate(e.value);
                    }}
                  />
                </td>
                <td className="align-bottom">
                  <div
                    className="p-float-label flex justify-content-center"
                    style={{ minHeight: 20 }}
                  >
                    {/* <MultiSelect value={filtercheckbox} onChange={handleChangeCheckbox} options={countries} optionLabel="name" 
                  maxSelectedLabels={1}  className="w-half h-half md:w-10rem" />
                <label htmlFor="ms-cities">&nbsp;Filters</label> */}
                    <Dropdown autoClose="outside" id="ddrMenu">
                      <Dropdown.Toggle
                        variant="outline-none"
                        size="sm"
                        id="dropdown-basic"
                      >
                        <IoSettings
                          style={{
                            color: themes === "dark" ? "white" : "#454545",
                            borderColor:
                              themes === "dark" ? "white" : "#454545",
                          }}
                          size={20}
                        ></IoSettings>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          color: themes === "dark" ? "#cfcfcf" : "#454545",
                          backgroundColor:
                            themes === "dark" ? "#1f1f1f" : "white",
                          borderColor: themes === "dark" ? "#cfcfcf" : "black",
                        }}
                        width={70}
                      >
                        <FormControlLabel
                          control={
                            <BlueSwitch
                              checked={effectivecDtInd}
                              onClick={handleEffectiveDtInd}
                              name="chkCtr"
                            />
                          }
                          label="Effective Date"
                        />
                        <hr className="mt-1 mb-0 pb-0"></hr>

                        <FormControlLabel
                          control={
                            <BlueSwitch
                              checked={excludeSuppress}
                              name="chkCtr"
                              onClick={handleExcludeSuppress}
                            />
                          }
                          label="Exclude Suppress Transaction"
                        />
                        <hr className="mt-1 mb-0 pb-0"></hr>
                        <FormControlLabel
                          control={
                            <BlueSwitch
                              checked={sweepIndicator}
                              name="chkCtr"
                              onClick={handleSweepIndicator}
                            />
                          }
                          label="Exclude Sweep Transaction"
                        />
                        {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
                <td className="align-bottom">
                  <input
                    type="button"
                    onClick={formSubmit}
                    className="btn btn-primary btn-sm"
                    value="submit"
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
        </>
        {/* <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Transactions Report
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">

                <form onSubmit={formSubmit}  >

                  <div className='container-fluid'>

                    <div>
                      <div>

                        <div className='row d-flex  mx-2 px-1'>

                          <div className='col-md-3 col-lg-3 col-sm-10'>
                            <span className='py-1'>From</span>
                            <DatePicker id="dpFrm"
                              value={frmDate}
                              format="MM/dd/yyyy"
                              calendar={CustomCalendar}
                              min={minFrmDt}
                              max={maxFrmDt}
                              disabled={isDisabled}
                              onChange={(e) => {
                                setDate(e.value);

                              }}

                            />

                          </div>

                          <div className='col-md-3 col-lg-3 col-sm-10'>
                            <span className='py-1'>To</span>
                            <DatePicker
                              //defaultValue={toDate}
                              value={toDate}
                              calendar={CustomCalendar}
                              format="MM/dd/yyyy"
                              min={minFrmDt}
                              max={maxFrmDt}
                              disabled={false}
                              onChange={(e) => {
                                setTDate(e.value);

                              }}
                            />

                          </div>

                          <div className='col-md-2 col-lg-2 col-sm-10'>

                            <input type="submit" className='btn btn-primary btn-sm' value="Submit" style={{ marginTop: '20px' }} />

                          </div>

                        </div>
                      </div></div></div>
                </form>
              </div>
            </div>
          </div>
        </div> */}

        <div>
          {flag ? (
            <div
              className="row d-flex justify-content-between"
              style={{ border: "none" }}
            >
              <AcctTransactionGrid
                data={AcctTransactionRptData}
                flag={isColumnSave}
                triggerExport={triggerExport}
                triggerColapse={triggerColapse}
                triggerExpand={triggerExpand}
                triggerColSave={triggerColSave}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcctTransactionRpt;
