

import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { dashboardData } from "./drawerLeftNew";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { useState, useEffect, useRef } from 'react';
import {ResponsiveContainer} from 'recharts';
// import Button from '@mui/material/Button';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import { process } from "@progress/kendo-data-query";
import { CustomColumnMenuNoGrpChkBox } from './customColumnMenuNoGrpChkBox';
import { Modal } from 'antd';
import { ExcelExport,ExcelExportColumnGroup,ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { BiSolidPhoneCall, BiUnderline } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { FaFileExcel, FaSms } from "react-icons/fa";
import { Link } from "react-router-dom";
import { blue } from "@mui/material/colors";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import React from 'react'

const AddressBook = ({addData}) => {
    
  const _export = useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const initialGroup = [
    {field:'extrnlAcctId'},
        { field: 'roleTypNm' }
  ];
    const [data, setData] = useState(addData);
    const [show, setShow] = useState(false);
    const [details, setDetails] = useState("");
    const [expandedState,setExpandedState]=useState(true);
    const [columnLatest,setColumnLatest]=React.useState(null);
    let closeImg = { cursor: 'pointer', float: 'right', marginTop: '5px', width: '20px' };
    const Title = ({ children }) => <div className="title">{children}</div>;
    const dataExport = process(addData, {
        group: initialGroup,
      }).data;
    const totalSum = (props) => {
        const field = props.field || '';
        const total = data
          .reduce((acc, current) => acc + current[field], 0)
          .toFixed(2);
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, '##,#.00')}
          </td>
        );
      };

      
      const onClick = () => {
        setShow(!show);
      };

      const menuWithExcelCheck = (props) => {
   
        return (
          <div>
    
            <CustomColumnMenuNoGrpChkBox
              {...props}
              columns={stateColumns}
              data={data}
              onColumnsSubmit={onColumnsSubmit}
            />
          </div>)
      };
      const windowHeight = useRef(window.innerHeight);
      const menuWithoutExcelCheck = (props) => {
       
        return (
          <div>
    
            <CustomColumnMenuNoGrp
              {...props}
              columns={stateColumns}
              data={data}
              onColumnsSubmit={onColumnsSubmit}
            />
          </div>)
      };
      const themes=useSelector(selectTheme);
      let defaultColumns = [
        // {
        //   title: 'Account',
        //   field: 'extrnlAcctId',
        //   minWidth: "auto",
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        //   columnMenu:menuWithExcelCheck
        // },
        // {
        //     title: 'Account',
        //     field: 'roleTypNm',
        //     minWidth: "auto",
        //     show: true,
        //     filter: 'text',
        //     locked: false,
            
        //     columnMenu:menuWithExcelCheck
        //   },
        {
          title: 'Contact Name',
          field: 'cntctNm',
          minWidth: "200",
          show: true,
          filter: 'text',
          locked: false,
         orderIndex:-1
          
          //columnMenu:menuWithExcelCheck
        },
          {
            title: 'Email',
            field: 'emailAdrs',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
            headerClassName: 'centerHeader',
            orderIndex:0
            //columnMenu:menuWithExcelCheck
          },
          {
            title: 'Home Phone',
            field: 'homePhone',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
            headerClassName: 'centerHeader',
            orderIndex:1
            //columnMenu:menuWithExcelCheck
          },
          {
            title: 'Cell Phone',
            field: 'cellPhone',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
            headerClassName: 'centerHeader',
            orderIndex:2
            //columnMenu:menuWithExcelCheck
          },
         
          {
            title: 'Office Phone',
            field: 'officePhone',
            minWidth: "auto",
            show: true,
            filter: 'text',
            locked: false,
            headerClassName: 'centerHeader',
            orderIndex:3
            //columnMenu:menuWithExcelCheck
            
          }
          
        ];
        
    const createDataState = (dataState) => {
      return {
        result: process(data, dataState),
        dataState: dataState,
      };
    };
    let initialState = createDataState({
      take: 500,
      skip: 0,
      group: [
        {field:'extrnlAcctId'},
        { field: 'roleTypNm' }
        
      ]
    });

    const handleCommentClik=(props)=>
    {
      
      setDetails(props.currentTarget.innerText);
      setShow(true);

    }
   const CustomCell = (props) => {
    const field = props.field || '';
    const value = props.dataItem[field];
  return (
    <td onClick={handleCommentClik}
    title= {value}
      {...props.tdProps}
      colSpan={1}
      style={{
        color: props.color,
        whiteSpace:props.whiteSpace,
        textDecoration:props.textDecoration,
        cursor:props.cursor
      }}
    >
      {value}
    </td>
  );
};
const MyCustomCell = (props) => <CustomCell {...props} color={"blue"} whiteSpace={"nowrap"} textDecoration={"underline"} cursor={"pointer"} />;
    const [result, setResult] = useState(initialState.result);
    const [dataState, setDataState] = useState(initialState.dataState);
    const [stateColumns, setStateColumns] = useState(defaultColumns);
    // columns[3].className = 'customCell';
    // columns[3].cell   =MyCustomCell;
    const addHiddenColumns = (columns) => {
      
      let newColumnsState = defaultColumns.map((col) => {
        let _col = columns.filter((c) => c.field == col.field);
        if (_col.length > 0) {
          return {
            ...col,
            orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
            width: _col[0].width ? _col[0].width : '',
          };
        } else {
          return { ...col, show: false };
        }
      });
      for (var i = 0; i < newColumnsState.length; i++) {
        
        for(var j=0;j<columns.length;j++){
          if(newColumnsState[i].field===columns[j].field)
          newColumnsState[i].headerClassName=columns[j].headerClassName;
        }
      }
      
      //newColumnsState[6].footerCell = totalSum;
      //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
      var tempcolumn=[];
      
        var j=0;
        for(var i=0;i<newColumnsState.length;i++){
         
          tempcolumn[j]=newColumnsState[i];
         
          j++;
        }
        
      return tempcolumn;
      const [, ...restnew] = newColumnsState;
      //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
      if(localStorage.getItem("SelAcctId") > 0 && newColumnsState[0].field==="accountName"){
        return restnew;
      }
      else{
        return newColumnsState;
      }
      
      // newColumnsState[0].columnMenu = menuWithExcelCheck;
      // newColumnsState[1].columnMenu = menuWithExcelCheck;
      // newColumnsState[2].columnMenu = menuWithExcelCheck;
      // newColumnsState[3].columnMenu = menuWithExcelCheck;
      // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[11].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[12].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[13].columnMenu = menuWithExcelCheck;
      // newColumnsState[14].columnMenu = menuWithExcelCheck;
      // newColumnsState[15].columnMenu = menuWithExcelCheck;
      // newColumnsState[16].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[17].columnMenu = menuWithExcelCheck;
      // newColumnsState[18].columnMenu = menuWithExcelCheck;
      // newColumnsState[19].columnMenu = menuWithExcelCheck;
      // newColumnsState[20].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[21].columnMenu = menuWithoutExcelCheck;
      // newColumnsState[22].columnMenu = menuWithExcelCheck;
      // newColumnsState[23].columnMenu = menuWithExcelCheck;
      // newColumnsState[24].columnMenu = menuWithExcelCheck;
  
      
    };
  const columnReorder=(props)=>{
    
    setStateColumns(addHiddenColumns(props.columns));
    
    }
    const dataStateChange = (event) => {

      if(event.dataState.filter!==undefined){
      if(event.dataState.filter.filters.length>0){
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (stateColumns[i].field == event.dataState.filter.filters[j].filters[0].field) {
              stateColumns[i].headerClassName="active";
              break;
            }
            else{
              stateColumns[i].headerClassName="";
            }
          }
        }
      }
      else{
        for (var i = 0; i < stateColumns.length; i++) {
          
              stateColumns[i].headerClassName="";
            }
          }
    }

    else{
      for (var i = 0; i < stateColumns.length; i++) {
          
        stateColumns[i].headerClassName="";
      }
      
    }
    
    for (var i = 0; i < stateColumns.length; i++) {
      
      for(var j=0;j<defaultColumns.length;j++){
        if(stateColumns[i].field===defaultColumns[j].field)
        stateColumns[i].headerClassName=defaultColumns[j].headerClassName+" "+stateColumns[i].headerClassName;
      }
    
  }

      let updatedState = createDataState(event.dataState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    };

    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
      };
      const expandChange = (event) => {
        
        const isExpanded =
          event.dataItem.expanded === undefined
            ? event.dataItem.aggregates
            : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({
            ...result,
            data: [...result.data],
          });
          //  setResult({ ...result });
            // let updatedState = createDataState(event.dataState);
            // setDataState(updatedState.dataState);
      }
      
       
       
      const getCells = (columns, cellProps) => {
        let cells = [];
        columns.forEach((column) => {
          if (column.aggregate) {
            cells.push(
              <td style={{ textAlign: "right" }}>
                {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
              </td>
            );
          } else {
            cells.push(<td>&nbsp;</td>);
          }
        });
        return cells;
      };
      const CustomGroupHeader = (props) => {
        
        return `${props.value}`;
      };
      const cellRender = (tdElement, cellProps) => {
        // if (
        //   cellProps.rowType === 'groupHeader' &&
        //   tdElement &&
        //   tdElement.props.role != 'presentation'
        // ) {
          
        //   const column = columns;
        //   return (
        //     <>
        //       <td
        //         {...tdElement.props}
        //         colSpan={tdElement.props.colSpan - column.length}
        //       ></td>
        //       {getCells(column, cellProps)}
        //     </>
        //   );
        // }
        // if (cellProps.rowType === 'groupFooter') {
    
        //   if (cellProps.field === 'totMarket') {
        //     return (
        //       <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
        //         {cellProps.dataItem.aggregates.totMarket.sum}
        //       </td>
        //     );
        //   }
    
        // }
        
        if (cellProps.rowType === "data") {
  
          if (cellProps.field === "elAcctId") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {cellProps.dataItem[cellProps.field]+cellProps.dataItem[cellProps.shrtNm]}
              </td>
            );
          }
          if (cellProps.field === "emailAdrs") {
            
            
            if(cellProps.dataItem[cellProps.field]!==null && cellProps.dataItem[cellProps.field]!==""){

            
            return (
              <td style={{ textAlign: 'center' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {/* <button className="btn btn-sm btn-outline-secondary" title={cellProps.dataItem[cellProps.field]} onClick={() => window.location.href="mailto:"+cellProps.dataItem[cellProps.field]}><FiMail size={20}/> </button> */}
                <Link to="#"  style={{color:themes==='dark'?'#4ad8ff':'#004cff', textDecoration:'underline', cursor:'pointer'}} onClick={(e)=>{window.location.href="mailto:"+cellProps.dataItem[cellProps.field]; e.preventDefault()}}>{cellProps.dataItem[cellProps.field]}</Link>
                
              </td>
            );}
          }
          if (cellProps.field === "homePhone") {
            
            if(cellProps.dataItem[cellProps.field]!==null && cellProps.dataItem[cellProps.field]!==""){
            return (
              <td style={{ textAlign: 'center' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {/* <button className="btn btn-sm btn-outline-secondary" title={(cellProps.dataItem[cellProps.field])} onClick={() => console.log(cellProps.dataItem[cellProps.field])}><BiSolidPhoneCall size={20}/> </button> */}
                {cellProps.dataItem[cellProps.field]}
              </td>
            );
            }
          }
          if (cellProps.field === "cellPhone") {
    
            
            if(cellProps.dataItem[cellProps.field]!==null && cellProps.dataItem[cellProps.field]!==""){
            
            return (
              <td style={{ textAlign: 'center' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {/* <button className="btn btn-sm btn-outline-secondary" title={cellProps.dataItem[cellProps.field]} onClick={() => window.location.href="sms:"+cellProps.dataItem[cellProps.field]}><FaSms size={20}/> </button>  */}
                {cellProps.dataItem[cellProps.field]}
                {/* <button className="btn btn-sm btn-outline-secondary" title={cellProps.dataItem[cellProps.field]} onClick={() => window.location.href="tel:"+cellProps.dataItem[cellProps.field]}><BiSolidPhoneCall size={20}/> </button> */}
              </td>
            );}
          }
          if (cellProps.field === "officePhone") {        
            if(cellProps.dataItem[cellProps.field]!==null && cellProps.dataItem[cellProps.field]!==""){
            return (
              <td style={{ textAlign: 'center' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {/* <button className="btn btn-sm btn-outline-secondary" title={cellProps.dataItem[cellProps.field]} onClick={() => window.location.href="sms:"+cellProps.dataItem[cellProps.field]}><BiSolidPhoneCall size={20}/> </button> */}
                {cellProps.dataItem[cellProps.field]}
              </td>
            );}
          }
          
          
          
        }
        return tdElement;
      };
      const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
      };
      const handleColapse=(event)=>
      {
    
    
        result.data.map(item => {
         // let index = this.expandedValues.indexOf(event.dataItem.value);
         item.expanded = false;
         return item
          // if (index === -1) {
          //   item.expanded = false
          //   return item
          // }
          // return item
        });
        setExpandedState(false);
        setResult({
          ...result,
          data: [...result.data],
        });
    
    
    
      }
    
      const handleExpand=(event)=>
      {
    
    
        result.data.map(item => {
         // let index = this.expandedValues.indexOf(event.dataItem.value);
         item.expanded = true;
         return item
          // if (index === -1) {
          //   item.expanded = false
          //   return item
          // }
          // return item
        });
        setExpandedState(true);
        setResult({
          ...result,
          data: [...result.data],
        });
    
    
    
      }
      var excelTitle= localStorage.getItem("pConsId")==0&&localStorage.getItem("SelAcctId")==0?"": localStorage.getItem("SelAcctId")==0?"Consolidation:"+localStorage.getItem("pConsName"):"Account Number:"+localStorage.getItem("SelAcctNm");
      var stateColExport=stateColumns;
      var columnLatest2=columnLatest;
      if(columnLatest2!==null&&columnLatest2.filter(x => x.field !=='mjrAstNm')){
        columnLatest2=[{
          title: 'Major Asset',
          field: 'mjrAstNm',
          groupHeader:CustomGroupHeader,
          show: false,
         
        
        },{
            title:'Minor Asset',
            field: 'mnrAstNm',
            groupHeader:CustomGroupHeader,
            show: false,
           
          },...columnLatest2]
      }
      
      if(columnLatest2!==null){
       for(var i=0;i<columnLatest2.length;i++){
        for(var j=0;j<stateColumns.length;j++){
          if(columnLatest2[i].field===stateColumns[j].field){
            columnLatest2[i].show=stateColumns[j].show;
          }
        }
       }
      }
      
      if(stateColExport.filter(x => x.field !=='mjrAstNm'))
      {
        stateColExport=[{
      title: '',
      field: 'extrnlAcctId',
      groupHeader:CustomGroupHeader,
      show: false,
     
    
    },{
        title:'',
        field: 'roleTypNm',
        groupHeader:CustomGroupHeader,
        show: false,
       
      },...stateColExport]
      }
      stateColExport.sort(function (a,b) {
        return a.orderIndex-b.orderIndex;
        
      })
    return(
           
      // <ResponsiveContainer>  
        <div>
             <Modal title="Details" open={show} onOk={onClick} onCancel={onClick}
        footer={[
          <Button key="Ok" onClick={onClick}>
            Ok
          </Button>

        ]}

      >
        {details}
      </Modal>
      <ExcelExport data={dataExport} group={initialGroup} ref={_export} fileName={ "AddressBook.xlsx"}>
      <ExcelExportColumnGroup
  title={excelTitle +  "  Processing Date: " + localStorage.getItem("processingDate")}
  headerCellOptions={{
    textAlign: "left",
  }}
>
  
{
stateColExport.map((w)=>{
  
return(
  <ExcelExportColumn field={w.field} hidden={!w.show}  title={w.title} headerCellOptions={w.headerCellOptions} groupHeader={w.groupHeader} cellOptions={w.headerCellOptions}/>
)

  

})
}
{/* <ExcelExportColumn
                 field="extrnlAcctId"
                 hidden={true}
                 groupHeader={CustomGroupHeader}
               />
               <ExcelExportColumn
                 field="roleTypNm"
                 hidden={true}
                 groupHeader={CustomGroupHeader}
               />
<ExcelExportColumn field="cntctNm" title="Contact Name" />
<ExcelExportColumn field="emailAdrs" title="Email" />
<ExcelExportColumn field="homePhone" title="Home Phone" />
<ExcelExportColumn field="cellPhone" title="Cell Phone" />

<ExcelExportColumn field="officePhone" title="Office Phone" /> */}

  {/* {columns.map((w)=>(
    <ExcelExportColumn field={w.field}  title={w.title} />

  ))} */}
{/* <ExcelExportColumn field="extrnlAcctId" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Shares" />
  <ExcelExportColumn field="cost" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Cost($)" />
  <ExcelExportColumn field="market" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Market($)" />
  <ExcelExportColumn field="unrGainLoss" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Unr Gain Loss($)" />
  <ExcelExportColumn field="estAnnInc" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Est Ann Income($)" />
  <ExcelExportColumn field="yield" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Yield(%)" /> */}

    </ExcelExportColumnGroup>
    </ExcelExport>   
     
    <Grid
      //id='tileGrid'
      style={{ height: windowHeight.current - 112}}
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      //pageable={true}
      expandField="expanded"
      onExpandChange={expandChange}
      cellRender={cellRender}
      onColumnReorder={columnReorder}
      sortable={true}
      resizable={false}
      pageable={true}
      reorderable={true}
      scrollable='scrollable'
       //pageSize={10}
       
      
    ><GridToolbar>
    {/* <Button  sx={{ textTransform: 'none' }} variant="outlined" disabled>Add+</Button> */}
  
        <button className='btn btn-outline-secondary align-items-center mx-2 btn-sm' onClick={excelExport}><FaFileExcel></FaFileExcel>&nbsp; export</button>
        <BsFillInfoCircleFill title='Grouping by Account number and assets.' color='#0384fc' size={15}/>
              {/* {
                expandedState ?
                  <><div className='btn btn-outline-primary btn-sm' onClick={handleColapse}>Collapse</div></> :

                  <><div className='btn btn-outline-primary btn-sm' onClick={handleExpand}>Expand</div></>
              } */}
  </GridToolbar>
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
            //  locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              footerCell={column.footerCell}
              headerClassName={column.headerClassName}
              {...column}
              //cell={NumberCell}
              //columnMenu={column.columnMenu}
              // columnMenu={(props) => (
              //   <menuWithExcelCheck
              //     {...props}
              //     columns={stateColumns}
              //     onColumnsSubmit={onColumnsSubmit}
              //   />
              // )}
              columnMenu={(props) => (
                <>
                  {column.filter === "text" ? (
                    <CustomColumnMenuNoGrpChkBox
                      {...props}
                      columns={stateColumns}
                      data={data}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  ) : column.filter === "numeric" ? (
                    <CustomColumnMenuNoGrp
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  ) : (
                    <CustomColumnMenuNoGrpChkBox
                      {...props}
                      columns={stateColumns}
                      data={data}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                </>
              )}
            />
          )
      )}
    </Grid>
    {themes==='dark'?
    <style>
      {`
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;

}
  .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
`}
    </style>:<></>}
    </div>       
    // </ResponsiveContainer>                      
                            
                        
    
                    
    )
    
}
export default AddressBook


