import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
//import SelectControl from './selectcontrol';
import Loading from './loading';
import { DatePicker } from "@progress/kendo-react-dateinputs";
//import Header from './header';
import TimeWtdRtnRptGrid from './TimeWtdRtnRptGrid';
import { Button, Card } from '@mui/material';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from 'react-bootstrap/Modal';
import { FcExpired } from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from 'react-bootstrap';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
var CardstartMarket;
var Carddisbursement;
var Cardincome;
var CardrlGainLoss;
var CardulGainLoss;
var CardendMarket; 
const TimeWtdRtnRpt = (acctId) => {

  const [TimeWtdRtnRptRptData, populateTimeWtdRtnRptRptData] = useState([]);
  const [TimeWtdRtnRptRptDataROR, populateTimeWtdRtnRptRptDataROR] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
  const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [loading, setLoading] = useState(true);
  const [IncepDt,setIncepDt]=useState();
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem('processingDate'));
  date.setMonth(date.getMonth() - 1);
  localStorage.setItem("FrmDate",date);
  const [frmDate, setfrmDate] = React.useState(date);
  const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const minFrmDt = new Date(2021, 8, 14);
  const maxFrmDt = new Date(2022, 8, 14);
  const [isDisabled, setisDisabled] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [session, setSession] = useState("");
  const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();
  // const dateTyplist = [
  //   { type: '30Days', id: 1 },
  //   { type: '60Days', id: 2 },
  //   { type: '90Days', id: 3 },
  //   { type: '1/2Year', id: 4 },
  //   { type: '1Year', id: 5 },
  //   { type: '2Years', id: 6 },
  //   { type: '3Years', id: 7 }
  // ];
  const [dateType, setDateType] = React.useState(1);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
        GetTimeWtdRtnRpteData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, []);
  
  
  const formSubmit = (event) => {
    GetTimeWtdRtnRpteData();
  }
  
  const handleChangeDateTyp = (e) => {
    
    if (e.target.innerText == "30 Days") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 1);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(1);
    }
    if (e.target.innerText == "60 Days") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 2);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(2);
    }
    if (e.target.innerText == "90 Days") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 3);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(3);
    }
    if (e.target.innerText == "180 Days") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 6);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(4);
    }
    if (e.target.innerText == "1 Year") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 12);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(5);
    }
    if (e.target.innerText == "2 Years") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 24);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(6);
    }
    if (e.target.innerText == "3 Years") {
      var date = new Date(localStorage.getItem('processingDate'));
      date.setMonth(date.getMonth() - 36);
      setfrmDate(date);
      localStorage.setItem("FrmDate",date);
      setDateType(7);
    }

    GetTimeWtdRtnRpteData();
  }

  const GetTimeWtdRtnRpteData = async () => {
    
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem('userId'));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = acctId.acctId;
    let PageId = 1;
    let netFeeId = 1;
    
    let StDate = new Date(localStorage.getItem("FrmDate"));
    let ToDate = toDate;
    let RltnshpCd =localStorage.getItem("pRltncd")==null||localStorage.getItem("pRltncd")==undefined?"":localStorage.getItem("pRltncd");
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    let mConsolidationId = localStorage.getItem('pConsId');
    const postData = { AsOfId, AcctId, netFeeId, PageId, StDate, ToDate, mConsolidationId ,RltnshpCd};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTTimeWtdRtnOfSlctdPrds',
      postData,
      config
    )
      .then(response => {

        //  console.log(response);
        
        const rowData = response.data;
        //console.log(rowData);
        
        let data_1= rowData.lstRTTimeWtdReturnT1.map((object,i) => {
          return {...object, name: rowData.lstRTTimeWtdReturnT1[i]["account"]+':'+rowData.lstRTTimeWtdReturnT1[i]["accountName"]} ;
        
        });
        
        populateTimeWtdRtnRptRptData(data_1);
        populateAccountListData(rowData.lstAcctTmWtdRtnList);
        //
        populateTimeWtdRtnRptRptCardData(rowData.lstAcctTmWtdRtnListT3)
        localStorage.setItem("CardData", rowData.lstAcctTmWtdRtnListT3);
        
        CardstartMarket=rowData.lstAcctTmWtdRtnListT3[0].startMarket;
        Carddisbursement=rowData.lstAcctTmWtdRtnListT3[0].disbursement;

        Cardincome=rowData.lstAcctTmWtdRtnListT3[0].income;
        CardrlGainLoss=rowData.lstAcctTmWtdRtnListT3[0].rlGainLoss;
        CardulGainLoss=rowData.lstAcctTmWtdRtnListT3[0].ulGainLoss;
        CardendMarket=rowData.lstAcctTmWtdRtnListT3[0].endMarket;
        let cdt = new Date(rowData.perfIncptnDt);
        let siDate=(cdt.getMonth() + 1).toString().padStart(2, '0')+"/"+cdt.getDate().toString().padStart(2, '0')+"/"+cdt.getFullYear();
        setIncepDt(siDate);
        populateTimeWtdRtnRptRptDataROR(rowData.lstAcctTmWtdRtnListT2);
        setSelAcctData(rowData.lstAcctTmWtdRtnList);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        else {
          SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        // getColumnStateDb();
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshToken();

        }
        // return error;
      });

  }

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();

      })
      .catch((error) => {
        // 
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }

  const getColumnStateDb = async () => {
    //Storing column settings in DB
    
    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTGetGridColumn/Index',
      postData,
      config
    )
      .then(response => {
        
        console.log('save in db');
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem('gridColumns', rowData);
        }
        else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log('error in save db ' + error);
        return error;
      });
  }

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }

  };

  const signOut = () => {

    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
      postData,
      config
    )
      .then((response) => {
        // 
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");

        // if (response.statusText === '') {

        // }

      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem('token');
    localStorage.clear();
    //firebaseApp.auth.signOut();

  }
  
  localStorage.getItem("CardData")
  if (loading) {
    return (
      <>
        {session === "Session Expired" ?

          <Modal show={true} fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{ outlineColor: "black", borderBlockColor: "black", backgroundColor: "#0099ff", width: "50px" }} onClick={signOut}>OK</a></div></Modal.Body></Modal>
          :
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>}</>
    )
  }
  

  return (
    <div className=''> 
      <div className='container-fluid'>

        <div>
          <div>
            {/* <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Performance Report
              </button>
            </h2> */}
            <div>
              <div>

                <form onSubmit={formSubmit}  >

                <div className='my-1'>

                  
<div className='row card-group mb-2'>
<Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
  <div className=' kpicard'>
    <div className=' kpiheader '>Start Market </div>
    <hr className='mt-1 mb-0 pb-0'></hr>
    <div className=' kpibody'>
      ${CardData.length === 0  ? '0' : formatNumber(abs(CardstartMarket), "##,#")}
    </div>

  </div>
  </Card>
  <Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
  <div className='kpicard'>
    <div className=' kpiheader '>Disbursement</div>
    <hr className='mt-1 mb-0 pb-0'></hr>
    <div className=' kpibody'>
      ${CardData.length === 0 ? '0' : formatNumber(abs(Carddisbursement), "##,#")}

    </div>

  </div>
</Card>
<Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
  <div className=' kpicard'>
    <div className='kpiheader'> Income</div>
    <hr className='mt-1 mb-0 pb-0'></hr>
    <div className='kpibody'>
      ${CardData.length === 0  ? '0' : formatNumber(abs(Cardincome), "##,#")}

    </div>

  </div>
  </Card>
  <Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
  <div className='kpicard'>
    <div className='kpiheader '>Realized Gain/Loss</div>
    <hr className='mt-1 mb-0 pb-0'></hr>
    <div className='kpibody'>
      ${CardData.length === 0  ? '0' : formatNumber(abs(CardrlGainLoss), "##,#")}

    </div>

  </div>
  </Card>
  <Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
  <div className='kpicard'>
    <div className='kpiheader'>Unrealized Gain/Loss </div>
    <hr className='mt-1 mb-0 pb-0'></hr>
    <div className='kpibody'>
      ${CardData.length === 0 ? '0' : formatNumber(abs(CardulGainLoss), "##,#")}
    </div>

  </div>
  </Card>
  <Card style={{borderRadius:'0px', alignContent:'center',justifyContent:'center'}} className='col'>
  <div className='kpicard'>
    <div className='kpiheader '>End Market </div>
    <hr className='mt-1 mb-0 pb-0'></hr>
    <div className='kpibody'>
      ${CardData.length === 0 ? '0' : formatNumber(abs(CardendMarket), "##,#")}
    </div>

  </div>
  </Card>
</div>

</div>

             
                </form>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Card className='cardStyle'>
            <form  >
              <div>
              <div className='container-fluid'>

<div>
  <div>

    <div className='row d-flex justify-content-between align-items-center mx-2 px-1'>
      <div className='col'>

      <ButtonToolbar>
        <ToggleButtonGroup type="radio" size='small' name="radioNm" value={dateType} onClick={handleChangeDateTyp}  >
          <ToggleButton className="me-1" variant="outline-secondary" value={1} size='small'>30 Days</ToggleButton >
          <ToggleButton className="me-1" variant="outline-secondary" value={2} size='small'>60 Days</ToggleButton>
          <ToggleButton className="me-1" variant="outline-secondary" value={3} size='small'>90 Days</ToggleButton>
          <ToggleButton className="me-1" variant="outline-secondary" value={4} size='small'>180 Days</ToggleButton>
          <ToggleButton className="me-1" variant="outline-secondary" value={5} size='small'>1 Year</ToggleButton>
          <ToggleButton className="me-1" variant="outline-secondary" value={6} size='small'>2 Years</ToggleButton>
          <ToggleButton className="me-1" variant="outline-secondary" value={7} size='small'>3 Years</ToggleButton>
        </ToggleButtonGroup  >

      </ButtonToolbar>

      </div>
      <div className='col px-1 text-end'>
          <div className='text=muted small text-end'>
            Excluded Net Fees in the ROR calculation
            </div>

      </div>









     

      {/* <DropDownList
        style={{
          width: "auto",height:"auto"
        }}
        data={dateTyplist}
        textField="type"
        dataItemKey="id"
        filterable={false}
        value={dateType}
        label=''
        onChange={handleChangeDateTyp}

      // onFilterChange={filterChange}
      /> */}
      {/* <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
          value={frmDate}
          format="MM/dd/yyyy"
          calendar={CustomCalendar}
          min={minFrmDt}
          max={maxFrmDt}
          disabled={isDisabled}
        // onChange={(e) => {
        //   setDate(e.value);

        // }}

        />

      </div>

      <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
          //defaultValue={toDate}
          value={toDate}
          calendar={CustomCalendar}
          format="MM/dd/yyyy"
          min={minFrmDt}
          max={maxFrmDt}
          disabled={false}
        // onChange={(e) => {
        //   setTDate(e.value);

        // }}
        />

      </div> */}

      {/* <div className='col-md-2 col-lg-2 col-sm-10'>

        <input type="submit" className='btn btn-primary btn-sm' value="Submit" style={{ marginTop: '20px' }} />

      </div> */}

    </div>
  </div></div></div>

                {/* <div className='my-1'>

                  
                  <div className='row card-group mb-2'>
                    <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'>Start Market </div>
                      <div className='card-body kpibody'>
                        ${CardData[0].startMarket === undefined ? '0' : formatNumber(abs(CardData[0].startMarket), "##,#")}
                      </div>

                    </div>

                    <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'>Disbursement</div>
                      <div className='card-body kpibody'>
                        ${CardData[0].disbursement === undefined ? '0' : formatNumber(abs(CardData[0].disbursement), "##,#")}

                      </div>

                    </div>

                    <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'> Income</div>
                      <div className='card-body kpibody'>
                        ${CardData[0].income === undefined ? '0' : formatNumber(abs(CardData[0].income), "##,#")}

                      </div>

                    </div>
                    <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'>Realized Gain/Loss</div>
                      <div className='card-body kpibody'>
                        ${CardData[0].rlGainLoss === undefined ? '0' : formatNumber(abs(CardData[0].rlGainLoss), "##,#")}

                      </div>

                    </div>

                    <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'>Unrealized Gain/Loss </div>
                      <div className='card-body kpibody'>
                        ${CardData[0].ulGainLoss === undefined ? '0' : formatNumber(abs(CardData[0].ulGainLoss), "##,#")}
                      </div>

                    </div>

                    <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'>End Market </div>
                      <div className='card-body kpibody'>
                        ${CardData[0].endMarket === undefined ? '0' : formatNumber(abs(CardData[0].endMarket), "##,#")}
                      </div>

                    </div>

                  </div>
                  
                </div> */}
                <div className='row card-header d-flex align-items-center py-2 mx-1 border-0 shadow-none'>

                </div>
                {flag === 1 ?
                  <TimeWtdRtnRptGrid data={TimeWtdRtnRptRptData} flag={isColumnSave} alloc={TimeWtdRtnRptRptDataROR} InceptnDt={IncepDt}/>
                  : <></>
                }

              </div>
            </form>
          </Card>
        </div>
      </div>

    </div>
  )
}

export default TimeWtdRtnRpt


