import * as React from 'react';
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuGroup,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuCheckboxFilter
} from "@progress/kendo-react-grid";

import { filterBy,orderBy } from '@progress/kendo-data-query';
export const ColumnMenu = props => {
  return <div>
        {/* <GridColumnMenuSort {...props} /> */}
        <GridColumnMenuFilter {...props} />
        {/* <GridColumnMenuGroup {...props} /> */}
        <GridColumnMenuItem {...props} />
        <GridColumnMenuItemContent {...props} />
      
      </div>;
};
export const ColumnMenuCheckboxFilter = (props) => {
  
  let filterData=orderBy(props.data, [{ field: props.column.field, dir: "asc" }]);
  //let filterData=props.data ;
  return (
    <div>
      <GridColumnMenuCheckboxFilter
       {...props}
        data={filterData}
        expanded={true}
      />
    </div>
  );
};