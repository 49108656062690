import React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import axios from "axios";
import { process, filterBy } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { Button, Card } from "@mui/material";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  FaCartPlus,
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
  FaSave,
} from "react-icons/fa";
import Enumerable from "linq";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { SiTruenas } from "react-icons/si";
import { getter } from "@progress/kendo-react-common"; ////
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade, Slide, Zoom } from "@progress/kendo-react-animation";
import { max } from "mathjs";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
const aggregates = [
  // {
  //   field: "cost",
  //   aggregate: "sum",
  // },
  {
    field: "market",
    aggregate: "sum",
  },
  {
    field: "cost",
    aggregate: "sum",
  },
  {
    field: "estAnnInc",
    aggregate: "sum",
  },
  {
    field: "unrGainLoss",
    aggregate: "sum",
  },
  {
    field: "investedIncome",
    aggregate: "sum",
  },
  {
    field: "yield",
    aggregate: "average",
  },
  {
    field: "accruedInterest",
    aggregate: "average",
  },
  {
    field: "acrdIncmAmt",
    aggregate: "sum",
  },
  {
    field: "pcash",
    aggregate: "sum",
  },
];

const initialGroup = [
  {
    field: "mjrAstNm",
  },
  {
    field: "mnrAstNm",
  },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const DATA_ITEM_KEY = "sequenceId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const AcctHoldingGrid = ({
  data,
  flag,
  triggerExport,
  triggerColapse,
  triggerExpand,
  triggerColSave,
  incomeCash,
  totalLiqPriBal,
  totMarketvalue,
  totalCash,
}) => {
  const themes = useSelector(selectTheme);
  const [success, setSuccess] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [columnLatest, setColumnLatest] = React.useState(null);
  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [locked, setLocked] = React.useState(false);

  const [expandedState, setExpandedState] = React.useState(true);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";

    const total = filterBy(
      data,
      localStorage.getItem("filter") === "undefined"
        ? null
        : JSON.parse(localStorage.getItem("filter"))
    )
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));

    setColumnLatest(props.target._columns);
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }

    //newColumnsState[6].footerCell = totalSum;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    var tempcolumn = [];

    var j = 0;
    for (var i = 0; i < newColumnsState.length; i++) {
      //i=newColumnsState[i].field==="accountName"&&localStorage.getItem("SelAcctId") > 0?i+1:i;
      tempcolumn[j] = newColumnsState[i];
      if (newColumnsState[i].field === "market") {
        tempcolumn[j].footerCell = totalSum;
      }
      j++;
    }

    return tempcolumn;
    const [, ...restnew] = newColumnsState;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    if (
      localStorage.getItem("SelAcctId") > 0 &&
      newColumnsState[0].field === "accountName"
    ) {
      return restnew;
    } else {
      return newColumnsState;
    }

    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithExcelCheck;
    // newColumnsState[3].columnMenu = menuWithExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[12].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[13].columnMenu = menuWithExcelCheck;
    // newColumnsState[14].columnMenu = menuWithExcelCheck;
    // newColumnsState[15].columnMenu = menuWithExcelCheck;
    // newColumnsState[16].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[17].columnMenu = menuWithExcelCheck;
    // newColumnsState[18].columnMenu = menuWithExcelCheck;
    // newColumnsState[19].columnMenu = menuWithExcelCheck;
    // newColumnsState[20].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[21].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[22].columnMenu = menuWithExcelCheck;
    // newColumnsState[23].columnMenu = menuWithExcelCheck;
    // newColumnsState[24].columnMenu = menuWithExcelCheck;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const defaultColumnsCopy = [
    // {
    //   title: 'Branch',
    //   field: 'branch',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Acct. Type',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Account#",
      field: "accountName",
      minWidth: 150,

      show: true,
      filter: "text",
      locked: false,
    },
    //  {
    //   title: 'PMR',
    //   field: 'pmrDesc',
    //   minWidth: 150,
    //   show: false,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Ticker",
      field: "tckrSymbl",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Cusip",
      field: "cusip",
      minWidth: 180,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Asset Description",
      field: "asset",
      minWidth: 350,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Cost($)",
      field: "cost",
      minWidth: 200,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
    },
    {
      title: "Market Value ($)",
      field: "market",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      footerCell: totalSum,
      headerClassName: "rightHeader",
      aggregate: "sum",
    },
    {
      title: "Unr Gain/Loss ($)",
      field: "unrGainLoss",
      minWidth: 180,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      // footerCell: totalSum,
      aggregate: "sum",
    },
    {
      title: "Est Ann Inc($)",
      field: "estAnnInc",
      minWidth: 300,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
    },
    {
      title: "Yield(%)",
      field: "yield",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Acc Int.(%)",
      field: "accruedInterest",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Accrued Income",
      field: "acrdIncmAmt",
      minWidth: 300,
      show: false,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
    },
    // {
    //   title: 'Principal($)',
    //   field: 'principalCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Income($)',
    //   field: 'incomeCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    {
      title: "Inv. Income($)",
      field: "investedIncome",
      minWidth: 300,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
    },
    {
      title: "Cost Per Share",
      field: "costPerShare",
      minWidth: 250,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Country",
      field: "country",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Location",
      field: "location",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Registration",
      field: "registration",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Holding Date",
      field: "holdingDate",
      //field: 'holdingDate',
      minWidth: 150,
      show: true,
      filter: "date",
      locked: false,
    },
    {
      title: "Industry",
      field: "industry",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Issue Type",
      field: "issueType",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Manual Price Source",
      field: "manualPriceSource",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Price",
      field: "price",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Price Date",
      field: "priceDate",
      minWidth: 150,
      show: true,
      filter: "date",
      locked: false,
    },
    {
      title: "Security Type",
      field: "securityType",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Pricing Source",
      field: "pricingSource",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    // {
    //   title: 'Pricing Source',
    //   field: 'pricingSourceDesc',
    //   minWidth: 300,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },

    // {
    //   title: 'Inv. Objective',
    //   field: 'investmentObjective',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Rate',
    //   field: 'rate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    // },
    // {
    //   title: 'Tax Cost($)',
    //   field: 'txCstAmt',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Yield To Cost(%)',
    //   field: 'yldToCost',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
  ];

  const CustomFooter = (props) => {
    const field = props.column.field || "";
    let mvSum = //filterBy(data,localStorage.getItem("filter")==="undefined"?null: JSON.parse(localStorage.getItem("filter")))
      data.reduce((acc, current) => acc + current[field], 0).toFixed(2);
    return `${formatNumber(mvSum, "##,#.00")}`;
  };
  const CustomGroupFooter = (props) => {
    const field = props.field || "";

    if (props.aggregates.group.field == "mnrAstNm") {
      let mvSumMinor = props.aggregates.group.items
        .reduce((acc, current) => acc + current[field], 0)
        .toFixed(2);

      return `${formatNumber(mvSumMinor, "##,#.00")}`;
    } else {
      let totalMjr = 0;
      for (var i = 0; i < props.aggregates.group.items.length; i++) {
        let mvSumMajor = props.aggregates.group.items[i].items
          .reduce((acc, current) => acc + current[field], 0)
          .toFixed(2);
        totalMjr = parseFloat(totalMjr) + parseFloat(mvSumMajor);
      }
      return `${formatNumber(totalMjr, "##,#.00")}`;
    }
  };
  const defaultColumns = [
    // {
    //   title: 'Branch',
    //   field: 'branch',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Acct. Type',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Account#",
      field: "accountName",
      minWidth: 150,
      show: localStorage.getItem("SelAcctId") > 0 ? false : true,
      //show: true,
      filter: "text",
      locked: false,
      orderIndex: -1,
    },
    //  {
    //   title: 'PMR',
    //   field: 'pmrDesc',
    //   minWidth: 150,
    //   show: false,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Ticker",
      field: "tckrSymbl",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 0,
    },
    {
      title: "Cusip",
      field: "cusip",
      minWidth: 180,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 1,
    },
    {
      title: "Asset Description",
      field: "asset",
      minWidth: 350,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 2,
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: 150,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      orderIndex: 3,
    },
    {
      title: "Cost($)",
      field: "cost",
      headerCellOptions: { textAlign: "right" },
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      minWidth: 200,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 4,
    },
    {
      title: "Market Value ($)",
      field: "market",
      footer: CustomFooter,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      footerCellOptions: { wrap: true, textAlign: "right", format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      footerCell: totalSum,
      headerClassName: "rightHeader",
      aggregate: "sum",
      orderIndex: 5,
    },
    {
      title: "Unr Gain/Loss ($)",
      field: "unrGainLoss",
      minWidth: 180,
      headerCellOptions: { textAlign: "right" },
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      // footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 6,
    },
    {
      title: "Est Ann Inc($)",
      field: "estAnnInc",
      minWidth: 300,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 7,
    },
    {
      title: "Yield(%)",
      field: "yield",
      minWidth: 150,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      orderIndex: 8,
    },
    {
      title: "Acc Int.(%)",
      field: "accruedInterest",
      minWidth: 150,
      show: true,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      orderIndex: 9,
    },
    {
      title: "Accrued Income",
      field: "acrdIncmAmt",
      minWidth: 300,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      show: false,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 10,
    },
    // {
    //   title: 'Principal($)',
    //   field: 'principalCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Income($)',
    //   field: 'incomeCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    {
      title: "Inv. Income($)",
      field: "investedIncome",
      minWidth: 300,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      //footerCell: totalSum,
      aggregate: "sum",
      orderIndex: 11,
    },
    {
      title: "Cost Per Share",
      field: "costPerShare",
      minWidth: 250,
      show: true,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      orderIndex: 12,
    },
    {
      title: "Country",
      field: "country",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 13,
    },
    {
      title: "Location",
      field: "location",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 14,
    },
    {
      title: "Registration",
      field: "registration",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 15,
    },
    {
      title: "Holding Date",
      field: "holdingDate",
      //field: 'holdingDate',
      minWidth: 150,
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 16,
    },
    {
      title: "Industry",
      field: "industry",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 17,
    },
    {
      title: "Issue Type",
      field: "issueType",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 18,
    },
    {
      title: "Manual Price Source",
      field: "manualPriceSource",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 19,
    },
    {
      title: "Price",
      field: "price",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
      orderIndex: 20,
    },
    {
      title: "Price Date",
      field: "priceDate",
      minWidth: 150,
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 21,
    },
    {
      title: "Security Type",
      field: "securityType",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 22,
    },
    {
      title: "Pricing Source",
      field: "pricingSource",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 23,
    },
    // {
    //   title: 'Pricing Source',
    //   field: 'pricingSourceDesc',
    //   minWidth: 300,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },

    // {
    //   title: 'Inv. Objective',
    //   field: 'investmentObjective',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Rate',
    //   field: 'rate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    // },
    // {
    //   title: 'Tax Cost($)',
    //   field: 'txCstAmt',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Yield To Cost(%)',
    //   field: 'yldToCost',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
  ];

  const CustomGroupHeader = (props) => {
    return `${props.value}`;
  };
  let loadedColumns = localStorage.getItem("gridColumns");
  //const GridColumns = defaultColumns;

  //const GridColumns =  defaultColumns;
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  if (
    localStorage.getItem("HoldingDataState") !== undefined &&
    localStorage.getItem("HoldingDataState") !== null
  ) {
    var datastatetemp = JSON.parse(localStorage.getItem("HoldingDataState"));
    if (datastatetemp.filter !== undefined) {
      if (datastatetemp.filter.filters.length > 0) {
        for (var i = 0; i < GridColumns.length; i++) {
          for (var j = 0; j < datastatetemp.filter.filters.length; j++) {
            if (
              GridColumns[i].field ==
              datastatetemp.filter.filters[j].filters[0].field
            ) {
              GridColumns[i].headerClassName = "active";
              break;
            } else {
              GridColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < GridColumns.length; i++) {
          GridColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < GridColumns.length; i++) {
        GridColumns[i].headerClassName = "";
      }
    }
  }
  for (var i = 0; i < GridColumns.length; i++) {
    for (var j = 0; j < defaultColumnsCopy.length; j++) {
      if (
        GridColumns[i].field === defaultColumnsCopy[j].field &&
        defaultColumnsCopy[j].headerClassName !== undefined
      )
        GridColumns[i].headerClassName =
          defaultColumnsCopy[j].headerClassName +
          " " +
          GridColumns[i].headerClassName;
    }
  }

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  // const createDataState = (dataState) => {
  //   return {
  //     result: process(data.slice(0), dataState),
  //     dataState: dataState,
  //   };
  // };

  let initialState = createDataState({
    filter:
      localStorage.getItem("HoldingDataState") === undefined ||
      localStorage.getItem("HoldingDataState") === null
        ? undefined
        : JSON.parse(localStorage.getItem("HoldingDataState")).filter,
    sort:
      localStorage.getItem("HoldingDataState") === undefined ||
      localStorage.getItem("HoldingDataState") === null
        ? undefined
        : JSON.parse(localStorage.getItem("HoldingDataState")).sort,
    take: 4000,
    skip: 0,
    group: [{ field: "mjrAstNm" }, { field: "mnrAstNm" }],
  });
  let initialStateExport = createDataState({
    take: 4000,
    skip: 0,
    group: [{ field: "mjrAstNm" }, { field: "mnrAstNm" }],
  });

  //localStorage.removeItem("HoldingDataState");
  console.log(JSON.parse(localStorage.getItem("HoldingDataState")));
  const [result, setResult] = React.useState(
    processWithGroups(
      data,
      localStorage.getItem("HoldingDataState") === undefined ||
        localStorage.getItem("HoldingDataState") === null
        ? initialState.dataState
        : JSON.parse(localStorage.getItem("HoldingDataState"))
    )
  );

  // useEffect(() => {

  //   setResult(processWithGroups(data, initialState.dataState));
  // });
  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  // GridColumns=localStorage.getItem("SelAcctId") > 0 ? GridColumns.shift() : GridColumns;

  const [, ...rest] = GridColumns;
  var tempcolumn = [];

  var j = 0;
  for (var i = 0; i < GridColumns.length; i++) {
    //i=GridColumns[i].field==="accountName" && localStorage.getItem("SelAcctId") > 0?i+1:i;
    tempcolumn[j] = GridColumns[i];
    if (GridColumns[i].field === "market") {
      tempcolumn[j].footerCell = totalSum;
    }
    j++;
  }

  const [stateColumns, setStateColumns] = React.useState(tempcolumn);

  //GridColumns[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
  //GridColumns[6].footerCell = totalSum;
  // GridColumns[0].columnMenu = menuWithExcelCheck;
  // GridColumns[1].columnMenu = menuWithExcelCheck;
  // GridColumns[2].columnMenu = menuWithExcelCheck;
  // GridColumns[3].columnMenu = menuWithExcelCheck;
  // GridColumns[4].columnMenu = menuWithoutExcelCheck;
  // GridColumns[5].columnMenu = menuWithoutExcelCheck;
  // GridColumns[6].columnMenu = menuWithoutExcelCheck;
  // GridColumns[7].columnMenu = menuWithoutExcelCheck;
  // GridColumns[8].columnMenu = menuWithoutExcelCheck;
  // GridColumns[9].columnMenu = menuWithoutExcelCheck;
  // GridColumns[10].columnMenu = menuWithoutExcelCheck;
  // GridColumns[11].columnMenu = menuWithoutExcelCheck;
  // GridColumns[12].columnMenu = menuWithoutExcelCheck;
  // GridColumns[13].columnMenu = menuWithExcelCheck;
  // GridColumns[14].columnMenu = menuWithExcelCheck;
  // GridColumns[15].columnMenu = menuWithExcelCheck;
  // GridColumns[16].columnMenu = menuWithoutExcelCheck;
  // GridColumns[17].columnMenu = menuWithExcelCheck;
  // GridColumns[18].columnMenu = menuWithExcelCheck;
  // GridColumns[19].columnMenu = menuWithExcelCheck;
  // GridColumns[20].columnMenu = menuWithoutExcelCheck;
  // GridColumns[21].columnMenu = menuWithoutExcelCheck;
  // GridColumns[22].columnMenu = menuWithExcelCheck;
  // GridColumns[23].columnMenu = menuWithExcelCheck;
  // GridColumns[24].columnMenu = menuWithExcelCheck;

  const [asOfDate, setasOfDate] = useState(0);
  let pageSize = 10;
  const [page, setPage] = React.useState({
    skip: 0,
    take: Number.MAX_VALUE,
  });

  const dataExport = process(data, {
    group: initialGroup,
  }).data;
  let _pdfExport;

  const saveColumnStateDb = async () => {
    //Storing column settings in DB
    //

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 34;
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    //
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };

  const saveColumnsState = () => {
    console.log("save called!!");
    //console.log(columns);

    var columns = [];
    if (localStorage.getItem("SelAcctId") > 0) {
      columns[0] = defaultColumns[0];
      for (var i = 0; i < stateColumns.length; i++) {
        columns[i + 1] = stateColumns[i];
      }
    } else {
      columns = stateColumns;
    }

    for (var i = 0; i < columns.length; i++) {
      if (columns[i].headerClassName !== undefined) {
        if (columns[i].headerClassName.includes("rightHeader")) {
          columns[i].headerClassName = "rightHeader";
        } else {
          columns[i].headerClassName = "";
        }
      }
    }
    let currentColumnsState = JSON.stringify(columns);

    localStorage.setItem("gridColumns", currentColumnsState);
    saveColumnStateDb();
  };

  // useEffect(() => {

  //   //saveColumnsState(stateColumns);
  // }, [stateColumns]);

  const dataStateChange = (event) => {
    localStorage.setItem("filter", JSON.stringify(event.dataState.filter));
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumnsCopy.length; j++) {
        if (
          stateColumns[i].field === defaultColumnsCopy[j].field &&
          defaultColumnsCopy[j].headerClassName !== undefined
        )
          stateColumns[i].headerClassName =
            defaultColumnsCopy[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }

    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(processWithGroups(data, updatedState.dataState));
    localStorage.setItem(
      "HoldingDataState",
      JSON.stringify(updatedState.dataState)
    );
    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const exportPDF = () => {
    //let oldDataState = { ...dataState };
    // if (pdfExportComponent.current) {
    //setDataState({ ...dataState, skip: 0, take: Number.MAX_VALUE });

    _pdfExport.save();

    // setTimeout(() => {
    //   setDataState(oldDataState);
    // });
  };

  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-end px-5 py-2">
              <h2 className="fw-bold text-fitek">Account Holding Report</h2>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };

  // const expandChange = (event) => {
  //
  //   const isExpanded =
  //     event.dataItem.expanded === undefined
  //       ? event.dataItem.aggregates
  //       : event.dataItem.expanded;
  //   event.dataItem.expanded = !isExpanded;
  //   setResult({ ...result });
  // };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({
      ...result,
      data: [...result.data],
    });
  };
  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;

      item.items.map((item1) => {
        item1.expanded = false;
      });

      //  for(var i=0;i<item.items.length;i++){
      //   item.items[i].expanded = false;
      //  }
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  /////////////////////////////
  const onSelectionChange = (event) => {
    // data.map((item) => {
    //     item.selected = false;
    // });

    let theSelectedItem = event.dataItems[event.endRowIndex];

    let newData = data.map((item) => {
      item.selected = false;
      if (item.sequenceId === theSelectedItem.sequenceId) {
        item.selected = !item.selected;
      }
      return item;
    });
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  //////////////////////////////////

  const handleExpand = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;

      for (var i = 0; i < item.items.length; i++) {
        item.items[i].expanded = true;
      }
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  useEffect(() => {
    handleExpand();
  }, []);

  useMemo(() => {
    if (triggerExport) {
      excelExport();
    }
  }, [triggerExport]);
  useEffect(() => {
    if (triggerColSave) {
      saveColumnsState();
    }
  }, [triggerColSave]);
  useMemo(() => {
    if (triggerExpand) {
      handleExpand();
    }
  }, [triggerExpand]);

  useMemo(() => {
    if (triggerColapse) {
      handleColapse();
    }
  }, [triggerColapse]);

  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      var colStatus = Enumerable.from(stateColumns)
        .where((w) => w.field === column.field)
        .toArray();
      //if( colStatus.length!=0 && colStatus[0].show){
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
      // }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.

      const columns = [
        // { field: 'branch' },
        // { field: 'accountType' },
        { field: "accountName" },

        { field: "tckrSymbl" },
        { field: "cusip" },
        { field: "asset" },
        { field: "shares" },
        { field: "cost", aggregate: "sum" },

        { field: "market", aggregate: "sum" },
        { field: "unrGainLoss", aggregate: "sum" },
        { field: "estAnnInc", aggregate: "sum" },
        { field: "yield" },
        { field: "accruedInterest" },
        { field: "acrdIncmAmt", aggregate: "sum" },
        { field: "investedIncome", aggregate: "sum" },
        { field: "costPerShare" },
        { field: "country" },
        { field: "location" },
        { field: "registration" },
        { field: "holdingDate" },
        { field: "industry" },
        { field: "issueType" },
        { field: "manualPriceSource" },
        { field: "price" },
        { field: "priceDate" },
        { field: "securityType" },
        { field: "pricingSource" },
        { field: "pricingSourceDesc" },
      ];
      if (!tdElement.props.colSpan) {
        return <td></td>;
      }
      let currentColumns = stateColumns.filter((c) => c.show);
      currentColumns = currentColumns.map((col) => {
        let aggrCol = columns.find((c) => {
          return c.field == col.field;
        });
        if (aggrCol && aggrCol.aggregate) {
          console.log("aggregate");
          return {
            ...col,
            aggregate: aggrCol.aggregate,
          };
        } else {
          return col;
        }
      });
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - currentColumns.length}
          ></td>
          {getCells(
            currentColumns.sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            }),
            cellProps
          )}
        </>
      );
    }
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "market") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.aggregates.market.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "cost") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.aggregates.cost.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "estAnnInc") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.estAnnInc.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "unrGainLoss") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.unrGainLoss.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "investedIncome") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.investedIncome.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.yield.average,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "accruedInterest") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.accruedInterest.average,
              "##,#.00"
            )}
          </td>
        );
      }
      // if (cellProps.field === "acrdIncmAmt") {

      //   return (
      //     <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
      //       {formatNumber(cellProps.dataItem.aggregates.acrdIncmAmt.sum, "##,#.00")}
      //     </td>
      //   );
      // }
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "market") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "unrGainLoss") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "estAnnInc") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yldToCost") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "accruedInterest") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "acrdIncmAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "principalCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "incomeCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "investedIncome") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "txCstAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "cost") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "shares") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "priceDate") {
        let cdt = new Date(cellProps.dataItem["priceDate"]);
        if (moment(cellProps.dataItem["priceDate"]).isValid()) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {cellProps.dataItem["priceDate"]}
            </td>
          );
        }
      }
      if (cellProps.field === "holdingDate") {
        let cdt = new Date(cellProps.dataItem["holdingDate"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
      if (cellProps.field === "price") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "costPerShare") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "costPerShare") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "priceDate") {
        let cdt = new Date(cellProps.dataItem["priceDate"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
    }

    return tdElement;
  };

  const NumberCell = (props) => {
    if (props.field === "branch") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "asset") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tckrSymbl") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "cusip") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "pmrDesc") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "shares") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "cost") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "market") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "unrGainLoss") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "estAnnInc") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yield") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "accruedInterest") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "principalCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "incomeCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "investedIncome") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "investmentObjective") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "administrator") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "investmentOfficer") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "rate") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "txCstAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yldToCost") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "costPerShare") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "price") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };

  const windowHeight = useRef(window.innerHeight);

  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? localStorage.getItem("pRltncd") == null ||
        localStorage.getItem("pRltncd") == undefined ||
        localStorage.getItem("pRltncd") == ""
        ? "Consolidation:" + localStorage.getItem("pConsName")
        : "Relationship Code:" + localStorage.getItem("pRltncd")
      : "Account Number:" + localStorage.getItem("SelAcctNm");

  var stateColExport = stateColumns;
  var columnLatest2 = columnLatest;
  if (
    columnLatest2 !== null &&
    columnLatest2.filter((x) => x.field !== "mjrAstNm")
  ) {
    columnLatest2 = [
      {
        title: "Major Asset",
        field: "mjrAstNm",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      {
        title: "Minor Asset",
        field: "mnrAstNm",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      ...columnLatest2,
    ];
  }

  if (columnLatest2 !== null) {
    for (var i = 0; i < columnLatest2.length; i++) {
      for (var j = 0; j < stateColumns.length; j++) {
        if (columnLatest2[i].field === stateColumns[j].field) {
          columnLatest2[i].show = stateColumns[j].show;
        }
      }
    }
  }

  if (stateColExport.filter((x) => x.field !== "mjrAstNm")) {
    stateColExport = [
      {
        title: "Major Asset",
        field: "mjrAstNm",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      {
        title: "Minor Asset",
        field: "mnrAstNm",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      ...stateColExport,
    ];
  }

  stateColExport.sort(function (a, b) {
    return a.orderIndex - b.orderIndex;
  });

  return (
    <>
      <NotificationGroup
        style={{
          zIndex: 9999999999999999,
          right: "55%",
          top: "12%",
          width: "50px",
          alignItems: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <Slide>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}

              // closable={true}

              // onClose={() =>
              //   setError(false)
              // }
            >
              Saved successfully.
            </Notification>
          )}
        </Slide>
      </NotificationGroup>
      <ExcelExport
        data={dataExport}
        ref={_export}
        group={initialGroup}
        fileName={excelTitle + "_AcctHolding.xlsx"}
      >
        <ExcelExportColumnGroup
          title={
            excelTitle +
            "  Processing Date: " +
            localStorage.getItem("processingDate") +
            "  Total Liquid Principal Balance($): " +
            formatNumber(totalLiqPriBal, "##,#.00") +
            "  Income($): " +
            formatNumber(incomeCash, "##,#.00") +
            "  Total Market Value($): " +
            formatNumber(totMarketvalue, "##,#.00") +
            "  Total Cash($): " +
            formatNumber(totalCash, "##,#.00")
          }
          headerCellOptions={{
            textAlign: "left",
          }}
        >
          {/* <ExcelExportColumn
            title="Major Asset Name"
            field="mjrAstNm"
             hidden={true}
            groupHeader={CustomGroupHeader}
          />
          <ExcelExportColumn
            field="mnrAstNm"
            hidden={true}
            groupHeader={CustomGroupHeader}
          /> */}

          {stateColExport.map((w) => {
            return (
              <ExcelExportColumn
                field={w.field}
                hidden={!w.show}
                title={w.title}
                headerCellOptions={w.headerCellOptions}
                groupHeader={w.groupHeader}
                cellOptions={w.cellOptions}
                groupFooter={w.groupFooter}
                groupFooterCellOptions={w.groupFooterCellOptions}
                footer={w.footer}
              />
            );
          })}

          {/* <ExcelExportColumn field="accountName" title="Account#" hidden={localStorage.getItem("SelAcctId")>0?true:false} />
          
          <ExcelExportColumn field="tckrSymbl"  title="Ticker" />
          <ExcelExportColumn field="cusip" title="Cusip" width={50} />
          <ExcelExportColumn field="asset" title="Asset Description" />

          <ExcelExportColumn field="shares" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Shares" />
          <ExcelExportColumn field="cost" headerCellOptions={{ textAlign: "right", }} groupFooter={CustomGroupFooter} 
          groupFooterCellOptions={{textAlign: "right",format: "#,##0.00"}} cellOptions={{ format: "#,##0.00", }} title="Cost($)" />
          <ExcelExportColumn field="market" footer={CustomFooter} groupFooter={CustomGroupFooter} 
          groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} footerCellOptions={{wrap: true,textAlign: "right",format: "#,##0.00"}}
          
          headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Market Value ($)"  />
          <ExcelExportColumn field="unrGainLoss" headerCellOptions={{ textAlign: "right", }}groupFooter={CustomGroupFooter} 
          groupFooterCellOptions={{textAlign: "right",format: "#,##0.00"}} cellOptions={{ format: "#,##0.00", }} title="Unr Gain/Loss ($)" />
          <ExcelExportColumn field="estAnnInc" groupFooter={CustomGroupFooter} 
          groupFooterCellOptions={{textAlign: "right",format: "#,##0.00"}} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Est Ann Income($)" />
          <ExcelExportColumn field="yield" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Yield(%)" />
          <ExcelExportColumn field="accruedInterest" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Acc Int.(%)" />
          <ExcelExportColumn field="acrdIncmAmt" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Accrued Income" />
          
          {/* <ExcelExportColumn field="principalCash" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Principal($)" /> */}
          {/* <ExcelExportColumn field="incomeCash" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Income($)" /> */}
          {/* <ExcelExportColumn field="investedIncome" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Inv. Income($)" />

          <ExcelExportColumn field="costPerShare" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Cost Per Share" />
          <ExcelExportColumn field="country" title="Country" />
          <ExcelExportColumn field="location" title="Location" />
          <ExcelExportColumn field="registration" title="Registration" />
          <ExcelExportColumn field="holdingDate" title="Holding Date" />
          <ExcelExportColumn field="industry" title="Industry" />
          <ExcelExportColumn field="issueType" title="Issue Type" />
          <ExcelExportColumn field="manualPriceSource" title="Manual Price Source" />
          <ExcelExportColumn field="price" headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Price" />
          <ExcelExportColumn field="priceDate" title="Price Date" />
          <ExcelExportColumn field="securityType" title="Security Type" />
          <ExcelExportColumn field="pricingSource" title="Pricing Source" /> */}
          {/* <ExcelExportColumn field="pricingSourceDesc" title="Pricing Source" /> */}
        </ExcelExportColumnGroup>
      </ExcelExport>

      <Grid
        style={{
          height: windowHeight.current - 190,
          width: "auto",
          position: "absolute",
          border: "none",
        }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={onColumnReorder}
        onColumnResize={onColumnResize}
        expandField="expanded"
        onExpandChange={expandChange}
        cellRender={cellRender}
        sortable={true}
        resizable={true}
        pageable={{
          buttonCount: 0,
          info: true,
          previousNext: false,
        }}
        reorderable={true}
        pageSize={2000}
        groupable={{
          footer: "none",
          enabled: false,
        }}
        //////////
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "single",
        }}
        onSelectionChange={onSelectionChange}
        //////////
      >
        {/* <GridToolbar>
          <p>Principal : {data[0].principalCash}</p>
          <p>Income : {data[0].incomeCash}</p>
          <p>Total Liquid Principal Balance : {data[0].totalLiqPriBal}</p>

        </GridToolbar> */}

        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                // locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                // columnMenu={column.columnMenu}

                //cell={NumberCell}

                columnMenu={(props) => (
                  <>
                    {column.filter === "text" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : column.filter === "numeric" ? (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}
              />
            )
        )}
      </Grid>
      {themes === "dark" ? (
        <style>
          {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}
.k-calendar   .k-focus
{
  background: radial-gradient(#ffffff, #000000e0) !important;
  
}
.k-calendar   .k-focus:hover
{
  background: #5665b9b9 !important;
  
}
.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
    .k-calendar   .k-focus
{

  background-color: #78e4ffa8 !important;

}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </>
  );
};

export default AcctHoldingGrid;
