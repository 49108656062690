import React from 'react'
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartSeriesLabels,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
    ChartArea,
  } from "@progress/kendo-react-charts";
  import { formatNumber, formatDate } from '@telerik/kendo-intl';
  import { useState, useRef, useEffect } from 'react';
  import { ResponsiveContainer } from 'recharts';
  import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
  import ToggleButton from 'react-bootstrap/ToggleButton';
  import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
  import Enumerable from 'linq';
import { useSelector } from 'react-redux';
import { selectTheme } from '../features/theme/themeSlice';
const AcctPerfHistory = ({ dashboardData }) => {
    const [data, setData] = useState(dashboardData.lstAccountHist);
    const [selYr,setSelYr]=useState(5);
    const themes=useSelector(selectTheme);
    const FormatDate=(date)=>
    {
       return formatDate(new Date(date.text),'MM-dd-yyyy') ;

    }
    const chartBootstrapV4Colors = [
      "#006fba", "#0C483E", "#00AB8D", "#65697D", "#D8DAE5", "#9E8AA3", "#D38BB2", "#7EF58B", "#E78B8B"
    ];
    const handleHistoryDateRange=(e)=>
    {
        
        if(e.target.innerText=="5 Yrs")
        {
            setSelYr(5);
          var filter=  Enumerable.from(dashboardData.lstAccountHist).where(w => w.yearDiff <= 5).toArray();
            setData(filter);
    
        }
       
        if(e.target.innerText=="4 Yrs"){
            setSelYr(4);
            var filter=  Enumerable.from(dashboardData.lstAccountHist).where(w => w.yearDiff <= 4).toArray();
            setData(filter);
        }
        

        if(e.target.innerText=="3 Yrs"){
            setSelYr(3);
            var filter=  Enumerable.from(dashboardData.lstAccountHist).where(w => w.yearDiff <= 3).toArray();
            setData(filter);
        }
       

        if(e.target.innerText=="2 Yrs"){
            setSelYr(2);
            var filter=  Enumerable.from(dashboardData.lstAccountHist).where(w => w.yearDiff <= 2).toArray();
            setData(filter);
        }
        

        if(e.target.innerText=="1 Yr"){
            setSelYr(1);
            var filter=  Enumerable.from(dashboardData.lstAccountHist).where(w => w.yearDiff <= 1).toArray();
            setData(filter);
        }
        


    }

  return (
    <div>
       <div className='row card-group my-1 d-flex'>
      
      
    <ButtonToolbar>
    <ToggleButtonGroup  type="radio" name="radioNm" value={selYr}    onClick={handleHistoryDateRange}  >
    <ToggleButton  className="me-1" variant="outline-secondary" value={5}  >5 Yrs</ToggleButton >
    <ToggleButton className="me-1"   variant="outline-secondary" value={4}>4 Yrs</ToggleButton>
    <ToggleButton className="me-1" variant="outline-secondary" value={3} >3 Yrs</ToggleButton>
    <ToggleButton className="me-1"  variant="outline-secondary" value={2} >2 Yrs</ToggleButton>
    <ToggleButton className="me-1"  variant="outline-secondary" value={1} >1 Yr</ToggleButton>
    </ToggleButtonGroup>
   
   
    </ButtonToolbar>


              <ResponsiveContainer>
                <Chart
                  seriesColors={chartBootstrapV4Colors}
                  style={{ maxHeight: "400px" }}>
<ChartArea background={themes==='dark'?"#292929":"white"} margin={30} />
                  {/* <ChartLegend position="bottom" /> */}
                  <ChartValueAxis>
                    <ChartValueAxisItem
                      // title={{
                      //     text: "Percentage",
                      // }}
                      min={0}
                      minorGridLines={{
                        color:themes==='dark'?"rgba(94, 94, 94, 0.4)":"rgba(94, 94, 94, 0.4)"
                      }}
                      majorGridLines={{
                        color:themes==='dark'?"rgba(94, 94, 94, 0.4)":"rgba(94, 94, 94, 0.4)"
                      }}
                      labels={{
                        visible: false,

                         rotation: 85,
                        format: "d",
                        //content: FormatLongNumber

                      }}
                    />
                  </ChartValueAxis>
                  <ChartCategoryAxis >
                      <ChartCategoryAxisItem
minorGridLines={{
  color:themes==='dark'?"rgba(94, 94, 94, 0.4)":"rgba(94, 94, 94, 0.4)"
}}
majorGridLines={{
  color:themes==='dark'?"rgba(94, 94, 94, 0.4)":"rgba(94, 94, 94, 0.4)"
}}

                        labels={{
                          color:themes==='dark'?"white":"black",
                          visible: true,
                          rotation: 70,
                          format: "d",
                            content: FormatDate
                        }}
                      //  categories={categoryAxis} 
                      />
                    </ChartCategoryAxis>
                  <ChartTooltip format='c2' />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="area"
                      // overlay={{
                      //   gradient: "sharpBevel",
                      // }}
                      data={data}
                      field="market"
                      categoryField="mnthEndDt" 
                      
  
                    //categoryField= {formatDate(new Date('mnthEndDt'), "mdy")}
                      //colorField='chrtColorId'
                      autoFit={true}
                      labels={{
                        backgroundColor:"rgba(0,0,0,0)",
                        color:themes==='dark'?"white":"black",
                        visible: false,
                        position: "outsideEnd",
                        format: "d",
                        //content: labelContent1,
                      }}
                    />
                  </ChartSeries>
                </Chart>

                 
              </ResponsiveContainer>

            </div>

         
    </div>
  )
}

export default AcctPerfHistory
